import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import withRouter from "../../../Components/Common/withRouter";

import { Button, Card, Input, Col, CardBody, CardTitle, Modal, ModalHeader, Row, ModalBody, InputGroup, Spinner, Container } from "reactstrap";
import EcommerceOrdersModal from "../../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { uploadFile, getEdi835Data, getLoggedInUser, getSelectedClientData, getARSourceData, uploadARFile, getATBSourceData, uploadATBFile } from 'helpers/backend_helper'
import Pagination from "../../../Components/Common/Pagination";
import moment from "moment";
import { toast } from "react-hot-toast";
import DynamicPagination from "Components/Common/DynamicPagination";
import { useNavigate } from "react-router-dom";
import { isExpireToken } from "slices/thunk";
import { useLoader } from "provider/LoadingContext";

const ATB: React.FC = () => {
    const { setIsLoading } = useLoader();
    const navigate = useNavigate();
    const [subscribemodal, setSubscribemodal] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [modal1, setModal1] = useState<boolean>(false);
    const toggleViewModal = useCallback(() => setModal1(!modal1), [modal1]);
    const [AtbData, seATBData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [customPageSize, setCustomPageSize] = useState<number>(10);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const fileInputRef = useRef(null);
    const [per, setPer] = useState<number>(4);
    const clients = JSON.parse(localStorage.getItem("selected-client") || '')

    useEffect(() => {
        getARData(currentPage, customPageSize);
    }, [currentPage, customPageSize]);


    const getARData = async (page: number, pageSize: number) => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                pagination: true,
                page: page - 1,
                size: pageSize,
                "orderByField": '',
                "orderDirection": ''
            };
            let response = await getATBSourceData(body);
            if (response && response.success && response.data.list) {
                seATBData(response.data.list);
                setTotalItems(response.data.totalItems);
                setIsLoading(false);
            } else {
                console.error("Failed to fetch data");
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "ATB Visit Id",
                accessor: "Account_Number",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => row.original.Account_Number,
            },
            {
                Header: "ATB Name",
                accessor: "Account_Name",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => row.original.Account_Name,
            },
            {
                Header: "Patient Type",
                accessor: "Patient_Type_Abbreviation",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.Patient_Type_Abbreviation
            },
            {
                Header: "ATB Discharge Date",
                accessor: "Discharge_Date",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => moment(new Date(row.original.Discharge_Date)).format('MM/DD/YYYY'),
            },
            {
                Header: "ATB Ins",
                accessor: "atb_ins",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.atb_ins
            },
            {
                Header: "ATB Ins Current",
                accessor: "atb_ins_current",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.atb_ins_current
            },
            {
                Header: "ATB Cycle SW",
                accessor: "atb_cycle_sw",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.atb_cycle_sw
            },
            {
                Header: "ATB Bill SW",
                accessor: "atb_bill_sw",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.atb_bill_sw
            },
            {
                Header: "Terms Patient",
                accessor: "terms_patient",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => row.original.terms_patient
            },
            {
                Header: "ATB Last Payment",
                accessor: "last_payment_date",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => moment(new Date(row.original.last_payment_date)).format('MM/DD/YYYY'),
            },
            {
                Header: "ATB Last Pay Amt",
                accessor: "last_pay_amt",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.last_pay_amt);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            {
                Header: "ATB 30",
                accessor: "atb_30_amt",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.atb_30_amt);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            {
                Header: "ATB 60",
                accessor: "atb_60_amt",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.atb_60_amt);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            {
                Header: "ATB 90",
                accessor: "atb_90_amt",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.atb_90_amt);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            {
                Header: "ATB 120",
                accessor: "atb_120_amt",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.atb_120_amt);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            {
                Header: "ATB Balance",
                accessor: "balance_amt",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.balance_amt);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            }
        ],
        []
    );

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isFileUploading) {
            const file = e.target.files?.[0] || null;
            if (file) {
                const fileNameArray = file.name.split('.');
                const fileExtension = fileNameArray[fileNameArray.length - 1].toLowerCase();

                if (['xlsx'].includes(fileExtension)) {
                    setSelectedFile(file);
                } else {
                    setSelectedFile(null);
                    toast.error("File format must be .xlsx");
                }
            }
        }
    };

    const handleUploadFile = async () => {
        if (!isFileUploading) {
            try {
                if (selectedFile) {
                    setIsFileUploading(true);
                    let response = await uploadATBFile({ file: selectedFile }, clients.user_id,);

                    if (response && response.success) {
                        setIsFileUploading(false);
                        setSelectedFile(null);
                        setSubscribemodal(false);
                        toast.success(`Successfully inserted`);
                        // fetchEdi835Data();
                        getARData(currentPage, customPageSize);

                        // let insertedCount = response.data.insertedCount;
                        // let updatedCount = response.data.updatedCount;

                        // if (insertedCount > 0 && updatedCount > 0) {
                        //     toast.success(`Successfully inserted ${insertedCount} and updated ${updatedCount} record(s).`);
                        // } else if (insertedCount > 0) {
                        //     toast.success(`Successfully inserted ${insertedCount} record(s).`);
                        // } else if (updatedCount > 0) {
                        //     toast.success(`Successfully updated ${updatedCount} record(s).`);
                        // } else {
                        //     toast("No records were inserted or updated.");
                        // }
                    } else {
                        toast.error(response.message);
                        setIsFileUploading(false);
                        setSelectedFile(null);
                        setSubscribemodal(false);
                        if (response.code === '1') {
                            isExpireToken(navigate);
                        }
                    }
                } else {
                    toast.error("Please upload any file");
                }
            } catch (error) {
                toast.error("An error occurred while uploading data. Please try again.");
            }

        }
    };

    const handleUpload = () => {
        setSubscribemodal(true);
    };

    const handleARSortingParamsaas = async (sortingParams: { orderByField: string; orderDirection: string }) => {
        const { orderByField, orderDirection } = sortingParams;
        const payload = {
            clientId: clients.user_id,
            pagination: true,
            page: 0,
            size: 50,
            orderByField,
            orderDirection,
        };

        try {
            setIsLoading(true);
            const response = await getATBSourceData(payload);
            if (response && response.success && response.data.list) {
                seATBData(response.data.list);
                setTotalItems(response.data.totalItems);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                seATBData([]);
                setTotalItems(0)
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Failed to fetch data", error);
        }
    };


    const handleArSortingParams = (params: { orderByField: string; orderDirection: string }) => {
        handleARSortingParamsaas(params);
    }


    return (
        <React.Fragment>
            <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
            <div className="page-content">
                <Container fluid={true}>
                    <div className="mb-2 tab-btn"><h4 className="fw-bold">ATB</h4>
                        <button className="btn btn-primary" id="collapseWidthExample" type="button" onClick={handleUpload}>
                            Upload ATB File
                        </button>
                    </div>
                    <div className="data_table_box log_table">
                        <div className="mt-4">
                            <TableContainer
                                columns={columns}
                                data={AtbData}
                                handleSortingParams={handleArSortingParams}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover"
                                theadClass="table-light"
                                customPageSizeOption={true}
                                customPageSizeOptions={true}
                            />
                        </div>
                    </div>
                    <div className="mt-2 mb-2">
                        {/* <Pagination
                            perPageData={customPageSize}
                            data={AtbData}
                            setCurrentpages={setCurrentpages}
                            currentpages={currentpages}
                        /> */}
                        <DynamicPagination
                            perPageData={customPageSize}
                            totalItems={totalItems}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </Container>
            </div>

            {/* subscribe ModalHeader */}
            <Modal isOpen={subscribemodal} autoFocus={true} centered toggle={() => { setSubscribemodal(!subscribemodal) }}>
                <div>
                    <ModalHeader className="border-bottom-0" toggle={() => { setSubscribemodal(!subscribemodal) }} />
                </div>
                <ModalBody>
                    <div className="text-center mb-4">
                        <Row className="justify-content-center">
                            <Col xl={10}>
                                <h4 className="text-primary mb-4">Upload ATB File</h4>
                                <InputGroup className="rounded bg-light">
                                    <Input ref={fileInputRef} disabled={isFileUploading} type="file" className="bg-transparent border-0" accept=".xlsx"
                                        placeholder="Upload File" onChange={handleFileChange} />
                                    <Button disabled={isFileUploading} color="primary" type="button" id="button-addon2" onClick={handleUploadFile}>{isFileUploading ? <Spinner size="sm">Uploading...</Spinner> : "Upload"}</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal >
        </React.Fragment>
    );
};

export default withRouter(ATB);