import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Card,
    Col,
    Container,
    Form,
    Row,
    Table,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    FormFeedback,
    Label,
    FormGroup
} from "reactstrap";
import './index.scss';
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { getClients, getDataFields, postAddClient, getLoggedInUser, postAddProviderInfo, postAddDataFields } from 'helpers/backend_helper';
import Setting from "configs/settings";
import Avatar from 'react-avatar';
import { isExpireToken } from "slices/thunk";
import { useLoader } from "provider/LoadingContext";

interface TabContentProps {
    activeTab: string;
    tabId: string;
    children: React.ReactNode;
}

interface Client {
    user_id: string;
}

const TabContent: React.FC<TabContentProps> = ({ activeTab, tabId, children }) => {
    return (
        <div role="tabpanel" hidden={activeTab !== tabId}>
            {children}
        </div>
    );
};

function AdminDashboard(props: any) {
    const { setIsLoading } = useLoader();
    const clientsString = localStorage.getItem("selected-client");

    let clients: Client | null = null;

    if (clientsString) {
        try {
            clients = JSON.parse(clientsString) as Client;
        } catch (e) {
            console.error("Error parsing stored clients", e);
            clients = null;
        }
    }
    const navigate = useNavigate();
    document.title = "Monthly Summary | Rubin Brown";
    const tableRow = 5;
    //Client List
    const [clientData, setClientData]: any = useState([]);

    // profile tab
    const [addClientModal, setAddClientModal] = useState<boolean>(false);
    const [imagePreview, setImagePreview] = useState(null);

    // provider tab
    const [providerData, setProviderData] = useState([{ providerName: '', providerNo: '' }]);

    //data field tab
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    // const [individualCheckboxes, setIndividualCheckboxes] = useState([false, false, false, false, false, false, false, false]);
    const [dataFields, setDataFields]: any = useState([]);
    const [individualCheckboxes, setIndividualCheckboxes] = useState(Array(dataFields.length).fill(false));

    const [isBasicInfoSave, setIsBasicInfo] = useState(false);

    const [clientId, setClientId] = useState(0);

    // tab
    const [activeTab, setActiveTab] = useState<string>('tab1');
    const handleTabClick = (tabId: string) => {
        clearAllData();
        setActiveTab(tabId);
    };

    // profile tab
    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            profilePicture: null,
            companyName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            pincode: '',
            city: '',
            state: '',
            primaryFirstName: '',
            primaryLastName: '',
            primaryEmail: '',
            primaryPhone: '',
            primaryCompanyName: ''
        },
        validationSchema: Yup.object().shape({
            profilePicture: Yup.mixed()
                .required("Please upload profile picture")
                .test("fileSize", "File too large", value => value && value.size <= 2000000)
                .test("fileFormat", "Unsupported file format", value => value && ['image/jpeg', 'image/png'].includes(value?.type)),
            companyName: Yup.string().required("Please Enter Company Name"),
            email: Yup.string().email().required("Please Enter Email"),
            addressLine1: Yup.string().required("Please Enter Address Line 1"),
            addressLine2: Yup.string().required("Please Enter Address Line 2"),
            city: Yup.string().required("Please Enter City"),
            state: Yup.string().required("Please Enter State"),
            pincode: Yup.number().min(6, "Pincode must be numeric and minimum 6 digits").required("Please Enter Pincode"),
            primaryFirstName: Yup.string().required("Please Enter Primary First Name"),
            primaryLastName: Yup.string().required("Please Enter Primary Last Name"),
            primaryEmail: Yup.string().email().required("Please Enter Primary Email"),
            primaryPhone: Yup.string().required("Please Enter Primary Phone"),
            primaryCompanyName: Yup.string().required("Please Enter Primary Company Name"),
        }),
        onSubmit: (values: any) => {
            saveProfileData(values);
        }
    });

    const saveProfileData = async (values) => {
        try {
            let user = getLoggedInUser();
            const formData = new FormData();
            formData.append('companyName', values.companyName);
            formData.append('email', values.email);
            formData.append('addressLine1', values.addressLine1);
            formData.append('addressLine2', values.addressLine2);
            formData.append('state', values.state);
            formData.append('city', values.city);
            formData.append('pincode', values.pincode);
            formData.append('primaryContactFirstName', values.primaryFirstName);
            formData.append('primaryContactLastName', values.primaryLastName);
            formData.append('primaryContactEmail', values.primaryEmail);
            formData.append('primaryContactPhone', values.primaryPhone);
            formData.append('primaryContactCompanyName', values.primaryCompanyName);
            formData.append('userId', user.id);
            formData.append('profilePicture', values.profilePicture);

            let response = await postAddClient(formData);
            if (response && response.success) {
                setIsBasicInfo(true);
                setClientId(response.data.id)
                toast.success(response.message)
                setAddClientModal(false);
                fetchClientData()
                // handleTabClick('tab2')
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
            }
        } catch (error) {
            toast.error("An error occurred while saving profile data. Please try again.");
        }
    }

    const handleClick = (item) => {
        localStorage.setItem("selected-client", JSON.stringify(item));
        navigate(`/monthly`);
    };

    const handleImageChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        if (file) {
            setFieldValue("profilePicture", file);

            const reader: any = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // providetr tab
    const handleInputChange = (index, event) => {
        const newData = [...providerData];
        newData[index][event.target.name] = event.target.value;
        setProviderData(newData);
    };

    const addProvider = () => {
        if (providerData.length < 10) {
            setProviderData([...providerData, { providerName: '', providerNo: '' }]);
        }
    };

    const deleteProvider = (index) => {
        if (providerData.length !== 1) {
            // Do not delete the first row
            const newData = [...providerData];
            // newData.pop();
            newData.splice(index, 1);
            setProviderData(newData);

        }
    };

    // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setInputValues({
    //         ...inputValues,
    //         [e.target.name]: e.target.value,
    //     });
    // };
    // const addProvider = () => {
    //     if (!inputValues.providerNo.trim() || !inputValues.providerName.trim()) {
    //         return;
    //     }

    //     const newProvider: Provider = {
    //         id: providerData.length + 1,
    //         providerNo: inputValues.providerNo,
    //         providerName: inputValues.providerName,
    //     };

    //     setProviderData((prevProviderData) => [...prevProviderData, newProvider]);

    //     // Clear input values after adding the provider
    //     setInputValues({
    //         providerNo: '',
    //         providerName: '',
    //     });
    // };

    //next tab

    // const nextTab = () => {
    //     setActiveTab('tab2');
    // }


    // const previousProvider = () => {
    //     setActiveTab('tab1');
    // }

    // const nextProvider = () => {
    //     setActiveTab('tab3');
    // }

    // const previousDataField = () => {
    //     setActiveTab('tab2');
    // }

    const validateData = () => {
        for (const provider of providerData) {
            if (provider.providerName === '' || provider.providerNo === '') {
                toast.error('Please fill in all fields before saving.');
                return false;
            }
        }
        return true;
    };

    const saveProvider = async (e) => {
        e.preventDefault();
        if (validateData()) {
            let formData: any = {};
            formData.userId = clientId;
            formData.providerInfo = providerData;
            let response = await postAddProviderInfo(formData);
            if (response && response.success) {
                toast.success(response.message)
                handleTabClick('tab3')
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
            }
        }
    }


    //data field tab
    const handleCheckboxChange = (event) => {
        setSelectAllChecked(event.target.checked);
        setIndividualCheckboxes((prevCheckboxes) => prevCheckboxes.map(() => event.target.checked));
    };

    const handleIndividualCheckboxChange = (index) => {
        setIndividualCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = [...prevCheckboxes];
            updatedCheckboxes[index] = !updatedCheckboxes[index];
            return updatedCheckboxes;
        });
    };

    const selectAll = () => {
        setSelectAllChecked(!selectAllChecked);
        setIndividualCheckboxes((prevCheckboxes) => prevCheckboxes.map(() => !selectAllChecked));
    };

    const saveDataField = async (e) => {
        e.preventDefault();
        const checkedData = dataFields.filter((row, index) => individualCheckboxes[index]);
        const resultArray = dataFields.map((item, index) => ({
            dataFieldId: item.id,
            isChecked: individualCheckboxes[index],
        }));
        if (checkedData.length > 0) {
            let formData: any = {};
            formData.userId = clientId;
            formData.dataFields = resultArray;
            let response = await postAddDataFields(formData);
            if (response && response.success) {
                toast.success(response.message);
                setAddClientModal(false);
                fetchClientData()
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
            }
        } else {
            toast.error("No checkboxes are selected.")
        }
    }

    const fetchDataFields = async () => {
        try {
            const body = {
                clientId: clients?.user_id ?? '',
            }
            let response = await getDataFields(body);
            if (response && response.success && (response.data && response.data.length > 0)) {
                setDataFields(response?.data);
            } else {
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
            }
        } catch (error) {
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    const addClientClick = () => {
        clearAllData();
        setClientId(0);
        setIsBasicInfo(false)
        handleTabClick('tab1')
        if (dataFields.length === 0) {
            fetchDataFields();
        }
        setAddClientModal(true)
    }

    const clearAllData = () => {
        setImagePreview(null)
        validation.resetForm();
        setProviderData([{ providerName: '', providerNo: '' }]);
        setIndividualCheckboxes(Array(dataFields.length).fill(false))
    }

    const fetchClientData = async () => {
        try {
            setIsLoading(true);
            let response = await getClients();
            if (response && response.success && (response.data && response.data.length > 0)) {
                setClientData(response?.data);
                setIsLoading(false);
            } else {
                toast.error(response?.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    useEffect(() => {
        fetchClientData();
    }, []);

    function formatDate(dateString) {
        // Split the date and time parts
        const [datePart, timePart] = dateString.split(' ');

        // Split the date part into day, month, and year
        const [day, month, year] = datePart.split('-');

        // Return the formatted date string in mm-dd-yyyy format
        return `${month}/${day}/${year}`;
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div>
                        <div className="d-flex align-items-center justify-content-between commen_top">
                            <div className="d-flex align-items-center">
                                <h4 className="mb-sm-0 font-size-18">{clientData.length} {clientData.length === 1 ? 'Client' : 'Clients'}</h4>
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={addClientClick}
                                >Add a Client </button>
                            </div>
                            {/* <div className="right_select">
                                <select name="" id="" className="form-select">
                                    <option value="">Recently Updated</option>
                                    <option value="">Recently Updated</option>
                                    <option value="">Recently Updated</option>
                                </select>
                            </div> */}
                        </div>

                        <div className="table-responsive client_list_table">
                            <Table className="table">
                                <tbody>
                                    {clientData.length > 0 ? (
                                        clientData.map((item, index) => (
                                            <tr key={index} style={{ cursor: 'pointer' }} onClick={() => handleClick(item)}>
                                                <td className="w45">
                                                    <Avatar name={item?.company_name} src={`${Setting?.API_URL}uploads/clients/${item?.profile_picture}`} size="50" round={true} />
                                                </td>
                                                <td className="text-capitalize">{item?.company_name}</td>
                                                <td>
                                                    {item?.flagged && (
                                                        <div className="fleg_black">{item?.flagged}</div>
                                                    )}
                                                </td>
                                                {/* <td>Last Locked: {formatDate(item?.created_at)}</td> */}
                                                <td className="text-end">Last Update: {formatDate(item?.updated_at)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={tableRow} className="text-center">No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Container>
            </div>

            {/* CREATE CLIENT MODAL */}
            <Modal
                isOpen={addClientModal}
                autoFocus={true}
                size="lg"
                centered
                toggle={() => { setAddClientModal(!addClientModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setAddClientModal(!addClientModal) }} >Add Client</ModalHeader>
                <ModalBody className="client-modal-body">
                    <div className="tab-header">
                        <button
                            type="button"
                            className={activeTab === 'tab1' ? 'active' : ''}
                            onClick={() => handleTabClick('tab1')}
                        >
                            Profile
                        </button>
                        {/* <button
                            type="button"
                            className={isBasicInfoSave ? activeTab === 'tab2' ? 'active' : '' : 'disable-data'}
                            onClick={() => handleTabClick('tab2')}
                            disabled={!isBasicInfoSave}
                        >
                            Provider Info
                        </button>
                        <button
                            type="button"
                            className={isBasicInfoSave ? activeTab === 'tab3' ? 'active' : '' : 'disable-data'}
                            onClick={() => handleTabClick('tab3')}
                            disabled={!isBasicInfoSave}
                        >
                            Data Fields
                        </button> */}
                    </div>
                    <div className="tab-content">
                        <TabContent tabId="tab1" activeTab={activeTab}>
                            <Form
                                className="needs-validation"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="providerTable">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="profilePicture">Profile Picture</Label>
                                                <Input
                                                    name="profilePicture"
                                                    type="file"
                                                    className="form-control"
                                                    id="profilePicture"
                                                    accept="image/*"
                                                    onChange={(event) => handleImageChange(event, validation.setFieldValue)}
                                                    invalid={
                                                        validation.touched.profilePicture && validation.errors.profilePicture ? true : false
                                                    }
                                                />
                                                {validation.errors && validation.errors.profilePicture ? (
                                                    <FormFeedback type="invalid">{validation.errors.profilePicture}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            {
                                                imagePreview && <Card
                                                    style={{
                                                        width: '7rem'
                                                    }}
                                                >
                                                    <img
                                                        className="image-preview"
                                                        alt="Preview"
                                                        src={imagePreview}
                                                    />
                                                </Card>
                                            }
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="companyName">Company Name</Label>
                                                <Input
                                                    name="companyName"
                                                    placeholder="Company Name"
                                                    type="text"
                                                    className="form-control"
                                                    id="companyName"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.companyName || ""}
                                                    invalid={
                                                        validation.touched.companyName && validation.errors.companyName ? true : false
                                                    }
                                                />
                                                {validation.touched.companyName && validation.errors.companyName ? (
                                                    <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="email">Email</Label>
                                                <Input
                                                    name="email"
                                                    placeholder="Email"
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="addressLine1">Address Line 1</Label>
                                                <Input
                                                    name="addressLine1"
                                                    placeholder="Address Line 1"
                                                    type="text"
                                                    className="form-control"
                                                    id="addressLine1"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.addressLine1 || ""}
                                                    invalid={
                                                        validation.touched.addressLine1 && validation.errors.addressLine1 ? true : false
                                                    }
                                                />
                                                {validation.touched.addressLine1 && validation.errors.addressLine1 ? (
                                                    <FormFeedback type="invalid">{validation.errors.addressLine1}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="addressLine2">Address Line 2</Label>
                                                <Input
                                                    name="addressLine2"
                                                    placeholder="Address Line 2"
                                                    type="text"
                                                    className="form-control"
                                                    id="addressLine2"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.addressLine2 || ""}
                                                    invalid={
                                                        validation.touched.addressLine2 && validation.errors.addressLine2 ? true : false
                                                    }
                                                />
                                                {validation.touched.addressLine2 && validation.errors.addressLine2 ? (
                                                    <FormFeedback type="invalid">{validation.errors.addressLine2}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="state">State</Label>
                                                <Input
                                                    name="state"
                                                    placeholder="State"
                                                    type="text"
                                                    className="form-control"
                                                    id="state"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.state || ""}
                                                    invalid={
                                                        validation.touched.state && validation.errors.state ? true : false
                                                    }
                                                />
                                                {validation.touched.state && validation.errors.state ? (
                                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="city">City</Label>
                                                <Input
                                                    name="city"
                                                    placeholder="City"
                                                    type="text"
                                                    id="city"
                                                    className="form-control"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.city || ""}
                                                    invalid={
                                                        validation.touched.city && validation.errors.city ? true : false
                                                    }
                                                />
                                                {validation.touched.city && validation.errors.city ? (
                                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="pincode">Pincode</Label>
                                                <Input
                                                    name="pincode"
                                                    placeholder="Pincode"
                                                    type="text"
                                                    className="form-control"
                                                    id="pincode"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.pincode || ""}
                                                    invalid={
                                                        validation.touched.pincode && validation.errors.pincode ? true : false
                                                    }
                                                />
                                                {validation.touched.pincode && validation.errors.pincode ? (
                                                    <FormFeedback type="invalid">{validation.errors.pincode}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="mt-2 mb-1">
                                            <h4>Primary Contact Details</h4>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="primaryFirstName">First Name</Label>
                                                <Input
                                                    name="primaryFirstName"
                                                    placeholder="First Name"
                                                    type="text"
                                                    className="form-control"
                                                    id="primaryFirstName"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.primaryFirstName || ""}
                                                    invalid={
                                                        validation.touched.primaryFirstName && validation.errors.primaryFirstName ? true : false
                                                    }
                                                />
                                                {validation.touched.primaryFirstName && validation.errors.primaryFirstName ? (
                                                    <FormFeedback type="invalid">{validation.errors.primaryFirstName}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="primaryLastName">Last Name</Label>
                                                <Input
                                                    name="primaryLastName"
                                                    placeholder="Last Name"
                                                    type="text"
                                                    className="form-control"
                                                    id="primaryLastName"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.primaryLastName || ""}
                                                    invalid={
                                                        validation.touched.primaryLastName && validation.errors.primaryLastName ? true : false
                                                    }
                                                />
                                                {validation.touched.primaryLastName && validation.errors.primaryLastName ? (
                                                    <FormFeedback type="invalid">{validation.errors.primaryLastName}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="primaryEmail">Email</Label>
                                                <Input
                                                    name="primaryEmail"
                                                    placeholder="Email"
                                                    type="text"
                                                    className="form-control"
                                                    id="primaryEmail"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.primaryEmail || ""}
                                                    invalid={
                                                        validation.touched.primaryEmail && validation.errors.primaryEmail ? true : false
                                                    }
                                                />
                                                {validation.touched.primaryEmail && validation.errors.primaryEmail ? (
                                                    <FormFeedback type="invalid">{validation.errors.primaryEmail}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="primaryPhone">Phone Number</Label>
                                                <Input
                                                    name="primaryPhone"
                                                    placeholder="Phone Number"
                                                    type="text"
                                                    className="form-control"
                                                    id="primaryPhone"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.primaryPhone || ""}
                                                    invalid={
                                                        validation.touched.primaryPhone && validation.errors.primaryPhone ? true : false
                                                    }
                                                />
                                                {validation.touched.primaryPhone && validation.errors.primaryPhone ? (
                                                    <FormFeedback type="invalid">{validation.errors.primaryPhone}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="primaryCompanyName">Company Name</Label>
                                                <Input
                                                    name="primaryCompanyName"
                                                    placeholder="Company Name"
                                                    type="text"
                                                    className="form-control"
                                                    id="primaryCompanyName"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.primaryCompanyName || ""}
                                                    invalid={
                                                        validation.touched.primaryCompanyName && validation.errors.primaryCompanyName ? true : false
                                                    }
                                                />
                                                {validation.touched.primaryCompanyName && validation.errors.primaryCompanyName ? (
                                                    <FormFeedback type="invalid">{validation.errors.primaryCompanyName}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="modal-footer text-end">
                                    <Button color="primary" type="submit" className="list-inline-item mr-3" >Save</Button>
                                    {/* <Button color="primary" onClick={saveDataField}  className="list-inline-item mr-3" >Save</Button> */}
                                    {/* <Button color="primary" type="button" disabled={isProvider} onClick={nextTab}>Next</Button> */}
                                </div>
                            </Form>
                        </TabContent>
                        {/* <TabContent tabId="tab2" activeTab={activeTab}>
                            <Form>
                                <div className="providerTable">
                                    {providerData.map((provider, index) => (
                                        <Row key={index}>
                                            <Col md="5" key={index + 'name'}>
                                                <FormGroup className="mb-3">
                                                    <Input
                                                        name="providerName"
                                                        placeholder="Provider Name"
                                                        type="text"
                                                        className="form-control"
                                                        id={`providerName-${index}`}
                                                        value={provider.providerName}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="5" key={index + 'id'}>
                                                <FormGroup className="mb-3">
                                                    <Input
                                                        name="providerNo"
                                                        placeholder="Provider Id"
                                                        type="text"
                                                        className="form-control"
                                                        id={`providerNo-${index}`}
                                                        value={provider.providerNo}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="2">
                                                {index === 0 ?
                                                    <Button style={{ backgroundColor: 'white' }} color="primary" type="button" className="list-inline-item" onClick={addProvider}>
                                                        <AddIcon style={{ color: 'green' }} />
                                                    </Button>
                                                    : null}
                                                {index !== 0 ?
                                                    <Button style={{ backgroundColor: 'white' }} color="primary" type="button" className="list-inline-item" onClick={() => { deleteProvider(index) }}>
                                                        <DeleteIcon style={{ color: 'red' }} />
                                                    </Button>
                                                    : null}
                                            </Col>


                                        </Row>
                                    ))}
                                </div>
                                <div className="modal-footer text-end">
                                    <Button color="primary" type="submit" onClick={saveProvider} className="list-inline-item mr-3" >Save</Button>
                                </div>
                            </Form>
                        </TabContent>
                        <TabContent tabId="tab3" activeTab={activeTab}>
                            <Form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-container">
                                            <div className="table-responsive data_table_box data_fileld">
                                                <div>
                                                    <table className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>
                                                                    <input type="checkbox" checked={selectAllChecked} onChange={handleCheckboxChange} />
                                                                </th>
                                                                <th onClick={selectAll}>TRG System</th>
                                                                <th>Client Term</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dataFields && dataFields.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input type="checkbox" checked={individualCheckboxes[index]} onChange={() => handleIndividualCheckboxChange(index)} />
                                                                    </td>
                                                                    <td>{row?.trg_system}</td>
                                                                    <td>{row?.client_term}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer text-end">
                                    <div className="sticky-save-button">
                                        <Button color="primary" type="submit" onClick={saveDataField} className="list-inline-item mr-3">Save</Button>
                                    </div>
                                </div>
                            </Form>
                        </TabContent> */}
                    </div>
                </ModalBody>
                {/* <ModalBody>
                    <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="profilePicture">Profile Picture</Label>
                                    <Input
                                        name="profilePicture"
                                        type="file"
                                        className="form-control"
                                        id="profilePicture"
                                        accept="image/*"
                                        onChange={(event) => handleImageChange(event, validation.setFieldValue)}
                                    />
                                    {validation.touched.profilePicture && validation.errors.profilePicture ? (
                                        <FormFeedback type="invalid">{validation.errors.profilePicture}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                {
                                    imagePreview && <Card
                                        style={{
                                            width: '7rem'
                                        }}
                                    >
                                        <img
                                            className="image-preview"
                                            alt="Preview"
                                            src={imagePreview}
                                        />
                                    </Card>
                                }
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="firstName">First Name</Label>
                                    <Input
                                        name="firstName"
                                        placeholder="First Name"
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.firstName || ""}
                                        invalid={
                                            validation.touched.firstName && validation.errors.firstName ? true : false
                                        }
                                    />
                                    {validation.touched.firstName && validation.errors.firstName ? (
                                        <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="lastName">Last Name</Label>
                                    <Input
                                        name="lastName"
                                        placeholder="Last Name"
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.lastName || ""}
                                        invalid={
                                            validation.touched.lastName && validation.errors.lastName ? true : false
                                        }
                                    />
                                    {validation.touched.lastName && validation.errors.lastName ? (
                                        <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="email">Email</Label>
                                    <Input
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="addressLine1">Address Line 1</Label>
                                    <Input
                                        name="addressLine1"
                                        placeholder="Address Line 1"
                                        type="text"
                                        className="form-control"
                                        id="addressLine1"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.addressLine1 || ""}
                                        invalid={
                                            validation.touched.addressLine1 && validation.errors.addressLine1 ? true : false
                                        }
                                    />
                                    {validation.touched.addressLine1 && validation.errors.addressLine1 ? (
                                        <FormFeedback type="invalid">{validation.errors.addressLine1}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="addressLine2">Address Line 2</Label>
                                    <Input
                                        name="addressLine2"
                                        placeholder="Address Line 2"
                                        type="text"
                                        className="form-control"
                                        id="addressLine2"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.addressLine2 || ""}
                                        invalid={
                                            validation.touched.addressLine2 && validation.errors.addressLine2 ? true : false
                                        }
                                    />
                                    {validation.touched.addressLine2 && validation.errors.addressLine2 ? (
                                        <FormFeedback type="invalid">{validation.errors.addressLine2}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="state">State</Label>
                                    <Input
                                        name="state"
                                        placeholder="State"
                                        type="text"
                                        className="form-control"
                                        id="state"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.state || ""}
                                        invalid={
                                            validation.touched.state && validation.errors.state ? true : false
                                        }
                                    />
                                    {validation.touched.state && validation.errors.state ? (
                                        <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="city">City</Label>
                                    <Input
                                        name="city"
                                        placeholder="City"
                                        type="text"
                                        id="city"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.city || ""}
                                        invalid={
                                            validation.touched.city && validation.errors.city ? true : false
                                        }
                                    />
                                    {validation.touched.city && validation.errors.city ? (
                                        <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="pincode">Pincode</Label>
                                    <Input
                                        name="pincode"
                                        placeholder="Pincode"
                                        type="text"
                                        className="form-control"
                                        id="pincode"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.pincode || ""}
                                        invalid={
                                            validation.touched.pincode && validation.errors.pincode ? true : false
                                        }
                                    />
                                    {validation.touched.pincode && validation.errors.pincode ? (
                                        <FormFeedback type="invalid">{validation.errors.pincode}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="mt-2 mb-1">
                                <h4>Primary Contact Details</h4>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="primaryFirstName">First Name</Label>
                                    <Input
                                        name="primaryFirstName"
                                        placeholder="First Name"
                                        type="text"
                                        className="form-control"
                                        id="primaryFirstName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.primaryFirstName || ""}
                                        invalid={
                                            validation.touched.primaryFirstName && validation.errors.primaryFirstName ? true : false
                                        }
                                    />
                                    {validation.touched.primaryFirstName && validation.errors.primaryFirstName ? (
                                        <FormFeedback type="invalid">{validation.errors.primaryFirstName}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="primaryLastName">Last Name</Label>
                                    <Input
                                        name="primaryLastName"
                                        placeholder="Last Name"
                                        type="text"
                                        className="form-control"
                                        id="primaryLastName"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.primaryLastName || ""}
                                        invalid={
                                            validation.touched.primaryLastName && validation.errors.primaryLastName ? true : false
                                        }
                                    />
                                    {validation.touched.primaryLastName && validation.errors.primaryLastName ? (
                                        <FormFeedback type="invalid">{validation.errors.primaryLastName}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="primaryEmail">Email</Label>
                                    <Input
                                        name="primaryEmail"
                                        placeholder="Email"
                                        type="text"
                                        className="form-control"
                                        id="primaryEmail"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.primaryEmail || ""}
                                        invalid={
                                            validation.touched.primaryEmail && validation.errors.primaryEmail ? true : false
                                        }
                                    />
                                    {validation.touched.primaryEmail && validation.errors.primaryEmail ? (
                                        <FormFeedback type="invalid">{validation.errors.primaryEmail}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="primaryPhone">Phone Number</Label>
                                    <Input
                                        name="primaryPhone"
                                        placeholder="Phone Number"
                                        type="text"
                                        className="form-control"
                                        id="primaryPhone"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.primaryPhone || ""}
                                        invalid={
                                            validation.touched.primaryPhone && validation.errors.primaryPhone ? true : false
                                        }
                                    />
                                    {validation.touched.primaryPhone && validation.errors.primaryPhone ? (
                                        <FormFeedback type="invalid">{validation.errors.primaryPhone}</FormFeedback>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button color="primary" size="lg" type="submit">Save</Button>
                    </Form>
                </ModalBody> */}
            </Modal >
        </React.Fragment>
    );
}

export default AdminDashboard;