import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Container } from "reactstrap";
import './index.scss';
import StickyTableContainer from "Components/Common/StickyTableContainer";
import { getMonthlyARReportDataURL } from "helpers/backend_helper";
import { isExpireToken } from "slices/thunk";
import moment from "moment";
import { useLoader } from "provider/LoadingContext";
import { toast } from "react-hot-toast";
import ARMonthlySummaryDetails from "../AR-Monthly-Summary-Details";

function ARMonthlySummary(props: any) {
    const { setIsLoading } = useLoader();
    const navigate = useNavigate();
    const clients = JSON.parse(localStorage.getItem("selected-client") || '');
    const [per, setPer] = useState<number>(2);
    const [summaryData, setsummaryData]: any = useState([]);
    const startDate = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
    const endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    const [isAr, setIsAr] = useState(false);
    const columns = useMemo(
        () => [
            {
                Header: "Month Year",
                filterable: false,
                disableFilters: true,
                Filter: false,
                disableSortBy: true,
                Cell: ({ row }) => (
                    <div className="first_column">
                        <a onClick={() => handleClick(row)}>
                            {getMonthYear(row.original.month_date)}
                        </a>
                    </div>
                ),
            },
            {
                Header: () => (
                    <div style={{ textAlign: 'right'}}>
                        AR for this month
                    </div>
                ),
                accessor: 'ar_for_this_month',
                filterable: false,
                disableFilters: true,
                Filter: false,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.ar_for_this_month);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>
                },
            },
            {
                // Header: "Net for this month",
                Header: () => (
                    <div style={{ textAlign: 'right'}}>
                        Net for this month
                    </div>
                ),
                accessor: 'net_for_this_month',
                filterable: false,
                disableFilters: true,
                Filter: false,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.net_for_this_month);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },

            },
            {
                Header: () => (
                    <div style={{ textAlign: 'right'}}>
                        Current Contractual
                    </div>
                ),
                accessor: 'current_contractual',
                filterable: false,
                disableFilters: true,
                Filter: false,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.current_contractual);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },

            {
                // Header: "Net to Gross %",
                Header: () => (
                    <div style={{ textAlign: 'right'}}>
                        Net to Gross %
                    </div>
                ),
                accessor: 'net_to_gross_percentage',
                filterable: false,
                disableFilters: true,
                Filter: false,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const val = parseFloat(row.original.net_to_gross_percentage).toFixed(2);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {`${val}%`}
                    </span>;
                },
            },
            {
                Header: () => (
                    <div style={{ textAlign: 'right'}}>
                        Prior Contractual %
                    </div>
                ),
                accessor: 'prior_contractual_percentage',
                filterable: false,
                disableFilters: true,
                Filter: false,
                disableSortBy: true,
                Cell: ({ row }) => {
                    const arForThisMonth = row.original.prior_contractual_percentage; // Accessing `prior_contractual_percentage` from the row data
                    const numericValue = parseFloat(row.value); // Parse the cell value
            
                    // Check if `prior_contractual_percentage` is null or `numericValue` is NaN
                    if (arForThisMonth === null || isNaN(numericValue)) {
                        return <span style={{ textAlign: 'end', display: 'block' }}>N/A</span>;
                    }
            
                    // Return percentage with fixed decimals if valid
                    return (
                        <span style={{ textAlign: 'end', display: 'block' }}>
                            {`${numericValue.toFixed(2)}%`}
                        </span>
                    );
                },
            },
            {
                Header: () => (
                    <div style={{ textAlign: 'right'}}>
                        Current Contractual %
                    </div>
                ),
                accessor: 'current_contractual_percentage',
                filterable: false,
                disableFilters: true,
                Filter: false,
                disableSortBy: true,
                Cell: ({ value }) => {
                    const numericValue = parseFloat(value);
                    const percentage = isNaN(numericValue) ? "0.00" : numericValue.toFixed(2);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {`${percentage}%`}
                    </span>;
                },
            },

        ],
        []
    );

    const handleClick = (value) => {
        localStorage.setItem("ar-summary-details", JSON.stringify(value.original));
        // navigate(`/ar-monthly-summary-details`);
        setIsAr(true);
    }

    const getMonthlyARReportData = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                startDate: startDate,
                endDate: endDate,
            };
            let response = await getMonthlyARReportDataURL(body);
            if (response) {
                if (response.data.length > 0) {
                    setsummaryData(response.data);
                    // setsummaryData([]);
                }
                setIsLoading(false);
            } else {
                console.error("Failed to fetch data");
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const getMonthYear = (input) => {
        const [month, year] = input.split('-');
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const monthNumber = parseInt(month, 10);
        const monthName = monthNames[monthNumber - 1];
        return `${monthName} ${year}`;
    };

    useEffect(() => {
        getMonthlyARReportData();
    }, []);

    //meta title
    document.title = "Monthly Summary | Rubin Brown";

    return (
        <React.Fragment >
            {/* <div className="page-content"> */}

            {!isAr ?
                (<Container fluid={true}>
                    <div className="data_table_box log_table w-100">
                        <div className="" >
                            <StickyTableContainer
                                columns={columns}
                                data={summaryData}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={12}
                                setCustomPageSize={1}
                                tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover"
                                theadClass="table-light"
                                customPageSizeOption={true}
                                customPageSizeOptions={true}
                            />
                        </div>
                    </div>
                </Container>
                ) : (
                    <ARMonthlySummaryDetails isAr={isAr} setIsAr={setIsAr}></ARMonthlySummaryDetails>
                )
            }
            {/* </div> */}
        </React.Fragment>
    );
}


export default ARMonthlySummary;