import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Container } from "reactstrap";
import './index.scss';
import StickyTableContainer from "Components/Common/StickyTableContainer";
import { getMonthlyARReportDataURL, getMonthlyZBAReportDataURL } from "helpers/backend_helper";
import { isExpireToken } from "slices/thunk";
import moment from "moment";
import { useLoader } from "provider/LoadingContext";
import { toast } from "react-hot-toast";
import ZbaMonthlySummaryDetails from "../Zba-Monthly-Summary-Details";

function ZbaMonthlySummary(props: any) {
    const { setIsLoading } = useLoader();
    const navigate = useNavigate();
    const clients = JSON.parse(localStorage.getItem("selected-client") || '');
    const [per, setPer] = useState<number>(2);
    const [summaryData, setsummaryData]: any = useState([]);
    const startDate = moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
    const endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    const [isZba, setIsZba] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: "Month Year",
                filterable: false,
                disableFilters: true,
                disableSortBy: true,
                Filter: false,
                Cell: ({ row }) => (
                    <div className="first_column">
                        <a onClick={() => handleClick(row)}>
                            {getMonthYear(row.original.discharge_date)}
                        </a>
                    </div>
                ),
            },
            {
                Header: () => (
                    <div className="text-end">
                        Total Billed Charges
                    </div>
                ),
                accessor: 'total_billed_charges',
                filterable: false,
                disableFilters: true,
                disableSortBy: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.total_billed_charges);
                    return <div className="text-end">
                        {formattedValue}
                    </div>
                },
            },
            {
                // Header: "Net for this month",
                Header: () => (
                    <div className="text-end">
                        Total Actual Payments
                    </div>
                ),
                accessor: 'total_actual_payments',
                filterable: false,
                disableFilters: true,
                disableSortBy: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.total_actual_payments);
                    return <div className="text-end">
                        {formattedValue}
                    </div>
                },
            },
            {
                // Header: "Net to Gross %",
                Header: () => (
                    <div className="text-end">
                        Total Actual Contractual
                    </div>
                ),
                accessor: 'actual_contractual',
                filterable: false,
                disableFilters: true,
                disableSortBy: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.actual_contractual);
                    return <div className="text-end">
                        {formattedValue}
                    </div>
                },
            },
            {
                Header: () => (
                    <div className="text-end">
                        Payment %
                    </div>
                ),
                accessor: 'payment_percentage',
                filterable: false,
                disableFilters: true,
                disableSortBy: true,
                Filter: false,
                Cell: ({ row }) => {
                    const val = parseFloat(row.original.payment_percentage).toFixed(2);
                    return <div className="text-end">
                          {`${val}%`}
                    </div>;
                },
            },
            {
                // Header: "Current Contractual %",
                Header: () => (
                    <div className="text-end">
                        Contractual %
                    </div>
                ),
                accessor: 'contractual_percentage',
                filterable: false,
                disableFilters: true,
                disableSortBy: true,
                Filter: false,
                Cell: ({ row }) => {
                    const val = parseFloat(row.original.contractual_percentage).toFixed(2);
                    return <div className="text-end">
                         {`${val}%`}
                    </div>;
                },
            },
        ],
        []
    );

    const handleClick = (value) => {
        localStorage.setItem("zba-summary-details", JSON.stringify(value.original));
        // navigate(`/zba-monthly-summary-details`);
        setIsZba(true);
    }

    const getMonthlyZBAReportData = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                startDate: startDate,
                endDate: endDate,
                isThreshold: true
            };
            let response = await getMonthlyZBAReportDataURL(body);
            console.log("response", response);
            if (response) {
                if (response.data.length > 0) {
                    setsummaryData(response.data);
                }
                setIsLoading(false);
            } else {
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    const getMonthYear = (input) => {
        const [month, year] = input.split('-');
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const monthNumber = parseInt(month, 10);
        const monthName = monthNames[monthNumber - 1];
        return `${monthName} ${year}`;
    };

    useEffect(() => {
        getMonthlyZBAReportData();
    }, []);

    return (
        <React.Fragment >
            {/* <div className="page-content"> */}
            {!isZba ?
                (<Container fluid={true}>
                    <div className="data_table_box log_table">
                        <div className="" >
                            <StickyTableContainer
                                columns={columns}
                                data={summaryData}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={12}
                                setCustomPageSize={1}
                                tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover"
                                theadClass="table-light"
                                customPageSizeOption={true}
                                customPageSizeOptions={true}
                            />
                        </div>
                    </div>
                </Container>
                ) : (
                    <ZbaMonthlySummaryDetails isZba={isZba} setIsZba={setIsZba}></ZbaMonthlySummaryDetails>
                )
            }
        </React.Fragment>
    );
}


export default ZbaMonthlySummary;