import axios from "axios";
import Setting from "configs/settings";
import {useDispatch } from "react-redux";
import { logoutUser } from "slices/thunk";

// default
axios.defaults.baseURL = Setting.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
// content type
// let authUser: any = (localStorage.getItem("authUser"));

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message: any;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "RYBAR_JWT " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url: any, params: any) => {
    let response: any;

    let paramKeys: any = [];
    let user = getLoggedinUser();
    let authToken = user?.token;

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, {
        params,
        headers: authToken ? { 'Authorization': `RYBAR_JWT ${authToken}` } : {},
      });
    } else {
      response = axios.get(`${url}`, {
        headers: authToken ? { 'Authorization': `RYBAR_JWT ${authToken}` } : {},
      });
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url: any, data: any) => {
    let user = getLoggedinUser();
    let authToken = user?.token;
    setAuthorization(authToken)
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url: any, data: any) => {
    return axios.patch(url, data);
  };

  put = (url: any, data: any) => {
    return axios.put(url, data);
  };

  /** upload */
  upload = (url: string, formData: FormData) => {
    let user = getLoggedinUser();
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `RYBAR_JWT ${user?.token}`,
      },
    });
  };
  /**
   * Delete
   */
  delete = (url: any, config: any) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };