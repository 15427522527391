import React from 'react';
import { useLoader } from 'provider/LoadingContext';

const GlobalSpinner = () => {
    const { isLoading } = useLoader();
    return (
        <>
            {isLoading ? (
                <div className="loader-display" >
                    <div
                        className="loader-absolute d-flex align-items-center justify-content-center"
                        style={{
                            position: 'fixed',
                            flexDirection: 'column',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 99999999999999,
                        }}
                    >
                        <div className="spinner-border text-primary" role="status">
                        </div>
                    </div>
                </div >
            ) : (
                <div></div>
            )}
        </>
    );
};

export default GlobalSpinner;