import React, { useState, useEffect, useMemo, useRef } from "react";
import './index.scss';
import { postGetClientProfileData, getSelectedClientData, saveClientProviderInfo, updateClientProviderInfo, updateClientAddress, updateClientPrimaryContact, updateClientDetails, getClientProviders, deleteClientProvider, getDataFields, createDataField, getAutoCompleteDataField, getDataFieldById, updateDataField, updateClientProfileImage, getPatientTypes, createPatientType, updatePatientType, deletePatientType, createZBAThreshold, getZBAThresholds, updateZBAThreshold, deleteZBAThreshold } from 'helpers/backend_helper'
import Avatar from 'react-avatar';
import Setting from "configs/settings";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {
    Card,
    Col,
    Container,
    Form,
    Row,
    Table,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    FormFeedback,
    Label,
    FormGroup,
    InputGroup,
} from "reactstrap";
import { useFormik } from "formik";
import TableContainer from "../../../Components/Common/TableContainer";
import editIcon from "../../../assets/images/edit.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import viewIcon from "../../../assets/images/show.svg";
import { isExpireToken } from "slices/thunk";
import { useNavigate } from "react-router-dom";
import { useLoader } from "provider/LoadingContext";

interface TabContentProps {
    activeTab: string;
    tabId: string;
    children: React.ReactNode;
}

const TabContent: React.FC<TabContentProps> = ({ activeTab, tabId, children }) => {
    return (
        <div role="tabpanel" hidden={activeTab !== tabId}>
            {children}
        </div>
    );
};

function Profile() {
    const { setIsLoading } = useLoader();
    // var clientData: any = getSelectedClientData();
    const navigate = useNavigate();
    const clients = JSON.parse(localStorage.getItem("selected-client") || '');
    const inputRefs = useRef([]);
    // const navigate = useNavigate();
    const [profileData, setProfileData]: any = useState();
    const [addProviderModal, setAddProviderModal] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState('');

    //View Patient Type
    const [viewPatientTypeData, setviewPatientTypeData]: any = useState();
    const [viewPatientTypeModal, setViewPatientTypeModal] = useState<boolean>(false);

    //Edit Patient Type
    const [editPatientTypeData, setEditPatientTypeData]: any = useState();
    const [editPatientTypeModal, setEditPatientTypeModal] = useState<boolean>(false);

    //Delete Patient Type
    const [deletePatientTypeData, setDeletePatientTypeData]: any = useState();
    const [deletePatientTypeModal, setDeletePatientTypeModal] = useState<boolean>(false);


    //Add Patient Type
    const [patientTypeData, setPatientTypeData]: any = useState([]);
    const [addPatientTypeModal, setAddPatientTypeModal] = useState<boolean>(false);


    //Add ZBA
    const [zbaData, setZbaData]: any = useState([]);
    const [addZbaModal, setAddZbaModal] = useState<boolean>(false);

    //Edit ZBA
    const [editZbaData, setEditZbaData]: any = useState();
    const [editZbaModal, setEditZbaModal] = useState<boolean>(false);

    //Delete Patient Type
    const [deleteZbaData, setDeleteZbaData]: any = useState();
    const [deleteZbaModal, setDeleteZbaModal] = useState<boolean>(false);

    //edit provider
    const [editProviderModal, setEditProviderModal] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider]: any = useState(null);

    //edit Address
    const [editAddressModal, setEditAddressModal] = useState<boolean>(false);
    const [selectedAddress, setSelectedAddress]: any = useState(null);

    //edit contact
    const [editContactModal, setEditContactModal] = useState<boolean>(false);
    const [selectedContact, setSelectedContact]: any = useState(null);

    //edit profile
    const [editProfileModal, setEditProfileModal] = useState<boolean>(false);
    const [selectedProfile, setSelectedProfile]: any = useState(null);

    const [editProfileLogoModal, setEditProfileLogoModal] = useState<boolean>(false);
    const [imagePreview, setImagePreview]: any = useState(null);
    const [fileName, setFileName]: any = useState(null);
    // const [selectedFile, setSelectedFile] = useState<File | null>();

    // get provider
    const [providerData, setProviderData]: any = useState([]);

    //get data fields drop-down
    const [dataFields, setDataFields]: any = useState([]);
    const [selectedValue, setSelectedValue] = useState('');

    //edit Mapping
    const [editMappingModal, setEditMappingModal] = useState<boolean>(false);
    // State to store table data
    const [tableData, setTableData]: any = useState([]);
    const [newRow, setNewRow] = useState({ start_date: '', end_date: '' });
    const [isAdding, setIsAdding] = useState(false);

    //new design start
    const [activeTab, setActiveTab] = useState<string>('tab1');





    //new design end


    const imageValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            profilePicture: profileData ? profileData.profile_picture : '',
        },
        validationSchema: Yup.object().shape({
            profilePicture: Yup.mixed().required("Please upload profile picture")
                .test("fileSize", "File too large", value => value && value.size <= 2000000)
                .test("fileFormat", "Unsupported file format", value => value && ['image/jpeg', 'image/png'].includes(value?.type)),
        }),
        onSubmit: (values: any) => {
            updateLogo(values)

        }
    });

    const updateLogo = async (value) => {
        let response = await updateClientProfileImage({ file: value.profilePicture }, clients.user_id);
        if (response && response.success) {
            toast.success(response.message);
            setImagePreview(null);
            setFileName(null);
            setEditProfileLogoModal(false);
            fetchUserProfileData();
            imageValidation.resetForm();
        } else {
            toast.error(response.message);
            if (response.code === '1') {
                isExpireToken(navigate);
            }
        }

    }

    // Handler to add a new row
    const addNewRow = () => {
        setIsAdding(true);
    };
    // Handler to save the new row
    const saveNewRow = async () => {
        try {
            setIsLoading(true);
            // Check if newRow.start_date is empty
            if (!newRow.start_date) {
                toast.error("Start date cannot be empty.");
                return;
            }

            let minStartDate = '';
            let maxStartDate = '';

            // Calculate minStartDate and maxStartDate from tableData if available
            if (tableData.length > 0) {
                const dates = tableData.map(record => new Date(record.start_date));
                minStartDate = new Date(Math.min(...dates)).toISOString().split('T')[0];
                maxStartDate = new Date(Math.max(...dates)).toISOString().split('T')[0];
            }

            // Validate the selected start_date against minStartDate and maxStartDate
            if (minStartDate && maxStartDate) {
                const selectedDateObj = new Date(newRow.start_date);
                const minDateObj = new Date(minStartDate);
                const maxDateObj = new Date(maxStartDate);

                if (selectedDateObj >= minDateObj && selectedDateObj <= maxDateObj) {
                    toast.error(`The selected date ${newRow.start_date} is between the range of ${minStartDate} and ${maxStartDate}.`);
                    return;
                }
            }

            // Add new row to the table data
            const newId = tableData.length + 1;
            setTableData([...tableData, { id: newId, ...newRow }]);
            setNewRow({ start_date: '', end_date: '' });

            // Prepare body for API request
            const body = {
                clientId: clients.user_id,
                startDate: newRow.start_date
            };

            // Make API request to create data field
            let response = await createDataField(body);
            if (response && response.success) {
                toast.success(response.message);
                setIsAdding(false);
                setEditMappingModal(false);
                fetchGetDataFields();
                setIsLoading(false);
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while saving data. Please try again.");
        }
    };

    // Handler to cancel adding a new row
    const cancelNewRow = () => {
        setNewRow({ start_date: '', end_date: '' });
        setIsAdding(false);
    };
    // Handler to update input values
    const handleInputChange = (field, value) => {

        setNewRow({ ...newRow, [field]: value });
    };

    const [dataFieldTable, setDataFieldTable]: any = useState([]);

    // Add searchFilter
    const dataFileds = [
        { trgSystem: "Account Number", clientTerm: "" },
        { trgSystem: "Discharge Date", clientTerm: "" },
        { trgSystem: "Financial Class", clientTerm: "" },
        { trgSystem: "Patient Type", clientTerm: "" },
        { trgSystem: "Service Code", clientTerm: "" },
        { trgSystem: "Acct Total Charges", clientTerm: "" },
        { trgSystem: "Total Ins Payment", clientTerm: "" },
        { trgSystem: "Total Ins Contractual", clientTerm: "" },
        { trgSystem: "Total Pat Payment", clientTerm: "" },
        { trgSystem: "Total Payments", clientTerm: "" },
        { trgSystem: "Acct Total Charge Adjustments", clientTerm: "" },
        { trgSystem: "Acct Balance", clientTerm: "" }
    ];

    const columns = useMemo(
        () => [
            {
                Header: 'Provider Name',
                accessor: 'provider_name',
                Filter: false,
            },
            {
                Header: 'Provider No',
                accessor: 'provider_no',
                Filter: false,
            },
            {
                Header: 'Action',
                Filter: false,
                Cell: ({ row }) => (
                    <>
                        <a onClick={() => handleEditProvider(row.original)} className="edit_icon mr-2">
                        </a>

                        <a onClick={() => handleDeleteProvider(row.original)} className="delete_icon">
                            {/* <img ssrc={editIcon} className="eye_open" alt="Edit" style={{ height: '20px' }} /> */}
                        </a>
                    </>
                )
            }
        ],
        []
    );

    // edit table 
    const [editingIndex, setEditingIndex] = useState(null);
    const [editValue, setEditValue] = useState("");
    const [editColumnValue, setEditColumnValue] = useState("");

    const handleEditClick = (index, currentTerm, value) => {
        setEditingIndex(index);
        setEditValue(currentTerm);
        setEditColumnValue(value);
    };

    const handleSaveClick = async (index) => {
        try {
            setIsLoading(true);
            const updatedTable = dataFieldTable.map((row, i) => {
                if (i === index) {

                    return { ...row, clientTerm: editValue };
                }
                return row;
            });
            setDataFieldTable(updatedTable);
            const body = {
                clientId: clients.user_id,
                dataFieldId: selectedValue,
                trgSystem: editColumnValue,
                clientTerm: editValue
            }
            let response = await updateDataField(body);
            if (response && response?.success) {
                toast.success(response?.message);
                setEditingIndex(null);
                setEditValue("");
                setEditColumnValue("");
                fetchGetDataFields();
                getDataFieldTable(selectedValue);
                setIsLoading(false);
            } else {
                toast.error(response?.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while saving data. Please try again.");
        }

    };

    const handleCancelClick = () => {
        setEditingIndex(null);
        setEditValue("");
    };

    // const handleDeleteClick = (index) => {
    //     const updatedTable = dataFieldTable.filter((_, i) => i !== index);
    //     setDataFieldTable(updatedTable);
    // };

    //// add provider OLD
    const addProviderValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            providerName: '',
            providerNo: '',
        },
        validationSchema: Yup.object().shape({
            providerName: Yup.string().required("Please Enter Provider Name"),
            providerNo: Yup.number().required("Please Enter Provider No"),
        }),
        onSubmit: (values: any) => {
            saveProviderData(values);
        }
    });


    const saveProviderData = async (value) => {
        try {
            setIsLoading(true);
            const formData = {
                userId: clients.user_id,
                providerInfo: [
                    {
                        providerName: value.providerName,
                        providerNo: value.providerNo
                    }
                ]
            }
            let response = await saveClientProviderInfo(formData);
            if (response && response.success) {
                toast.success(response.message);
                addProviderValidation.resetForm();
                setAddProviderModal(false);
                fetchUserProvider();
                setIsLoading(false);
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while saving provider. Please try again.")
        }

    }

    //// edit provider
    const editProviderValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: selectedProvider ? selectedProvider.id : '',
            providerName: selectedProvider ? selectedProvider.provider_name : '',
            providerNo: selectedProvider ? selectedProvider.provider_no : '',
        },
        validationSchema: Yup.object().shape({
            providerName: Yup.string().required("Please Enter Provider Name"),
            providerNo: Yup.number().required("Please Enter Provider No"),
        }),
        onSubmit: (values: any) => {
            editProviderData(values);
        }
    });

    const editProviderData = async (value) => {
        try {
            setIsLoading(true);
            const body = {
                providerId: value.id,
                providerName: value.providerName,
                providerNo: value.providerNo
            }
            let response = await updateClientProviderInfo(body);
            if (response && response.success) {
                toast.success(response.message);
                editProviderValidation.resetForm();
                setEditProviderModal(false);
                fetchUserProvider();
                setIsLoading(false);
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while saving provider. Please try again.")
        }

    };

    //delete Provider
    const handleDeleteProvider = async (provider) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this provider?");
        if (confirmDelete) {
            // Your delete logic here
            try {
                setIsLoading(true);
                const body = {
                    "providerId": provider.id
                }
                let response = await deleteClientProvider(body);
                if (response && response.success) {
                    toast.success(response.message);
                    fetchUserProvider();
                    setIsLoading(false);
                } else {
                    toast.error(response.message);
                    if (response.code === '1') {
                        isExpireToken(navigate);
                    }
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                toast.error("An error occurred while saving provider. Please try again.")
            }
        }
    };

    /// edit Address
    const editAddressValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: selectedAddress ? selectedAddress.user_id : '',
            addressLine1: selectedAddress ? selectedAddress.address_line_1 : '',
            addressLine2: selectedAddress ? selectedAddress.address_line_2 : '',
            city: selectedAddress ? selectedAddress.city : '',
            state: selectedAddress ? selectedAddress.state : '',
            pincode: selectedAddress ? selectedAddress.pincode : ''
        },
        validationSchema: Yup.object().shape({
            userId: Yup.number(),
            addressLine1: Yup.string().required("Please Enter Address Line 1"),
            addressLine2: Yup.string(),
            city: Yup.string().required("Please Enter City"),
            state: Yup.string().required("Please Enter State"),
            pincode: Yup.number().min(6, "Pincode must be numeric and minimum 6 digits").required("Please Enter Pincode"),
        }),
        onSubmit: (values: any) => {
            editAddressData(values);
        }
    });

    const editAddressData = async (value) => {
        try {
            setIsLoading(true);
            const body = {
                userId: value.userId,
                addressLine1: value.addressLine1,
                addressLine2: value.addressLine2,
                state: value.city,
                city: value.state,
                pincode: value.pincode
            }
            let response = await updateClientAddress(body);
            if (response && response.success) {
                toast.success(response.message);
                editAddressValidation.resetForm();
                setEditAddressModal(false);
                fetchUserProfileData();
                setIsLoading(false);
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while saving provider. Please try again.")
        }

    }

    //// edit contact
    const editContactValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: selectedContact ? selectedContact.user_id : '',
            primaryFirstName: selectedContact ? selectedContact.primary_contact_first_name : '',
            primaryLastName: selectedContact ? selectedContact.primary_contact_last_name : '',
            primaryCompanyName: selectedContact ? selectedContact.primary_contact_company_name : '',
            primaryEmail: selectedContact ? selectedContact.primary_contact_email : '',
            primaryPhone: selectedContact ? selectedContact.primary_contact_phone : '',
        },
        validationSchema: Yup.object().shape({
            userId: Yup.number(),
            primaryFirstName: Yup.string().required("Please Enter Primary First Name"),
            primaryLastName: Yup.string().required("Please Enter Primary Last Name"),
            primaryCompanyName: Yup.string().required("Please Enter Primary Company Name"),
            primaryEmail: Yup.string().email().required("Please Enter Primary Email"),
            primaryPhone: Yup.string().required("Please Enter Primary Phone"),
        }),
        onSubmit: (values: any) => {
            editContactData(values);
        }
    });

    const editContactData = async (value) => {
        try {
            setIsLoading(true);
            const body = {
                userId: value.userId,
                primaryContactFirstName: value.primaryFirstName,
                primaryContactLastName: value.primaryLastName,
                primaryContactCompanyName: value.primaryCompanyName,
                primaryContactEmail: value.primaryEmail,
                primaryContactPhone: value.primaryPhone
            }
            let response = await updateClientPrimaryContact(body);
            if (response && response.success) {
                toast.success(response.message);
                editContactValidation.resetForm();
                setEditContactModal(false);
                fetchUserProfileData();
                setIsLoading(false);
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while saving provider. Please try again.")
        }

    }

    //// edit profile
    const editProfileValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            clientId: selectedProfile ? selectedProfile.user_id : '',
            companyName: selectedProfile ? selectedProfile.company_name : '',
            email: selectedProfile ? selectedProfile.email : '',
        },
        validationSchema: Yup.object().shape({
            userId: Yup.number(),
            companyName: Yup.string().required("Please Enter Company Name"),
            email: Yup.string().email().required("Please Enter Your Email"),
        }),
        onSubmit: (values: any) => {
            editProfileData(values);
        }
    });

    const editProfileData = async (value) => {
        try {
            setIsLoading(true);
            const body = {
                clientId: value.clientId,
                email: value.email,
                companyName: value.companyName,
            }
            let response = await updateClientDetails(body);
            if (response && response.success) {
                toast.success(response.message);
                editProfileValidation.resetForm();
                setEditProfileModal(false);
                fetchUserProfileData(); setIsLoading(false);
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while saving company name. Please try again.")
        }

    }

    // add patientTypeData 
    const addPatientTypeValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            clientId: clients.user_id,
            code: '',
            description: '',
            abbreviation: '',
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required("Please Enter Code"),
            description: Yup.string().required("Please Enter Description"),
            abbreviation: Yup.string().required("Please Enter Abbreviation"),
        }),
        onSubmit: (values: any) => {
            createPatientTypeData(values);
        }
    });

    const createPatientTypeData = async (values) => {
        try {
            setIsLoading(true);
            let response = await createPatientType(values);
            if (response && response.success) {
                toast.success(response.message);
                addPatientTypeValidation.resetForm();
                setAddPatientTypeModal(false);
                fetchPatientType();
                setIsLoading(false);
            } else {
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    // view patientTypeData 
    // const viewPatientTypeValidation: any = useFormik({
    //     enableReinitialize: true,
    //     initialValues: {
    //         clientId: viewPatientTypeData ? viewPatientTypeData.user_id : '',
    //         code: viewPatientTypeData ? viewPatientTypeData.patient_type : '',
    //         description: viewPatientTypeData ? viewPatientTypeData.description : '',
    //         abbreviation: viewPatientTypeData ? viewPatientTypeData.abbreviation : '',
    //     },
    //     validationSchema: Yup.object().shape({
    //         code: Yup.string().required("Please Enter Code"),
    //         description: Yup.string().required("Please Enter Description"),
    //         abbreviation: Yup.string().required("Please Enter Abbreviation"),
    //     }),
    //     onSubmit: (values: any) => {
    //     }
    // });

    // const handleview = (value) => {
    //     setviewPatientTypeData(value);
    //     setViewPatientTypeModal(true);

    // }

    // edit patientTypeData 

    const editPatientTypeValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            patient_type_id: editPatientTypeData ? editPatientTypeData.patient_type_id : '',
            clientId: clients.user_id,
            code: editPatientTypeData ? editPatientTypeData.patient_type : '',
            description: editPatientTypeData ? editPatientTypeData.description : '',
            abbreviation: editPatientTypeData ? editPatientTypeData.abbreviation : '',
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required("Please Enter Code"),
            description: Yup.string().required("Please Enter Description"),
            abbreviation: Yup.string().required("Please Enter Abbreviation"),
        }),
        onSubmit: (values: any) => {
            handleEditPatientTypeData(values);
        }
    });

    const handleEditPatientTypeData = async (value) => {
        try {
            setIsLoading(true);
            let response = await updatePatientType(value);
            if (response && response.success) {
                toast.success(response.message);
                setEditPatientTypeModal(false);
                editPatientTypeValidation.resetForm();
                fetchPatientType();
                setIsLoading(false);
            } else {
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while updating data. Please try again.")
        }
    }

    const handlEdit = (value) => {
        setEditPatientTypeData(value);
        setEditPatientTypeModal(true);

    }

    // delete Paisant Type
    const deletePatientTypeValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            patient_type_id: deletePatientTypeData ? deletePatientTypeData.patient_type_id : '',
            clientId: clients.user_id
        },
        onSubmit: (values: any) => {
            handleDeletePatientTypeData(values);
        }
    });

    const handleDeletePatientTypeData = async (value) => {
        try {
            setIsLoading(true);
            let response = await deletePatientType(value);
            if (response && response.success) {
                toast.success(response.message);
                setDeletePatientTypeModal(false);
                fetchPatientType();
                setIsLoading(false);
            } else {
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    const handleDelete = (value) => {
        setDeletePatientTypeData(value)
        setDeletePatientTypeModal(true);
    }

    const fetchPatientType = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
            }
            let response = await getPatientTypes(body);
            if (response && response.success) {
                if (response.data && response.data.length > 0) {
                    setPatientTypeData(response.data);
                } else {
                    setPatientTypeData([])
                }
                setIsLoading(false);
            } else {
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    // add ZBA 
    const addZbaValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            clientId: clients.user_id,
            name: '',
            lower_limit: 0,
            upper_limit: 0,
            start_date: '',
            end_date: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Please Enter Name"),
            lower_limit: Yup.number().required("Please Enter Lower Limit"),
            upper_limit: Yup.number()
                .required("Please Enter Upper Limit")
                .when('lower_limit', (lower_limit, schema) => {
                    return schema.test({
                        test: upper_limit => upper_limit > lower_limit,
                        message: "Upper Limit must be greater than Lower Limit"
                    });
                }),
            start_date: Yup.date().required("Please Enter Start Date"),
            end_date: Yup.date()
                .required("Please Enter End Date")
                .when('start_date', (start_date, schema) => {
                    return schema.test({
                        test: end_date => new Date(end_date) > new Date(start_date),
                        message: "End Date must be after Start Date"
                    });
                }),
        }),
        onSubmit: (values) => {
            createAtzData(values);
        }
    });

    const createAtzData = async (values) => {
        try {
            setIsLoading(true);
            let response = await createZBAThreshold(values);
            if (response && response.success) {
                toast.success(response.message);
                addZbaValidation.resetForm();
                setAddZbaModal(false);
                fetchZab();
                setIsLoading(false);
            } else {
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while creating data. Please try again.")
        }

    }

    //Edit ZBA
    const editZbaValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            clientId: clients.user_id,
            threshold_id: editZbaData ? editZbaData.threshold_id : '',
            name: editZbaData ? editZbaData.name : '',
            lower_limit: editZbaData ? editZbaData.lower_limit : 0,
            upper_limit: editZbaData ? editZbaData.upper_limit : 0,
            start_date: editZbaData ? editZbaData.start_date : '',
            end_date: editZbaData ? editZbaData.end_date : '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Please Enter Name"),
            lower_limit: Yup.number().required("Please Enter Lower Limit"),
            upper_limit: Yup.number()
                .required("Please Enter Upper Limit")
                .when('lower_limit', (lower_limit, schema) => {
                    return schema.test({
                        test: upper_limit => upper_limit > lower_limit,
                        message: "Upper Limit must be greater than Lower Limit"
                    });
                }),
            start_date: Yup.date().required("Please Enter Start Date"),
            end_date: Yup.date()
                .required("Please Enter End Date")
                .when('start_date', (start_date, schema) => {
                    return schema.test({
                        test: end_date => new Date(end_date) > new Date(start_date),
                        message: "End Date must be after Start Date"
                    });
                }),
        }),
        onSubmit: (values) => {
            editAtzData(values);
        }
    });

    const editAtzData = async (values) => {
        try {
            setIsLoading(true);
            let response = await updateZBAThreshold(values);
            if (response && response.success) {
                toast.success(response.message);
                setEditZbaModal(false);
                editZbaValidation.resetForm();
                fetchZab();
                setIsLoading(false);
            } else {
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while updating data. Please try again.")
        }

    }

    // delete Paisant Type
    const deleteZbaValidation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            threshold_id: deleteZbaData ? deleteZbaData.threshold_id : '',
            clientId: clients.user_id
        },
        onSubmit: (values: any) => {
            handleDeleteZbaData(values);
        }
    });

    const handleDeleteZbaData = async (value) => {
        try {
            setIsLoading(true);
            let response = await deleteZBAThreshold(value);
            if (response && response.success) {
                toast.success(response.message);
                setDeleteZbaModal(false);
                fetchZab();
                setIsLoading(false);
            } else {
                toast.error(response.message)
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while deleting data. Please try again.")
        }
    }

    const handleZbtEdit = (values) => {
        setEditZbaData(values);
        setEditZbaModal(true);
    }

    const handleZbtAdd = () => {
        setAddZbaModal(true);
        addZbaValidation.resetForm();

    }

    const handleZbaDelete = (value) => {
        setDeleteZbaData(value)
        setDeleteZbaModal(true);
    }

    // Get ZAB
    const fetchZab = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                pagination: false,
                page: 0,
                size: 10
            }
            let response = await getZBAThresholds(body);
            if (response && response.success) {
                if (response && response?.data && response?.data?.list && response?.data?.list?.length > 0) {
                    setZbaData(response.data.list);
                } else {
                    setZbaData([])
                }
                setIsLoading(false);
            } else {
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    {/* add data field */ }
    const toggleMappingModal = () => {
        setEditMappingModal(!editMappingModal);
        setNewRow({ start_date: '', end_date: '' });
        setIsAdding(false);
    };

    const fetchUserProfileData = async () => {
        try {
            setIsLoading(true);
            let response = await postGetClientProfileData(clients.user_id);
            if (response && response.success) {
                setProfileData(response.data);
                setIsLoading(false);
            } else {
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    useEffect(() => {
        fetchUserProfileData();
        fetchUserProvider();
        const date = new Date();
        const formattedDate = date.toISOString().split('T')[0];
        setCurrentDate(formattedDate);
    }, []);



    const fetchUserProvider = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                pagination: false,
                page: 0,
                size: 0
            }
            let response = await getClientProviders(body);
            if (response && response.success) {
                if (response.data && response.data.list && response.data.list.length > 0) {
                    setProviderData(response.data.list);
                    setIsLoading(false);
                }
            } else {
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    const fetchGetDataFields = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
            };
            let response = await getDataFields(body);
            if (response && response.success && Array.isArray(response.data) && response.data.length > 0) {
                const updatedDataArray = response.data.map(item => ({
                    ...item,
                    present: false
                }));
                const currentDate = new Date().toISOString().split('T')[0];
                const currentDateObjects = updatedDataArray.filter(item => formatDate(item.start_date) === formatDate(currentDate));
                if (currentDateObjects && currentDateObjects != undefined) {
                    currentDateObjects.forEach(item => {
                        item.present = true;
                    });
                }
                setDataFields(updatedDataArray);
                setSelectedValue(updatedDataArray[0].data_field_id);
                getDataFieldTable(updatedDataArray[0].data_field_id);
                setTableData(updatedDataArray);
                setIsLoading(false);
            } else {
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.");
        }
    };

    const handleAddProvider = () => {
        setAddProviderModal(true)
    }

    const handleEditProvider = (value) => {
        setSelectedProvider(value)
        setEditProviderModal(true);
    }

    const handleEditAddress = (value) => {
        setSelectedAddress(value)
        setEditAddressModal(true);
    }

    const handleEditContact = (value) => {
        setSelectedContact(value)
        setEditContactModal(true);
    }

    const handleEditProfile = (value) => {
        setSelectedProfile(value)
        setEditProfileModal(true);
    }

    const handleMapping = () => {
        setEditMappingModal(true)
    }

    // data field change event
    const handleDataFieldChange = (event) => {
        setSelectedValue(event.target.value);
        getDataFieldTable(event.target.value)

    };

    // useEffect(() => {
    //     if (dataFields.length > 0) {
    //         const currentMapping = dataFields.find(item => item.present === true);
    //         if (currentMapping) {
    //             setSelectedValue(currentMapping.data_field_id);
    //             getDataFieldTable(currentMapping.data_field_id)
    //         }
    //     }
    // }, []);

    //Get data for data field table
    const getDataFieldTable = async (id) => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                dataFieldId: parseInt(id)
            }
            let response = await getDataFieldById(body);
            if (response && response.success && response?.data) {
                if (response?.data?.user_data_fields.length > 0) {
                    const mainArray = mergeArrays(dataFileds, response?.data?.user_data_fields);
                    setDataFieldTable(mainArray);
                    setIsLoading(false);
                } else {
                    setDataFieldTable(dataFileds);
                    setIsLoading(false);
                }
            } else {
                setDataFieldTable([]);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while getting data. Please try again.")
        }
    }

    const mergeArrays = (staticArray, apiArray) => {
        const updatedArray = staticArray.map(item => {
            const apiItem = apiArray.find(apiItem => apiItem.trgSystem === item.trgSystem);
            if (apiItem) {
                return { ...item, clientTerm: apiItem.clientTerm };
            }
            return item;
        });
        return updatedArray;
    };

    const formatDate = (dateString) => {
        if (dateString !== null) {
            const date = new Date(dateString);
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        } else {
            return null
        }

    };

    const toggleProfileLogoModal = () => {
        setEditProfileLogoModal(!editProfileLogoModal);

    }

    const handleImageChange = (e, setFieldValue) => {
        const file = e.target.files[0];
        if (file) {
            setFieldValue("profilePicture", file);

            const reader: any = new FileReader();
            reader.onloadend = () => {
                // setSelectedFile(file)
                setImagePreview(reader.result);
                setFileName(file?.name);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleProfileLogo = (profileData) => {
        setImagePreview(null);
        setFileName(null);
        imageValidation.resetForm();
        setEditProfileLogoModal(true);
        if (profileData?.profile_picture) {
            setImagePreview(`${Setting?.API_URL}uploads/clients/${profileData?.profile_picture}`);
            setFileName(profileData?.profile_picture);
        }
    }

    const presentDateFormat = (item) => {
        // if (item.present === true && item.end_date !== null) {
        //     return `${item.data_field_name} Current Mapping (${formatDate(item.start_date)} - Present) `;
        // } else {
        //     if (item.present === false && item.end_date !== null) {
        //         return `${item.data_field_name} (${formatDate(item.start_date)} - ${formatDate(item.end_date)})`;
        //     } else {
        //         return `${item.data_field_name} (${formatDate(item.start_date)})`;
        //     }
        // }

        {/* {item.data_field_name} { item.present === true ? `Current Mapping (${formatDate(item.start_date)}) - Present` : `(${formatDate(item.start_date)} - ${formatDate(item.end_date)})`} */ }
    }
    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };

    const handleAddPatientType = () => {
        setAddPatientTypeModal(true)
    }

    useEffect(() => {
        if (activeTab === 'tab1') {
            fetchPatientType();
            console.log("ZBA Transactions tab selected");
        }
        if (activeTab === 'tab2') {
            fetchGetDataFields();
            // Logic for 'tab1', e.g., fetching data for ZBA Transactions
            console.log("ZBA Transactions tab selected");
        }
        if (activeTab === 'tab3') {
            fetchZab();
            // Logic for 'tab1', e.g., fetching data for ZBA Transactions
            console.log("ZBA Transactions tab selected");
        }
    }, [activeTab]);

    return (
        <React.Fragment >
            <div className="page-content">
                <Container fluid={true}>
                    <div className="d-flex align-items-center">
                        <h4 className="mb-sm-0 font-size-18">Profile</h4>
                        {/* <div className="client_data_txt">Client Added Date</div>
                        <div className="client_data_txt">Last imported date</div> */}
                    </div>
                    {/* ================================================ */}
                    <div>
                        <div className="row  mt-4 new_design_box">
                            <div className="col-md-1 client_data">
                                <div className="prof_logo">
                                    <Avatar name={profileData?.company_name}
                                        src={`${Setting?.API_URL}uploads/clients/${profileData?.profile_picture}`} size="100" round={true} />
                                    <a onClick={() => handleProfileLogo(profileData)} className="text-center edit_icon"></a>
                                </div>
                                {/* <a href="#" className="text-center">Edit Logo</a> */}
                            </div>
                            <div className="col-md-3 client_data ">
                                <div className="box_white_shedow">
                                    <div className="special_flex">
                                        <h3>Company Name</h3>
                                        <a onClick={() => handleEditProfile(profileData)} className="edit_icn"></a>
                                    </div>
                                    <div>{profileData?.company_name}</div>
                                    <div>{profileData?.email}</div>
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-3 client_data">
                                <div className="box_white_shedow">
                                    <div className="special_flex">
                                        <h3>Address</h3>
                                        <a onClick={() => handleEditAddress(profileData)} className="edit_icn"></a>
                                    </div>
                                    <div>{profileData?.address_line_1}<br /> {profileData?.address_line_2}</div>
                                    <div>{profileData?.city}, {profileData?.state}</div>
                                    <div>{profileData?.pincode}<br /></div>
                                </div>
                            </div>
                            <div className="col-md-3 client_data ">
                                <div className="box_white_shedow">
                                    <div className="special_flex">
                                        <h3>Primary Contact</h3>
                                        <a onClick={() => handleEditContact(profileData)} className="edit_icn"></a>
                                    </div>
                                    <div>{profileData?.primary_contact_first_name} {profileData?.primary_contact_last_name}</div>
                                    <div>{profileData?.primary_contact_company_name}</div>
                                    <div>{profileData?.primary_contact_email}</div>
                                    <div>{profileData?.primary_contact_phone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ================================================ */}

                    <div className="row mt-4">
                        <div className="col-12 col-md-4 tab-header">
                            <button
                                type="button"
                                className={activeTab === 'tab1' ? 'active ms-0' : 'ms-0'}
                                onClick={() => handleTabClick('tab1')}
                            >
                                Patient Type
                            </button>
                            <button
                                type="button"
                                className={activeTab === 'tab2' ? 'active' : ''}
                                onClick={() => handleTabClick('tab2')}
                            >
                                AR Data Fields
                            </button>
                            <button
                                type="button"
                                className={activeTab === 'tab3' ? 'active' : ''}
                                onClick={() => handleTabClick('tab3')}
                            >
                                ZBA Thresholds
                            </button>
                        </div>
                        <div className="tab-content">
                            {activeTab === 'tab1' && (
                                <TabContent tabId="tab1" activeTab={activeTab}>
                                    <div className="col-md-8">
                                        <div className="d-flex align-items-end mb-4">
                                            <button className="btn btn-primary waves-effect waves-light btn btn-primary" onClick={handleAddPatientType}>Add</button>
                                        </div>
                                        <div className="table-responsive data_table_box data_fileld">
                                            <div className="pt-table">
                                                <table style={{ marginTop: "8px" }} className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover">
                                                    <thead className="table-light">
                                                        {patientTypeData && patientTypeData?.length > 0 &&
                                                            <tr>
                                                                <th>Patient Type</th>
                                                                <th>Description</th>
                                                                <th>Abbreviation</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        {patientTypeData && patientTypeData?.length > 0 && patientTypeData.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{row.patient_type}</td>
                                                                <td>{row.description}</td>
                                                                <td>{row.abbreviation}</td>
                                                                <td>
                                                                    <>
                                                                        <Button color="link" size="sm">
                                                                            <img src={editIcon} alt="" height="18" className="rounded-pill d-block mx-auto" onClick={() => handlEdit(row)} />
                                                                        </Button>
                                                                        <Button color="link" size="sm">
                                                                            <img src={deleteIcon} alt="" height="22" className="rounded-pill d-block mx-auto" onClick={() => handleDelete(row)} />
                                                                        </Button>
                                                                        {/* <Button color="link" size="sm">
                                                                        <img src={viewIcon} alt="" height="22" className="rounded-pill d-block mx-auto" onClick={() => handleview(row)} />
                                                                    </Button> */}
                                                                    </>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* )} */}

                                        </div>
                                    </div>
                                </TabContent>
                            )}
                            {activeTab === 'tab2' && (
                                <TabContent tabId="tab2" activeTab={activeTab}>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center mb-3 Data_Fields_data">
                                            <h3 className="font-size-20 mb-sm-0">AR Data Fields</h3>
                                        </div>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="right_select me-3">
                                                <select className="form-select" value={selectedValue} onChange={handleDataFieldChange}>
                                                    <option value="">Select an option</option>
                                                    {dataFields.map((item) => (
                                                        <option key={item.data_field_id} value={item.data_field_id}>
                                                            {/* {item.data_field_name} { item.end_date !== null ? `Current Mapping (${formatDate(item.start_date)}) - Present` : `(${formatDate(item.start_date)} - ${formatDate(item.end_date)})`} */}
                                                            {item.data_field_name} {item.end_date === null ? `Current Mapping (${formatDate(item.start_date)} - Present) ` : `(${formatDate(item.start_date)} - ${formatDate(item.end_date)})`}
                                                            {/* {presentDateFormat(item)} */}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <button className="btn btn-primary waves-effect waves-light btn btn-primary me-3" onClick={handleAddDataField}>Edit</button> */}
                                            <button className="btn btn-outline-primary me-3" onClick={handleMapping} >Add</button>
                                        </div>
                                        <div className="table-responsive data_table_box data_fileld">
                                            {dataFieldTable && dataFieldTable.length > 0 && (
                                                <div className="trg-table">
                                                    <table style={{ marginTop: "8px" }} className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>TRG System</th>
                                                                <th>Client Term</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {dataFieldTable.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td>{row.trgSystem}</td>
                                                                    <td>
                                                                        {editingIndex === index ? (
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={editValue}
                                                                                onChange={(e) => setEditValue(e.target.value)}
                                                                            />
                                                                        ) : (
                                                                            row.clientTerm
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {editingIndex === index ? (
                                                                            <>
                                                                                <Button color="link" className="text-blue" size="sm" onClick={() => handleSaveClick(index)}>
                                                                                    Save
                                                                                </Button>
                                                                                <Button color="link" className="text-danger" size="sm" onClick={handleCancelClick}>
                                                                                    Cancel
                                                                                </Button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Button color="link" size="sm" onClick={() => handleEditClick(index, row.clientTerm, row.trgSystem)}>
                                                                                    <img src={editIcon} alt="" height="18" className="rounded-pill d-block mx-auto" />
                                                                                </Button>
                                                                                {/* <Button color="link" size="sm">
                                                                        <img src={deleteIcon} alt="" height="20" className="rounded-pill d-block mx-auto" />
                                                                    </Button> */}
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </TabContent>
                            )}
                            {activeTab === 'tab3' && (
                                <TabContent tabId="tab3" activeTab={activeTab}>
                                    <div className="col-md-8">
                                        <div className="d-flex align-items-end mb-4">
                                            <button className="btn btn-primary waves-effect waves-light btn btn-primary" onClick={handleZbtAdd}>Add</button>
                                        </div>
                                        <div className="table-responsive data_table_box data_fileld">
                                            <div className="pt-table">
                                                <table style={{ marginTop: "8px" }} className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover">
                                                    <thead className="table-light">
                                                        {zbaData && zbaData?.length > 0 &&
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Lower Limit</th>
                                                                <th>Upper Limit</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        }
                                                    </thead>
                                                    <tbody>
                                                        {zbaData && zbaData.length > 0 && zbaData.map((row, index) => {
                                                            const isPastEndDate = new Date(row.end_date) < new Date();
                                                            console.log("isPastEndDate", isPastEndDate)
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{row.name}</td>
                                                                    <td>{row.lower_limit}</td>
                                                                    <td>{row.upper_limit}</td>
                                                                    <td>{formatDate(row.start_date)}</td>
                                                                    <td>{formatDate(row.end_date)}</td>
                                                                    <td>
                                                                        <>
                                                                            <Button color="link" size="sm">
                                                                                <img src={editIcon} alt="" height="18" className="rounded-pill d-block mx-auto" onClick={() => handleZbtEdit(row)} />
                                                                            </Button>
                                                                            {zbaData.length > 1 && (
                                                                                <Button color="link" size="sm" disabled={isPastEndDate}>
                                                                                    <img src={deleteIcon} alt="" height="22" className="rounded-pill d-block mx-auto" onClick={() => handleZbaDelete(row)} />
                                                                                </Button>
                                                                            )}

                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* )} */}

                                        </div>
                                    </div>
                                </TabContent>
                            )}
                        </div>
                    </div>
                </Container>
            </div>

            {/* add provider  OLD*/}
            <Modal
                isOpen={addProviderModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setAddProviderModal(!addProviderModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setAddProviderModal(!addProviderModal) }} >Add Provider</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            addProviderValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="providerName">Provider Name</Label>
                                        <Input
                                            name="providerName"
                                            placeholder="Provider Name"
                                            type="text"
                                            className="form-control"
                                            id="providerName"
                                            onChange={addProviderValidation.handleChange}
                                            onBlur={addProviderValidation.handleBlur}
                                            value={addProviderValidation.values.providerName || ""}
                                            invalid={
                                                addProviderValidation.touched.providerName && addProviderValidation.errors.providerName ? true : false
                                            }
                                        />
                                        {addProviderValidation.touched.providerName && addProviderValidation.errors.providerName ? (
                                            <FormFeedback type="invalid">{addProviderValidation.errors.providerName}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="providerNo">Provider No</Label>
                                        <Input
                                            name="providerNo"
                                            placeholder="Provider No"
                                            type="text"
                                            className="form-control"
                                            id="providerNo"
                                            onChange={addProviderValidation.handleChange}
                                            onBlur={addProviderValidation.handleBlur}
                                            value={addProviderValidation.values.providerNo || ""}
                                            invalid={
                                                addProviderValidation.touched.providerNo && addProviderValidation.errors.providerNo ? true : false
                                            }
                                        />
                                        {addProviderValidation.touched.providerNo && addProviderValidation.errors.providerNo ? (
                                            <FormFeedback type="invalid">{addProviderValidation.errors.providerNo}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Save</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* edit provider OLD*/}
            <Modal
                isOpen={editProviderModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setEditProviderModal(!editProviderModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setEditProviderModal(!editProviderModal) }} >Edit Provider</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            editProviderValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="providerName">Provider Name</Label>
                                        <Input
                                            name="providerName"
                                            placeholder="Provider Name"
                                            type="text"
                                            className="form-control"
                                            id="providerName"
                                            onChange={editProviderValidation.handleChange}
                                            onBlur={editProviderValidation.handleBlur}
                                            value={editProviderValidation.values.providerName || ""}
                                            invalid={
                                                editProviderValidation.touched.providerName && editProviderValidation.errors.providerName ? true : false
                                            }
                                        />
                                        {editProviderValidation.touched.providerName && editProviderValidation.errors.providerName ? (
                                            <FormFeedback type="invalid">{editProviderValidation.errors.providerName}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="providerNo">Provider No</Label>
                                        <Input
                                            name="providerNo"
                                            placeholder="Provider No"
                                            type="text"
                                            className="form-control"
                                            id="providerNo"
                                            onChange={editProviderValidation.handleChange}
                                            onBlur={editProviderValidation.handleBlur}
                                            value={editProviderValidation.values.providerNo || ""}
                                            invalid={
                                                editProviderValidation.touched.providerNo && editProviderValidation.errors.providerNo ? true : false
                                            }
                                        />
                                        {editProviderValidation.touched.providerNo && editProviderValidation.errors.providerNo ? (
                                            <FormFeedback type="invalid">{editProviderValidation.errors.providerNo}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* Add Patient Type Design Start*/}
            <Modal
                isOpen={addPatientTypeModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setAddPatientTypeModal(!addPatientTypeModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setAddPatientTypeModal(!addPatientTypeModal) }} >Add Patient Type</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            addPatientTypeValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="code">Code</Label>
                                        <Input
                                            name="code"
                                            placeholder="Code"
                                            type="text"
                                            className="form-control"
                                            id="code"
                                            onChange={addPatientTypeValidation.handleChange}
                                            onBlur={addPatientTypeValidation.handleBlur}
                                            value={addPatientTypeValidation.values.code || ""}
                                            invalid={
                                                addPatientTypeValidation.touched.code && addPatientTypeValidation.errors.code ? true : false
                                            }
                                        />
                                        {addPatientTypeValidation.touched.code && addPatientTypeValidation.errors.code ? (
                                            <FormFeedback type="invalid">{addPatientTypeValidation.errors.code}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="description">Description</Label>
                                        <Input
                                            name="description"
                                            placeholder="Description"
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            onChange={addPatientTypeValidation.handleChange}
                                            onBlur={addPatientTypeValidation.handleBlur}
                                            value={addPatientTypeValidation.values.description || ""}
                                            invalid={
                                                addPatientTypeValidation.touched.description && addPatientTypeValidation.errors.description ? true : false
                                            }
                                        />
                                        {addPatientTypeValidation.touched.description && addPatientTypeValidation.errors.description ? (
                                            <FormFeedback type="invalid">{addPatientTypeValidation.errors.description}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="abbreviation">Abbreviation</Label>
                                        <Input
                                            name="abbreviation"
                                            placeholder="Abbreviation"
                                            type="text"
                                            className="form-control"
                                            id="abbreviation"
                                            onChange={addPatientTypeValidation.handleChange}
                                            onBlur={addPatientTypeValidation.handleBlur}
                                            value={addPatientTypeValidation.values.abbreviation || ""}
                                            invalid={
                                                addPatientTypeValidation.touched.abbreviation && addPatientTypeValidation.errors.abbreviation ? true : false
                                            }
                                        />
                                        {addPatientTypeValidation.touched.abbreviation && addPatientTypeValidation.errors.abbreviation ? (
                                            <FormFeedback type="invalid">{addPatientTypeValidation.errors.abbreviation}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Save</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* view Patient Type */}
            {/* <Modal
                isOpen={viewPatientTypeModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setViewPatientTypeModal(!viewPatientTypeModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setViewPatientTypeModal(!viewPatientTypeModal) }} >View Patient Type</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            viewPatientTypeValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="code">Code</Label>
                                        <Input
                                            name="code"
                                            placeholder="Code"
                                            type="text"
                                            className="form-control"
                                            id="code"
                                            disabled={true}
                                            onChange={viewPatientTypeValidation.handleChange}
                                            onBlur={viewPatientTypeValidation.handleBlur}
                                            value={viewPatientTypeValidation.values.code || ""}
                                            invalid={
                                                viewPatientTypeValidation.touched.code && viewPatientTypeValidation.errors.code ? true : false
                                            }
                                        />
                                        {viewPatientTypeValidation.touched.code && viewPatientTypeValidation.errors.code ? (
                                            <FormFeedback type="invalid">{viewPatientTypeValidation.errors.code}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="description">Description</Label>
                                        <Input
                                            name="description"
                                            placeholder="Description"
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            disabled={true}
                                            onChange={viewPatientTypeValidation.handleChange}
                                            onBlur={viewPatientTypeValidation.handleBlur}
                                            value={viewPatientTypeValidation.values.description || ""}
                                            invalid={
                                                viewPatientTypeValidation.touched.description && viewPatientTypeValidation.errors.description ? true : false
                                            }
                                        />
                                        {viewPatientTypeValidation.touched.description && viewPatientTypeValidation.errors.description ? (
                                            <FormFeedback type="invalid">{viewPatientTypeValidation.errors.description}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="abbreviation">Abbreviation</Label>
                                        <Input
                                            name="abbreviation"
                                            placeholder="Abbreviation"
                                            type="text"
                                            className="form-control"
                                            id="abbreviation"
                                            disabled={true}
                                            onChange={viewPatientTypeValidation.handleChange}
                                            onBlur={viewPatientTypeValidation.handleBlur}
                                            value={viewPatientTypeValidation.values.abbreviation || ""}
                                            invalid={
                                                viewPatientTypeValidation.touched.abbreviation && viewPatientTypeValidation.errors.abbreviation ? true : false
                                            }
                                        />
                                        {viewPatientTypeValidation.touched.abbreviation && viewPatientTypeValidation.errors.abbreviation ? (
                                            <FormFeedback type="invalid">{viewPatientTypeValidation.errors.abbreviation}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </ModalBody>
            </Modal > */}

            {/* edit Patient Type */}
            <Modal
                isOpen={editPatientTypeModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setEditPatientTypeModal(!editPatientTypeModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setEditPatientTypeModal(!editPatientTypeModal) }} >View Patient Type</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            editPatientTypeValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="code">Code</Label>
                                        <Input
                                            name="code"
                                            placeholder="Code"
                                            type="text"
                                            className="form-control"
                                            id="code"
                                            onChange={editPatientTypeValidation.handleChange}
                                            onBlur={editPatientTypeValidation.handleBlur}
                                            value={editPatientTypeValidation.values.code || ""}
                                            invalid={
                                                editPatientTypeValidation.touched.code && editPatientTypeValidation.errors.code ? true : false
                                            }
                                        />
                                        {editPatientTypeValidation.touched.code && editPatientTypeValidation.errors.code ? (
                                            <FormFeedback type="invalid">{editPatientTypeValidation.errors.code}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="description">Description</Label>
                                        <Input
                                            name="description"
                                            placeholder="Description"
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            onChange={editPatientTypeValidation.handleChange}
                                            onBlur={editPatientTypeValidation.handleBlur}
                                            value={editPatientTypeValidation.values.description || ""}
                                            invalid={
                                                editPatientTypeValidation.touched.description && editPatientTypeValidation.errors.description ? true : false
                                            }
                                        />
                                        {editPatientTypeValidation.touched.description && editPatientTypeValidation.errors.description ? (
                                            <FormFeedback type="invalid">{editPatientTypeValidation.errors.description}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="abbreviation">Abbreviation</Label>
                                        <Input
                                            name="abbreviation"
                                            placeholder="Abbreviation"
                                            type="text"
                                            className="form-control"
                                            id="abbreviation"
                                            onChange={editPatientTypeValidation.handleChange}
                                            onBlur={editPatientTypeValidation.handleBlur}
                                            value={editPatientTypeValidation.values.abbreviation || ""}
                                            invalid={
                                                editPatientTypeValidation.touched.abbreviation && editPatientTypeValidation.errors.abbreviation ? true : false
                                            }
                                        />
                                        {editPatientTypeValidation.touched.abbreviation && editPatientTypeValidation.errors.abbreviation ? (
                                            <FormFeedback type="invalid">{editPatientTypeValidation.errors.abbreviation}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Save</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* delete Patient Type */}
            <Modal
                isOpen={deletePatientTypeModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setDeletePatientTypeModal(!deletePatientTypeModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setDeletePatientTypeModal(!deletePatientTypeModal) }} >Delete Patient Type</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            deletePatientTypeValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <p>Are you sure you want to delete this item ?</p>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Delete</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* Add Provider New Design End*/}

            {/* edit address */}
            <Modal
                isOpen={editAddressModal}
                autoFocus={true}
                size="lg"
                centered
                toggle={() => { setEditAddressModal(!editAddressModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setEditAddressModal(!editAddressModal) }} >Edit Address</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            editAddressValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="addressLine1">Address Line 1</Label>
                                        <Input
                                            name="addressLine1"
                                            placeholder="Address Line 1"
                                            type="text"
                                            className="form-control"
                                            id="addressLine1"
                                            onChange={editAddressValidation.handleChange}
                                            onBlur={editAddressValidation.handleBlur}
                                            value={editAddressValidation.values.addressLine1 || ""}
                                            invalid={
                                                editAddressValidation.touched.addressLine1 && editAddressValidation.errors.addressLine1 ? true : false
                                            }
                                        />
                                        {editAddressValidation.touched.addressLine1 && editAddressValidation.errors.addressLine1 ? (
                                            <FormFeedback type="invalid">{editAddressValidation.errors.addressLine1}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="addressLine2">Address Line 2</Label>
                                        <Input
                                            name="addressLine2"
                                            placeholder="Address Line 2"
                                            type="text"
                                            className="form-control"
                                            id="addressLine2"
                                            onChange={editAddressValidation.handleChange}
                                            onBlur={editAddressValidation.handleBlur}
                                            value={editAddressValidation.values.addressLine2 || ""}
                                            invalid={
                                                editAddressValidation.touched.addressLine2 && editAddressValidation.errors.addressLine2 ? true : false
                                            }
                                        />
                                        {editAddressValidation.touched.addressLine2 && editAddressValidation.errors.addressLine2 ? (
                                            <FormFeedback type="invalid">{editAddressValidation.errors.addressLine2}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="state">State</Label>
                                        <Input
                                            name="state"
                                            placeholder="State"
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            onChange={editAddressValidation.handleChange}
                                            onBlur={editAddressValidation.handleBlur}
                                            value={editAddressValidation.values.state || ""}
                                            invalid={
                                                editAddressValidation.touched.state && editAddressValidation.errors.state ? true : false
                                            }
                                        />
                                        {editAddressValidation.touched.state && editAddressValidation.errors.state ? (
                                            <FormFeedback type="invalid">{editAddressValidation.errors.state}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="city">City</Label>
                                        <Input
                                            name="city"
                                            placeholder="City"
                                            type="text"
                                            id="city"
                                            className="form-control"
                                            onChange={editAddressValidation.handleChange}
                                            onBlur={editAddressValidation.handleBlur}
                                            value={editAddressValidation.values.city || ""}
                                            invalid={
                                                editAddressValidation.touched.city && editAddressValidation.errors.city ? true : false
                                            }
                                        />
                                        {editAddressValidation.touched.city && editAddressValidation.errors.city ? (
                                            <FormFeedback type="invalid">{editAddressValidation.errors.city}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="pincode">Pincode</Label>
                                        <Input
                                            name="pincode"
                                            placeholder="Pincode"
                                            type="text"
                                            className="form-control"
                                            id="pincode"
                                            onChange={editAddressValidation.handleChange}
                                            onBlur={editAddressValidation.handleBlur}
                                            value={editAddressValidation.values.pincode || ""}
                                            invalid={
                                                editAddressValidation.touched.pincode && editAddressValidation.errors.pincode ? true : false
                                            }
                                        />
                                        {editAddressValidation.touched.pincode && editAddressValidation.errors.pincode ? (
                                            <FormFeedback type="invalid">{editAddressValidation.errors.pincode}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* edit address */}
            <Modal
                isOpen={editContactModal}
                autoFocus={true}
                size="lg"
                centered
                toggle={() => { setEditContactModal(!editContactModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setEditContactModal(!editContactModal) }} >Edit Primary Contact</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            editContactValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="primaryFirstName">First Name</Label>
                                        <Input
                                            name="primaryFirstName"
                                            placeholder="First Name"
                                            type="text"
                                            className="form-control"
                                            id="primaryFirstName"
                                            onChange={editContactValidation.handleChange}
                                            onBlur={editContactValidation.handleBlur}
                                            value={editContactValidation.values.primaryFirstName || ""}
                                            invalid={
                                                editContactValidation.touched.primaryFirstName && editContactValidation.errors.primaryFirstName ? true : false
                                            }
                                        />
                                        {editContactValidation.touched.primaryFirstName && editContactValidation.errors.primaryFirstName ? (
                                            <FormFeedback type="invalid">{editContactValidation.errors.primaryFirstName}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="primaryLastName">Last Name</Label>
                                        <Input
                                            name="primaryLastName"
                                            placeholder="Last Name"
                                            type="text"
                                            className="form-control"
                                            id="primaryLastName"
                                            onChange={editContactValidation.handleChange}
                                            onBlur={editContactValidation.handleBlur}
                                            value={editContactValidation.values.primaryLastName || ""}
                                            invalid={
                                                editContactValidation.touched.primaryLastName && editContactValidation.errors.primaryLastName ? true : false
                                            }
                                        />
                                        {editContactValidation.touched.primaryLastName && editContactValidation.errors.primaryLastName ? (
                                            <FormFeedback type="invalid">{editContactValidation.errors.primaryLastName}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="primaryCompanyName">Company Name</Label>
                                        <Input
                                            name="primaryCompanyName"
                                            placeholder="Company Name"
                                            type="text"
                                            className="form-control"
                                            id="primaryCompanyName"
                                            onChange={editContactValidation.handleChange}
                                            onBlur={editContactValidation.handleBlur}
                                            value={editContactValidation.values.primaryCompanyName || ""}
                                            invalid={
                                                editContactValidation.touched.primaryCompanyName && editContactValidation.errors.primaryCompanyName ? true : false
                                            }
                                        />
                                        {editContactValidation.touched.primaryCompanyName && editContactValidation.errors.primaryCompanyName ? (
                                            <FormFeedback type="invalid">{editContactValidation.errors.primaryCompanyName}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="primaryEmail">Email</Label>
                                        <Input
                                            name="primaryEmail"
                                            placeholder="Email"
                                            type="text"
                                            className="form-control"
                                            id="primaryEmail"
                                            onChange={editContactValidation.handleChange}
                                            onBlur={editContactValidation.handleBlur}
                                            value={editContactValidation.values.primaryEmail || ""}
                                            invalid={
                                                editContactValidation.touched.primaryEmail && editContactValidation.errors.primaryEmail ? true : false
                                            }
                                        />
                                        {editContactValidation.touched.primaryEmail && editContactValidation.errors.primaryEmail ? (
                                            <FormFeedback type="invalid">{editContactValidation.errors.primaryEmail}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="primaryPhone">Phone Number</Label>
                                        <Input
                                            name="primaryPhone"
                                            placeholder="Phone Number"
                                            type="text"
                                            className="form-control"
                                            id="primaryPhone"
                                            onChange={editContactValidation.handleChange}
                                            onBlur={editContactValidation.handleBlur}
                                            value={editContactValidation.values.primaryPhone || ""}
                                            invalid={
                                                editContactValidation.touched.primaryPhone && editContactValidation.errors.primaryPhone ? true : false
                                            }
                                        />
                                        {editContactValidation.touched.primaryPhone && editContactValidation.errors.primaryPhone ? (
                                            <FormFeedback type="invalid">{editContactValidation.errors.primaryPhone}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* edit Profile */}
            <Modal
                isOpen={editProfileModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setEditProfileModal(!editProfileModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setEditProfileModal(!editProfileModal) }} >Edit Company Name</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            editProfileValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="companyName">Company Name</Label>
                                        <Input
                                            name="companyName"
                                            placeholder="Company Name"
                                            type="text"
                                            className="form-control"
                                            id="companyName"
                                            onChange={editProfileValidation.handleChange}
                                            onBlur={editProfileValidation.handleBlur}
                                            value={editProfileValidation.values.companyName || ""}
                                            invalid={
                                                editProfileValidation.touched.companyName && editProfileValidation.errors.companyName ? true : false
                                            }
                                        />
                                        {editProfileValidation.touched.companyName && editProfileValidation.errors.companyName ? (
                                            <FormFeedback type="invalid">{editProfileValidation.errors.companyName}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="email">Email</Label>
                                        <Input
                                            name="email"
                                            placeholder="Email"
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            onChange={editProfileValidation.handleChange}
                                            onBlur={editProfileValidation.handleBlur}
                                            value={editProfileValidation.values.email || ""}
                                            invalid={
                                                editProfileValidation.touched.email && editProfileValidation.errors.email ? true : false
                                            }
                                        />
                                        {editProfileValidation.touched.email && editProfileValidation.errors.email ? (
                                            <FormFeedback type="invalid">{editProfileValidation.errors.email}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* add mapping */}
            <Modal
                isOpen={editMappingModal}
                autoFocus={true}
                size="lg"
                centered
                toggle={toggleMappingModal}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={toggleMappingModal}>Mapping</ModalHeader>
                <ModalBody>
                    <Form
                        className="needs-addProviderValidation"
                    >
                        <div className="providerTable">
                            <Row>
                                <div className="table-responsive data_table_box data_fileld">
                                    <div>
                                        <table className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Mapping</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    {isAdding && (<th>Actions</th>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((row) => (
                                                    <tr key={row.data_field_id}>
                                                        <td>{row.data_field_name}</td>
                                                        <td>{formatDate(row.start_date)}</td>
                                                        {/* <td>{row.present === true ? "present" : row.end_date}</td> */}
                                                        <td>{row.end_date === null ? "present" : formatDate(row.end_date)}</td>
                                                        {isAdding && (<td></td>)}
                                                    </tr>
                                                ))}
                                                {isAdding && (
                                                    <tr>
                                                        <td>New Mapping</td>
                                                        <td>
                                                            <input
                                                                name="startDate"
                                                                placeholder="Effective From"
                                                                type="date"
                                                                className="form-control"
                                                                id="startDate"
                                                                min={currentDate}
                                                                onChange={(e) => handleInputChange('start_date', e.target.value)}
                                                                value={newRow.start_date}
                                                            />
                                                        </td>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <Button color="link" className="text-blue" size="sm" onClick={saveNewRow}>
                                                                Save
                                                            </Button>
                                                            <Button color="red" className="text-danger" size="sm" onClick={cancelNewRow}>
                                                                Cancel
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Form>
                    {!isAdding && (
                        <div>
                            <Button color="primary" className="list-inline-item mr-3" onClick={addNewRow}>Add New</Button>
                        </div>
                    )}
                </ModalBody>
            </Modal>

            <Modal
                isOpen={editProfileLogoModal}
                autoFocus={true}
                size="md"
                centered
                toggle={toggleProfileLogoModal}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={toggleProfileLogoModal}>Profile Logo</ModalHeader>
                <ModalBody>
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            imageValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">

                                    <FormGroup className="my-2 text-center">
                                        {
                                            imagePreview && <span className="profile-img-box">
                                                <img
                                                    className="image-preview"
                                                    alt="Preview"
                                                    src={imagePreview}
                                                />
                                                <input
                                                    name="profilePicture"
                                                    type="file"
                                                    className="form-control"
                                                    id="profilePicture"
                                                    accept="image/*"
                                                    // value={imagePreview?imagePreview:''}
                                                    onChange={(event) => handleImageChange(event, imageValidation.setFieldValue)}
                                                />
                                                {imageValidation.errors && imageValidation.errors.profilePicture ? (
                                                    <FormFeedback type="invalid">{imageValidation.errors.profilePicture}</FormFeedback>
                                                ) : null}
                                            </span>
                                        }
                                    </FormGroup>
                                    {fileName && <div className="text-center mb-5"> {fileName}</div>}
                                </Col>

                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item">Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            {/* Add ZBA  */}
            <Modal
                isOpen={addZbaModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setAddZbaModal(!addZbaModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setAddZbaModal(!addZbaModal) }} >Add ATB Threshold</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            addZbaValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="name">Name</Label>
                                        <Input
                                            name="name"
                                            placeholder="Name"
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            onChange={addZbaValidation.handleChange}
                                            onBlur={addZbaValidation.handleBlur}
                                            value={addZbaValidation.values.name || ""}
                                            invalid={
                                                addZbaValidation.touched.name && addZbaValidation.errors.name ? true : false
                                            }
                                        />
                                        {addZbaValidation.touched.name && addZbaValidation.errors.name ? (
                                            <FormFeedback type="invalid">{addZbaValidation.errors.name}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="start_date">StartDate</Label>
                                        <Input
                                            name="start_date"
                                            placeholder="StartDate"
                                            type="date"
                                            className="form-control"
                                            id="start_date"
                                            onChange={addZbaValidation.handleChange}
                                            onBlur={addZbaValidation.handleBlur}
                                            value={addZbaValidation.values.start_date || ""}
                                            invalid={
                                                addZbaValidation.touched.start_date && addZbaValidation.errors.start_date ? true : false
                                            }
                                        />
                                        {addZbaValidation.touched.start_date && addZbaValidation.errors.start_date ? (
                                            <FormFeedback type="invalid">{addZbaValidation.errors.start_date}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="end_date">End End</Label>
                                        <Input
                                            name="end_date"
                                            placeholder="End End"
                                            type="date"
                                            className="form-control"
                                            id="end_date"
                                            onChange={addZbaValidation.handleChange}
                                            onBlur={addZbaValidation.handleBlur}
                                            value={addZbaValidation.values.end_date || ""}
                                            invalid={
                                                addZbaValidation.touched.end_date && addZbaValidation.errors.end_date ? true : false
                                            }
                                        />
                                        {addZbaValidation.touched.end_date && addZbaValidation.errors.end_date ? (
                                            <FormFeedback type="invalid">{addZbaValidation.errors.end_date}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="lower_limit">Lower Limit</Label>
                                        <Input
                                            name="lower_limit"
                                            placeholder="Lower Limit"
                                            type="text"
                                            className="form-control"
                                            id="lower_limit"
                                            onChange={addZbaValidation.handleChange}
                                            onBlur={addZbaValidation.handleBlur}
                                            value={addZbaValidation.values.lower_limit || ""}
                                            invalid={
                                                addZbaValidation.touched.lower_limit && addZbaValidation.errors.lower_limit ? true : false
                                            }
                                        />
                                        {addZbaValidation.touched.lower_limit && addZbaValidation.errors.lower_limit ? (
                                            <FormFeedback type="invalid">{addZbaValidation.errors.lower_limit}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="upper_limit">Upper Limit</Label>
                                        <Input
                                            name="upper_limit"
                                            placeholder="Upper Limit"
                                            type="text"
                                            className="form-control"
                                            id="upper_limit"
                                            onChange={addZbaValidation.handleChange}
                                            onBlur={addZbaValidation.handleBlur}
                                            value={addZbaValidation.values.upper_limit || ""}
                                            invalid={
                                                addZbaValidation.touched.upper_limit && addZbaValidation.errors.upper_limit ? true : false
                                            }
                                        />
                                        {addZbaValidation.touched.upper_limit && addZbaValidation.errors.upper_limit ? (
                                            <FormFeedback type="invalid">{addZbaValidation.errors.upper_limit}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Save</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* Edit ZBA  */}
            <Modal
                isOpen={editZbaModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setEditZbaModal(!editZbaModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setEditZbaModal(!editZbaModal) }} >Edit ATB Threshold</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            editZbaValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="name">Name</Label>
                                        <Input
                                            name="name"
                                            placeholder="Name"
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            onChange={editZbaValidation.handleChange}
                                            onBlur={editZbaValidation.handleBlur}
                                            value={editZbaValidation.values.name || ""}
                                            invalid={
                                                editZbaValidation.touched.name && editZbaValidation.errors.name ? true : false
                                            }
                                        />
                                        {editZbaValidation.touched.name && editZbaValidation.errors.name && typeof editZbaValidation.errors.name === 'string' ? (
                                            <FormFeedback type="invalid">{editZbaValidation.errors.name}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="start_date">StartDate</Label>
                                        <Input
                                            name="start_date"
                                            placeholder="StartDate"
                                            type="date"
                                            className="form-control"
                                            id="start_date"
                                            onChange={editZbaValidation.handleChange}
                                            onBlur={editZbaValidation.handleBlur}
                                            value={editZbaValidation.values.start_date || ""}
                                            invalid={
                                                editZbaValidation.touched.start_date && editZbaValidation.errors.start_date ? true : false
                                            }
                                        />
                                        {editZbaValidation.touched.start_date && editZbaValidation.errors.start_date && typeof editZbaValidation.errors.start_date === 'string' ? (
                                            <FormFeedback type="invalid">{editZbaValidation.errors.start_date}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="end_date">End End</Label>
                                        <Input
                                            name="end_date"
                                            placeholder="End End"
                                            type="date"
                                            className="form-control"
                                            id="end_date"
                                            onChange={editZbaValidation.handleChange}
                                            onBlur={editZbaValidation.handleBlur}
                                            value={editZbaValidation.values.end_date || ""}
                                            invalid={
                                                editZbaValidation.touched.end_date && editZbaValidation.errors.end_date ? true : false
                                            }
                                        />
                                        {editZbaValidation.touched.end_date && editZbaValidation.errors.end_date && typeof editZbaValidation.errors.end_date === 'string' ? (
                                            <FormFeedback type="invalid">{editZbaValidation.errors.end_date}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="lower_limit">Lower Limit</Label>
                                        <Input
                                            name="lower_limit"
                                            placeholder="Lower Limit"
                                            type="text"
                                            className="form-control"
                                            id="lower_limit"
                                            onChange={editZbaValidation.handleChange}
                                            onBlur={editZbaValidation.handleBlur}
                                            value={editZbaValidation.values.lower_limit || ""}
                                            invalid={
                                                editZbaValidation.touched.lower_limit && editZbaValidation.errors.lower_limit ? true : false
                                            }
                                        />
                                        {editZbaValidation.touched.lower_limit && editZbaValidation.errors.lower_limit && typeof editZbaValidation.errors.lower_limit === 'string' ? (
                                            <FormFeedback type="invalid">{editZbaValidation.errors.lower_limit}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="upper_limit">Upper Limit</Label>
                                        <Input
                                            name="upper_limit"
                                            placeholder="Upper Limit"
                                            type="text"
                                            className="form-control"
                                            id="upper_limit"
                                            onChange={editZbaValidation.handleChange}
                                            onBlur={editZbaValidation.handleBlur}
                                            value={editZbaValidation.values.upper_limit || ""}
                                            invalid={
                                                editZbaValidation.touched.upper_limit && editZbaValidation.errors.upper_limit ? true : false
                                            }
                                        />
                                        {editZbaValidation.touched.upper_limit && editZbaValidation.errors.upper_limit && typeof editZbaValidation.errors.upper_limit === 'string' ? (
                                            <FormFeedback type="invalid">{editZbaValidation.errors.upper_limit}</FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Update</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

            {/* Delete ZBA */}
            <Modal
                isOpen={deleteZbaModal}
                autoFocus={true}
                size="md"
                centered
                toggle={() => { setDeleteZbaModal(!deleteZbaModal) }}
                scrollable
            >
                <ModalHeader className="border-bottom-0" toggle={() => { setDeleteZbaModal(!deleteZbaModal) }} >Delete ZBA Threshold</ModalHeader>
                <ModalBody >
                    <Form
                        className="needs-addProviderValidation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            deleteZbaValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className="providerTable">
                            <Row>
                                <p>Are you sure you want to delete this item ?</p>
                            </Row>
                        </div>
                        <div className="modal-footer text-end">
                            <Button color="primary" type="submit" className="list-inline-item mr-3" >Delete</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal >

        </React.Fragment>
    );
}

export default Profile;