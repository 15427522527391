import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import classname from "classnames";
import withRouter from "../../Components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import { getLoggedInUser, getSelectedClientData } from "../../helpers/backend_helper";

const Navbar = (props: any) => {
  const [currentUser, SetCurrentUser] = useState(getLoggedInUser());
  const [clientData, setClientData]: any = useState(null);

  useEffect(() => {
    var clientData: any = getSelectedClientData();
    setClientData(clientData);

    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;

    var matchingMenuItem = null;
    var ul: any = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");

    let itemsArray = [...items];
    removeActivation(itemsArray);

    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname]);

  function activateParentDropdown(item: any) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const removeActivation = (items: any) => {
    let actiItems = items.filter((x: any) => x.classList.contains("active"));

    actiItems.forEach((item: any) => {
      const parent = item.parentElement

      if (item.classList.contains("dropdown-item")) {
        parent.classList.remove("active");
        const parent2 = parent.parentElement;
        parent2.classList.remove("active");

        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">

                {currentUser.user_type === 2 ? (
                  <>
                    <li>
                      <Link className="nav-link arrow-none" to="/dashboard">
                        Dashboard
                      </Link>
                    </li>
                  </>
                ) :
                  (
                    <>
                      {props.router.location.pathname !== '/clients' ?
                        <>
                          <li className="nav-item dropdown">
                            <Link
                              className={classname("nav-link dropdown-toggle arrow-none text-capitalize", {
                                "active": props.router.location.pathname === "/clients"
                              })}
                              to="/clients"
                            >
                              {clientData ? `${clientData?.company_name}` : "..."}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": props.router.location.pathname === "/ar"
                              })}
                              to="/ar"
                            >
                              AR
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": props.router.location.pathname === "/atb"
                              })}
                              to="/atb"
                            >
                              ATB
                            </Link>
                          </li>

                          <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": props.router.location.pathname === "/monthly"
                              })}
                              to="/monthly"
                            >
                              Monthly
                            </Link>
                          </li>

                          {/* {props.router.location.pathname === "/zba-monthly-summary" ? (
                            <li>
                              <Link
                                className={classname("nav-link arrow-none", {
                                  "active": true
                                })}
                                to="/zba-monthly-summary"
                              >
                                ZBA - Monthly Summary
                              </Link>
                            </li>
                          ) : props.router.location.pathname === "/zba-monthly-summary-details" ? (
                            <li>
                              <Link
                                className={classname("nav-link arrow-none", {
                                  "active": true
                                })}
                                to="/zba-monthly-summary-details"
                              >
                                ZBA - Monthly Summary
                              </Link>
                            </li>
                          ) : <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": true
                              })}
                              to="/zba-monthly-summary"
                            >
                              ZBA - Monthly Summary
                            </Link>
                          </li>} */}


                          {/* {props.router.location.pathname === "/ar-monthly-summary" ? (
                            <li>
                              <Link
                                className={classname("nav-link arrow-none", {
                                  "active": true
                                })}
                                to="/ar-monthly-summary"
                              >
                                AR - Monthly Summary
                              </Link>
                            </li>
                          ) : props.router.location.pathname === "/ar-monthly-summary-details" ? (
                            <li>
                              <Link
                                className={classname("nav-link arrow-none", {
                                  "active": true
                                })}
                                to="/ar-monthly-summary-details"
                              >
                                AR - Monthly Summary
                              </Link>
                            </li>
                          ) : <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": true
                              })}
                              to="/ar-monthly-summary"
                            >
                              AR - Monthly Summary
                            </Link>
                          </li>} */}

                          {/* <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": props.router.location.pathname === "/custom-report"
                              })}
                              to="/custom-report"
                            >
                              Custom Report
                            </Link>
                          </li> */}

                          <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": props.router.location.pathname === "/transactions"
                              })}
                              to="/transactions"
                            >
                              Transactions
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": props.router.location.pathname === "/logs"
                              })}
                              to="/logs"
                            >
                              Logs
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              className={classname("nav-link arrow-none", {
                                "active": props.router.location.pathname === "/profile"
                              })}
                              to="/profile"
                            >
                              Profile
                            </Link>

                          </li>
                        </>
                        : <li><div className="nav-link arrow-none" >&nbsp;</div></li>
                      }
                    </>
                  )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(Navbar));