import { getFirebaseBackend } from "helpers/firebase_helper";
import { postJwtLogin, postValidateUser } from "helpers/backend_helper";
import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import { setAuthorization } from "helpers/api_helper";
import { useNavigate } from "react-router-dom";

export const validateUser = (email: any) => async (dispatch: any) => {
    try {
        let response = await postValidateUser(email);
        if (response && response.success)
            dispatch(loginSuccess(response.message));
        else
            dispatch(apiError(response.message));
        return response;
    } catch (error) {
        dispatch(apiError(error));
    }
}

export const userAuthentication = (payload: any, history: any) => async (dispatch: any) => {
    try {
        let response: any = await postJwtLogin(payload);

        if (response && response.success) {
            localStorage.setItem("authUser", JSON.stringify(response.data));
            setAuthorization(response?.data?.token);
            dispatch(loginSuccess(response.data));

            let user = response?.data;

            if (user && user.user_type === 1) {
                history('/clients');
            } else if (user && user.user_type === 2) {
                history('/dashboard');
            }
        } else {
            dispatch(apiError(response.message));
        }
        return response;
    } catch (error) {
        dispatch(apiError(error));
    }
}

export const logoutUser = () => async (dispatch: any) => {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("selected-client");
        localStorage.removeItem("ar-summary-details");
        localStorage.removeItem("I18N_LANGUAGE");
        dispatch(logoutUserSuccess(true));
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const isExpireToken = (navigate: any) => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("selected-client");
    localStorage.removeItem("ar-summary-details");
    localStorage.removeItem("I18N_LANGUAGE");
    navigate('/login');
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};


export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
    try {
        let response: any;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);
        }

        const socialdata = await response;
        if (socialdata) {
            sessionStorage.setItem("authUser", JSON.stringify(socialdata));
            dispatch(loginSuccess(socialdata));
            history('/dashboard');
        }

    } catch (error) {
        dispatch(apiError(error));
    }
};