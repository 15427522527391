import { Navigate } from "react-router-dom"
// import Dashboard from "../pages/Dashboard";

import Dashboard from "../pages/Admin/Dashboard";
// import DashboardSaas from "../pages/Dashboard-saas";
// import DashboardCrypto from "../pages/Dashboard-crypto";
// import DashboardBlog from "../pages/Dashboard-blog";
// import DashboardJob from "../pages/Dashboard-job";

// // File Manager
// import FileManager from "../pages/FileManager/index"

// //Ecommerce
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
// import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
// import EcommerenceProducts from "../pages/Ecommerce/EcommerenceProducts/index";
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerenceProducts/EcommerceProductDetail";

// // //Tasks
// import TasksList from "../pages/Tasks/tasks-list";
// import TasksCreate from "../pages/Tasks/tasks-create";

// //Invoices
// import InvoicesList from "../pages/Invoices/invoices-list";
// import InvoiceDetail from "../pages/Invoices/invoices-detail";

// // //Projects
// import ProjectsGrid from "../pages/Projects/projects-grid";
// import ProjectsList from "../pages/Projects/projects-list";
// import ProjectsCreate from "../pages/Projects/projects-create";
// import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"

// // Pages Calendar
// import Calendar from "../pages/Calendar/index"

// //Chat
// import Chat from "../pages/Chat/Chat"

// //Email
// import EmailInbox from "../pages/Email/email-inbox"
// import EmailRead from "../pages/Email/email-read";
// import EmailBasicTemplate from "../pages/Email/email-basic-template";
// import EmailAlertTemplate from "../pages/Email/email-template-alert";
// import EmailTemplateBilling from "../pages/Email/email-template-billing"

// //Contact Page 
// import ContactsGrid from "../pages/Contacts/contacts-grid";
// import ContactsList from "../pages/Contacts/ContactList/contacts-list"
// import ContactsProfile from "../pages/Contacts/ContactProfile/contacts-profile";

// //Blog
// import BlogList from "../pages/Blog/BlogList/index";
// import BlogGrid from "../pages/Blog/BlogGrid/index";
// import BlogDetails from "../pages/Blog/BlogDetails";

// //Job
// import JobList from "../pages/JobPages/JobList";
// import JobGrid from "../pages/JobPages/JobGrid/index";
// import JobDetails from "../pages/JobPages/JobDetails";
// import JobCategories from "../pages/JobPages/JobCategories";
// import CandidateList from "../pages/JobPages/CandidateList";
// import CandidateOverview from "../pages/JobPages/CandidateOverview";
// import ApplyJobs from "../pages/JobPages/ApplyJobs/index";

// //Crypto
// import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet";
// import CryptoBuySell from "../pages/Crypto/crypto-buy-sell";
// import CryptoExchange from "../pages/Crypto/crypto-exchange";
// import CryptoLending from "../pages/Crypto/crypto-lending";
// import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application";
// import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
// import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

//Authentication pages
// import Login1 from "../pages/AuthenticationInner/Login";
// import Login2 from "../pages/AuthenticationInner/Login2";
// import Register from "../pages/AuthenticationInner/Register";
// import Register2 from "../pages/AuthenticationInner/Register2";
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
// import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
// import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
// import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
// import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
// import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
// import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
// import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
// import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";


//Pages
// import PagesStarter from "../pages/Utility/pages-starter";
// import PagesMaintenance from "../pages/Utility/pages-maintenance";
// import PagesTimeline from "../pages/Utility/pages-timeline";
// import PagesFaqs from "../pages/Utility/pages-faqs";
// import PagesPricing from "../pages/Utility/pages-pricing";
// import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// //Ui
// import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
// import UiCards from "../pages/Ui/UiCards";
// import UiDropdown from "../pages/Ui/UiDropdown";
// import UiGrid from "../pages/Ui/UiGrid";
// import UiImages from "../pages/Ui/UiImages";
// import UiModal from "../pages/Ui/UiModal";
// import UiOffCanvas from "../pages/Ui/UiOffCanvas";
// import UiRangeSlider from "../pages/Ui/UiRangeSlider";
// import UiProgressbar from "../pages/Ui/UiProgressbar";
// import UiPlaceholders from "../pages/Ui/UiPlaceholders";
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
// import UiTypography from "../pages/Ui/UiTypography";
// import UiToasts from "../pages/Ui/UiToast";
// import UiVideo from "../pages/Ui/UiVideo";
// import UiGeneral from "../pages/Ui/UiGeneral";
// import UiColors from "../pages/Ui/UiColors";
// import UiUtilities from "../pages/Ui/UiUtilities";
// import UiCarousel from "../pages/Ui/UiCarousel";
// import UiLightbox from "../pages/Ui/UiLightbox";
// import UiRating from "../pages/Ui/UiRating";
// import UiNotifications from "../pages/Ui/UiNotifications"


// // Forms
import FormElements from "../pages/Forms/FormElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormEditors from "../pages/Forms/FormEditors";
import FormUpload from "../pages/Forms/FormUpload";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormWizard from "../pages/Forms/FormWizard";
import FormMask from "../pages/Forms/FormMask";
import FormAdvanced from "../pages/Forms/FormAdvanced";


// //Tables
import BasicTables from "../pages/Tables/BasicTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";

// // Charts
// import ChartApex from "../pages/Charts/Apexcharts";
// import EChart from "../pages/Charts/EChart";
// import SparklineChart from "../pages/Charts/SparklineChart";
// import ReCharts from "../pages/Charts/ReCharts";
// import ChartjsChart from "../pages/Charts/ChartjsChart"

// //Icons
// import IconBoxicons from "../pages/Crypto/Icons/IconBoxicons";
// import IconDripicons from "../pages/Crypto/Icons/IconDripicons";
// import IconMaterialdesign from "../pages/Crypto/Icons/IconMaterialdesign";
// import IconFontawesome from "../pages/Crypto/Icons/IconFontawesome"


// // Maps
// import MapsVector from "../pages/Maps/MapsVector";
// import MapsLeaflet from "../pages/Maps/MapsLeaflet";
// import MapsGoogle from "pages/Maps/MapGoogle";
import DataTable from "pages/Tables/DataTable";

// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
// import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
// import SignUp from "pages/Authentication/Register"
import FormValidations from "pages/Forms/FormValidations";

import Logs from "pages/Admin/Logs";
import ARMonthlySummary from "pages/Admin/AR-Monthly-Summary";
import CustomReport from "pages/Admin/Custom-Report";
import Transactions from "pages/Admin/Transactions";
import LatestTranaction from "pages/Dashboard/LatestTranaction";
import MonthlySummary from "pages/Admin/Monthly";
import ContactsProfile from "../pages/Admin/Profile";
import ARMonthlySummaryDetails from "pages/Admin/AR-Monthly-Summary-Details";
import AR from "pages/Admin/AR";
import ATB from "pages/Admin/ATB";
import ZbaMonthlySummary from "pages/Admin/Zba-Monthly-Summary";
import ZbaMonthlySummaryDetails from "pages/Admin/Zba-Monthly-Summary-Details";
// import PatientType from "pages/Admin/Patient-Type";


const authProtectedRoutes = [
  { path: "/", exact: true, component: <Navigate to="/clients" /> },
  // Application Routes
  { path: "/dashboard", component: <LatestTranaction /> },
  { path: '/ar', component: <AR /> },
  { path: '/atb', component: <ATB /> },
  { path: "/clients", component: <Dashboard /> },
  { path: "/monthly", component: <MonthlySummary /> },
  // { path: "/ar-monthly-summary", component: <ARMonthlySummary /> },
  // { path: "/ar-monthly-summary-details", component: <ARMonthlySummaryDetails /> },
  // { path: "/zba-monthly-summary", component: <ZbaMonthlySummary /> },
  // { path: "/zba-monthly-summary-details", component: <ZbaMonthlySummaryDetails /> },
  { path: "/custom-report", component: <CustomReport /> },
  { path: "/transactions", component: <Transactions /> },
  { path: "/logs", component: <Logs /> },
  { path: "/profile", component: <ContactsProfile /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]
export { authProtectedRoutes, publicRoutes };
