// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first_line_details {
  display: flex; }

.first_line_details .special_wid {
  width: 16.66%;
  padding: 20px;
  background: #e9e9e9;
  color: #000;
  border: 0.5px solid #c5c5c5; }

.first_line_details .special_wid:nth-child(1) {
  background: #fff;
  color: #c5c5c5; }

.second_line_details {
  display: flex; }

.second_line_details .special_wid img {
  margin-left: 10px; }

.first_line_details .special_wid img {
  max-width: 11px;
  margin: -4px 4px 0 0px; }

.second_line_details .special_wid {
  width: 16.66%;
  padding: 10px 20px;
  background: #fff;
  border: 0.5px solid #c5c5c5;
  line-height: 40px;
  color: #192456; }

.second_line_details .special_wid:nth-child(1) {
  background: #e9e9e9; }

.fleg_black {
  background: #000 !important;
  border-radius: 50px;
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
  line-height: normal; }

.font-size-18 span {
  font-size: 16px;
  margin-left: 25px;
  font-weight: 400; }

.black_down {
  margin: 0 10px 0 0 !important;
  cursor: pointer; }

.custom_report_table .second_line_details .special_wid {
  text-align: right; }

.custom_report_table .second_line_details .special_wid:nth-child(1) {
  text-align: left; }

.cp {
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Custom-Report/index.scss"],"names":[],"mappings":"AAAA;EAEI,aAAa,EAAA;;AAEjB;EAEI,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,2BAA2B,EAAA;;AAE/B;EAEI,gBAAgB;EAChB,cAAa,EAAA;;AAGjB;EAEI,aAAa,EAAA;;AAEjB;EAAsC,iBAAiB,EAAA;;AACvD;EAEI,eAAe;EACf,sBAAsB,EAAA;;AAE1B;EAEI,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,2BAA2B;EAC3B,iBAAiB;EACjB,cAAa,EAAA;;AAEjB;EAEI,mBAAmB,EAAA;;AAGvB;EACI,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB,EAAA;;AAGvB;EAAmB,eAAe;EAAC,iBAAiB;EAAC,gBAAgB,EAAA;;AACrE;EAEI,6BAA6B;EAC7B,eAAe,EAAA;;AAEnB;EAAuD,iBAAiB,EAAA;;AACxE;EAAoE,gBAAgB,EAAA;;AACpF;EACI,eAAe,EAAA","sourcesContent":[".first_line_details\n{\n    display: flex;\n}\n.first_line_details .special_wid\n{\n    width: 16.66%;\n    padding: 20px;\n    background: #e9e9e9;\n    color: #000;\n    border: 0.5px solid #c5c5c5;\n}\n.first_line_details .special_wid:nth-child(1)\n{\n    background: #fff;\n    color:#c5c5c5;\n}\n\n.second_line_details\n{\n    display: flex;\n}\n.second_line_details .special_wid img{margin-left: 10px;}\n.first_line_details .special_wid img\n{\n    max-width: 11px;\n    margin: -4px 4px 0 0px;\n}\n.second_line_details .special_wid\n{\n    width: 16.66%;\n    padding: 10px 20px;\n    background: #fff;\n    border: 0.5px solid #c5c5c5;\n    line-height: 40px;\n    color:#192456;\n}\n.second_line_details .special_wid:nth-child(1)\n{\n    background: #e9e9e9;\n}\n\n.fleg_black {\n    background: #000 !important;\n    border-radius: 50px;\n    color: #fff;\n    padding: 10px 20px;\n    display: inline-block;\n    line-height: normal;\n}\n\n.font-size-18 span{font-size: 16px;margin-left: 25px;font-weight: 400;}\n.black_down\n{\n    margin: 0 10px 0 0 !important;\n    cursor: pointer;\n}\n.custom_report_table .second_line_details .special_wid{text-align: right;}\n.custom_report_table .second_line_details .special_wid:nth-child(1){text-align: left;}\n.cp{\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
