import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import './index.scss';
import { getMonthlyZBAFcReportDataURL, getMonthlyZBAPtReportDataURL } from "helpers/backend_helper";
import { isExpireToken } from "slices/thunk";
import back from "../../../assets/images/back-arrow.svg";
import { useLoader } from "provider/LoadingContext";

interface ZBAMonthlySummaryDetailsProps {
    isZba: boolean;
    setIsZba: React.Dispatch<React.SetStateAction<boolean>>;
}

const ZbaMonthlySummaryDetails: React.FC<ZBAMonthlySummaryDetailsProps> = ({ isZba, setIsZba }) => {
    const { setIsLoading } = useLoader();
    const navigate = useNavigate();
    const details = JSON.parse(localStorage.getItem("zba-summary-details") || '{}');
    const clients = JSON.parse(localStorage.getItem("selected-client") || '');
    const [summaryHeaderDetails] = useState<any[]>([details]);
    const [summaryDetails, setSummaryDetails] = useState<any[]>([]);
    const [expandedRows, setExpandedRows] = useState<any[]>([]);

    // Currency formatting function
    const formatCurrency = (value: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
    };

    // Custom cell component
    const BoldCell = ({ value }: { value: string }) => (
        <span><b>{value}</b></span>
    );

    const columns = [
        {
            name: <b></b>,
            selector: row => row.insurance_type_name,
            cell: row => <BoldCell value={`${row.insurance_type_name} Subtotal`} />,
            sortable: false,
        },
        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Total Billed Charges</b></div>,
            sortable: false,
            selector: row => row.total_billed_charges,
            format: row => formatCurrency(row.total_billed_charges),
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {formatCurrency(row.total_billed_charges)}
                </div>
            ),
        },
        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Total Actual Payments</b></div>,
            sortable: false,
            selector: row => row.total_actual_payments,
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {formatCurrency(row.total_actual_payments)}
                </div>
            ),
        },
        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Total Actual Contractual</b></div>,
            sortable: false,
            selector: row => row.actual_contractual,
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {formatCurrency(row.actual_contractual)}
                </div>
            ),
        },
        {
            name: <span style={{ fontWeight: 'bold', textAlign: 'end', width: '100%' }}>Payment %</span>,
            sortable: false,
            selector: row => {
                const val = (parseFloat(row.payment_percentage) * 100);
                return `${val}%`;
            },
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {`${parseFloat(row.payment_percentage).toFixed(2)}%`}
                </div>
            ),
        },
        {
            name: <span style={{ fontWeight: 'bold', textAlign: 'end', width: '100%' }}>Contractual %</span>,
            sortable: false,
            selector: row => {
                const val = (parseFloat(row.contractual_percentage) * 100);
                return `${val}%`;
            },
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {`${parseFloat(row.contractual_percentage).toFixed(2)}%`}
                </div>
            ),
        },
    ];

    const getMonthlyZBAReportData = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                monthYear: summaryHeaderDetails[0].discharge_date,
                isThreshold: true
            };
            let response = await getMonthlyZBAFcReportDataURL(body);
            if (response) {
                const fetchedSummaryDetails = response.data;
                setSummaryDetails(fetchedSummaryDetails);

                // Fetch expandable row data for each row and store in expandedRows state
                const expandedDataPromises = fetchedSummaryDetails.map(async (row: any) => {
                    console.log("")
                    const expandBody = {
                        clientId: clients.user_id,
                        monthYear: summaryHeaderDetails[0].discharge_date,
                        insuranceTypeId: row.insurance_type_id,
                        isThreshold: true
                    };
                    const result = await getMonthlyZBAPtReportDataURL(expandBody);
                    return {
                        ...row,
                        insurance_type_name: row.insurance_type_name,
                        expandedData: result?.data || []
                    };
                });

                const expandedDataResults = await Promise.all(expandedDataPromises);
                console.log("expandedDataResults", expandedDataResults)
                setExpandedRows(expandedDataResults);
                setIsLoading(false);

            } else {
                console.error("Failed to fetch data");
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getMonthlyZBAReportData();
    }, []);

    const ExpandableComponent = ({ data }) => {
        console.log("data",data)
        return (
            <div className="rdt_table_details">
                <div>
                    {data.expandedData.length > 0 ? (
                        <table>
                            <tbody>
                                {data.expandedData.map((row: any) => (
                                    (row.total_billed_charges !== 0 && row.total_actual_payments !== 0) && (
                                        <tr key={row.patient_type_abbreviation}>
                                            <td>{data.insurance_type_name} {row.patient_type_abbreviation}</td>
                                            <td className="text-end">{row.total_billed_charges ? formatCurrency(row.total_billed_charges) : '$0.00'}</td>
                                            <td className="text-end">{row.total_actual_payments ? formatCurrency(row.total_actual_payments) : '$0.00'}</td>
                                            <td className="text-end">{row.actual_contractual ? formatCurrency(row.actual_contractual) : '$0.00'}</td>
                                            <td className="text-end">{row.payment_percentage ? `${parseFloat(row.payment_percentage).toFixed(2)}%` : '0.0%'}</td>
                                            <td className="text-end">{row.contractual_percentage ? `${parseFloat(row.contractual_percentage).toFixed(2)}%` : '0.0%'}</td>
                                        </tr>
                                    )
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No Data Available</p>
                    )}
                </div>
            </div>
        );
    };

    const handleClick = () => {
        setIsZba(false);
    };

    const getMonthYear = (input: string) => {
        const [month, year] = input.split('-');
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const monthNumber = parseInt(month, 10);
        const monthName = monthNames[monthNumber - 1];
        return `${monthName} ${year}`;
    };

    return (
        <React.Fragment>
            <Container fluid={true}>
                <div>
                    <div className="d-flex align-items-center justify-content-between commen_top">
                        <div className="d-flex align-items-center">
                            <a className="me-3" onClick={handleClick}>
                                <img
                                    src={back}
                                    alt=""
                                    className="rounded-circle"
                                    height="30"
                                />
                            </a>
                            <h4 className="mb-sm-0 font-size-18">{getMonthYear(summaryHeaderDetails[0].discharge_date)}</h4>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={expandedRows}
                        expandableRows
                        expandableRowExpanded={(row) => !row.isSummaryRow}
                        expandableRowsComponent={ExpandableComponent}
                        expandableRowDisabled={row => row.isSummaryRow}
                    />
                </div>
            </Container>
        </React.Fragment>
    );
}

export default ZbaMonthlySummaryDetails;
