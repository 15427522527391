import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  Alert,
} from "reactstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  resetLoginMsgFlag,
  userAuthentication,
  validateUser,
} from "slices/auth/login/thunk";

// Import images
import logo from "../../assets/images/logo.svg";

import withRouter from "Components/Common/withRouter";
import { createSelector } from "reselect";
import { useLoader } from "provider/LoadingContext";
import moment from "moment";

const Login = (props: any) => {
  const { setIsLoading } = useLoader();
  const dispatch: any = useDispatch();
  const [userDetails, setUserDetails]: any = useState({});
  const [show, setShow]: any = useState(false);
  const [isAuthenticationMode, setAuthenticationMode]: any = useState(false);
  const [position, setPosition]: any = useState({ latitude: null, longitude: null });
  const [location, setLocation] = useState('Fetching location...');
  const [deviceInfo, setDeviceInfo] = useState('');
  const [date, setDate] = useState('');
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'long',
  };
  // meta title
  document.title = "Login | Rubin Brown";

  useEffect(() => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-US', options);
    setDate(formattedDate)
    // Get the device information
    const parseUserAgent = () => {
      const ua = navigator.userAgent;
      let browser = 'Unknown Browser';
      let version = '';
      let platform = 'Unknown Platform';

      if (ua.indexOf('Firefox') > -1) {
        browser = 'Firefox';
        version = ua.substring(ua.indexOf('Firefox') + 8);
      } else if (ua.indexOf('Chrome') > -1) {
        browser = 'Chrome';
        version = ua.substring(ua.indexOf('Chrome') + 7).split(' ')[0];
      } else if (ua.indexOf('Safari') > -1) {
        browser = 'Safari';
        version = ua.substring(ua.indexOf('Version') + 8).split(' ')[0];
      } else if (ua.indexOf('MSIE') > -1 || ua.indexOf('Trident/') > -1) {
        browser = 'Internet Explorer';
        version = ua.indexOf('MSIE') > -1
          ? ua.substring(ua.indexOf('MSIE') + 5).split(';')[0]
          : ua.substring(ua.indexOf('rv:') + 3).split(')')[0];
      }

      if (ua.indexOf('Mac') > -1) {
        platform = 'Apple Mac';
      } else if (ua.indexOf('Win') > -1) {
        platform = 'Windows';
      } else if (ua.indexOf('Linux') > -1) {
        platform = 'Linux';
      } else if (ua.indexOf('Android') > -1) {
        platform = 'Android';
      } else if (ua.indexOf('like Mac') > -1) {
        platform = 'iOS';
      }

      return `${browser} ${version} ${platform}`;
    };

    setDeviceInfo(parseUserAgent());
    // Get the location information
    // const fetchLocation = async () => {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       async (position) => {
    //         const { latitude, longitude } = position.coords;
    //         try {
    //           const response = await fetch(
    //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA9fINlcM19rOmCSgCXZdQImq8y2CpMvrc`
    //           );
    //           const data = await response.json();
    //           console.log('Geocoding response:', data);

    //           if (data.status === 'OK') {
    //             const addressComponents = data.results[0].address_components;
    //             let city, state, country, zip;

    //             addressComponents.forEach((component) => {
    //               const types = component.types;
    //               if (types.includes('locality')) {
    //                 city = component.long_name;
    //               }
    //               if (types.includes('administrative_area_level_1')) {
    //                 state = component.short_name;
    //               }
    //               if (types.includes('postal_code')) {
    //                 zip = component.long_name;
    //               }
    //               if (types.includes('country')) {
    //                 country = component.long_name;
    //               }
    //             });

    //             console.log(`City: ${city}, State: ${state}, Zip: ${zip}, Country: ${country}`);
    //             // setLocation(`${city} ${state} ${zip} ${country}`);
    //           } else {
    //             console.error('Error fetching location details:', data.status);
    //             // setLocation('Unable to fetch location details');
    //           }
    //         } catch (error) {
    //           console.error('Error fetching geocoding data:', error);
    //           // setLocation('Unable to fetch geocoding data');
    //         }
    //       },
    //       (error) => {
    //         console.error('Error fetching location', error);
    //         // setLocation('Unable to fetch location');
    //       }
    //     );
    //   } else {
    //     console.log('Geolocation is not supported by this browser.');
    //     // setLocation('Geolocation is not supported by this browser');
    //   }
    // };

    // fetchLocation();
  }, []);

  // console.log("deviceInfo", deviceInfo);
  // console.log("date", date);

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  );

  const { error } = useSelector(selectProperties);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
    device: "",
    date: ""
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    otp: "",
    device: "",
    date: ""
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...formErrors };

    // Validate email
    if (!formData.email) {
      newErrors.email = "Please Enter Your email";
      valid = false;
    } else {
      newErrors.email = "";
      valid = true;
    }

    // Validate password (if applicable)
    if (isAuthenticationMode && (userDetails && userDetails.user_type === 1)) {
      if (!formData.password) {
        newErrors.password = "Please Enter Your Password";
        valid = false;
      } else {
        newErrors.password = "";
        valid = true;
      }
    }

    // Validate OTP (if applicable)
    if (isAuthenticationMode) {
      if (!formData.otp) {
        newErrors.otp = "Enter the verification code sent to your email";
        valid = false;
      } else {
        newErrors.otp = "";
        valid = true;
      }
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    console.log("formData", formData);
    e.preventDefault();

    if (validateForm()) {
      const values = {
        ...formData, device: deviceInfo,
        date: date
      };

      if (isAuthenticationMode) {
        setIsLoading(true);
        // User Authentication API
        let response = await dispatch(userAuthentication(values, props.router.navigate));
        if (response && response?.success) {
          toast.success(response?.message);
          setAuthenticationMode(false);
          setIsLoading(false);
        } else {
          toast.error(response?.message);
          setIsLoading(false);
        }
      } else {
        // Send OTP API
        let response = await dispatch(validateUser(values));
        if (response && response?.success) {
          const user: any = response?.data;
          setUserDetails(user);
          setAuthenticationMode(true);
          toast.success("A verification code has been sent to your email")
          setIsLoading(false);
        } else {
          // toast.error(response?.message);
        }
      }
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft">
                  <Row>
                    <Col className="col-12">
                      <div className="p-3 text-center">
                        <img
                          src={logo}
                          alt=""
                          className=""
                          height="50"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-3">
                        {error ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="text"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              email: e.target.value,
                            })
                          }
                          value={formData.email}
                          invalid={formErrors.email ? true : false}
                        />
                        {formErrors.email ? (<span className="error">{formErrors.email}</span>) : null}
                      </div>

                      <div
                        className="mb-3"
                        hidden={(isAuthenticationMode && (userDetails && userDetails.user_type === 1)) ? false : true}
                      >
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={formData.password}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              })
                            }
                            invalid={formErrors.password ? true : false}
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light"
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {formErrors.password ? (<span className="error">{formErrors.password}</span>) : null}
                      </div>

                      <div className="mb-3" hidden={!isAuthenticationMode}>
                        <Label className="form-label">One-Time Passcode</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="otp"
                            value={formData.otp}
                            type="text"
                            placeholder="Enter One-Time Passcode"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                otp: e.target.value,
                              })
                            }
                            invalid={formErrors.otp ? true : false}
                          />
                        </div>
                        {formErrors.otp ? (<span className="error">{formErrors.otp}</span>) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-dark btn-block"
                          type="submit"
                        >
                          {isAuthenticationMode ? "Submit" : "Submit"}
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your
                          password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Rubin Brown.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);