import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import './index.scss';
import { useLoader } from "provider/LoadingContext";
import { useNavigate } from "react-router-dom";
import ARMonthlySummary from "../AR-Monthly-Summary";
import ZbaMonthlySummary from "../Zba-Monthly-Summary";

interface TabContentProps {
    activeTab: string;
    tabId: string;
    children: React.ReactNode;
}

const TabContent: React.FC<TabContentProps> = ({ activeTab, tabId, children }) => {
    return (
        <div role="tabpanel" hidden={activeTab !== tabId}>
            {children}
        </div>
    );
};

function MonthlySummary(props: any) {
    const navigate = useNavigate();
    const { setIsLoading } = useLoader();
    const [activeTab, setActiveTab] = useState<string>('tab2'); // Default is 'tab1'
    useEffect(() => {
        if (activeTab === 'tab1') {
            // Logic for 'tab1', e.g., fetching data for ZBA Transactions
            console.log("ZBA Transactions tab selected");
        }
        if (activeTab === 'tab2') {
            // Logic for 'tab2', e.g., fetching data for AR Transactions
            console.log("AR Transactions tab selected");
        }
    }, [activeTab]); // This effect runs whenever `activeTab` changes

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div className="mb-2 tab-btn justify-content-start">
                        <h4 className="fw-bold mb-0">Monthly</h4>
                        <div className="tab-header ms-auto mb-1">
                            <button
                                type="button"
                                className={activeTab === 'tab1' ? 'active' : ''}
                                onClick={() => handleTabClick('tab1')}
                            >
                                AR Summary
                            </button>
                            <button
                                type="button"
                                className={activeTab === 'tab2' ? 'active' : ''}
                                onClick={() => handleTabClick('tab2')}
                            >
                                ZBA Summary
                            </button>
                            <button
                                type="button"
                                className={activeTab === 'tab3' ? 'active' : ''}
                                onClick={() => handleTabClick('tab3')}
                            >
                                Summary of Month End Contractuals
                            </button>
                        </div>
                    </div>

                    <div className="row">

                        <div className="tab-content">
                            {activeTab === 'tab1' && (
                                <TabContent tabId="tab1" activeTab={activeTab}>
                                    <ARMonthlySummary />
                                </TabContent>
                            )}
                            {activeTab === 'tab2' && (
                                <TabContent tabId="tab2" activeTab={activeTab}>
                                    <ZbaMonthlySummary />
                                </TabContent>
                            )}
                            {activeTab === 'tab3' && (
                                <TabContent tabId="tab3" activeTab={activeTab}>
                                    <h1>Summary of Month End Contractuals</h1>
                                </TabContent>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default MonthlySummary;
