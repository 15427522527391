import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import './index.scss';
import black_down from "assets/images/black_down.svg";
import side_down from "assets/images/side_arrow.svg";

function CustomReport(props: any) {
    const array = [
        {
            "name": "Total AR",
            "ar": "$19,884,378.93",
            "contractual": "$10,000,000",
            "required_reserve": "53.91%",
            "per_GL": "53.91%",
            "adjustment": "$0",
            "sub-report": [],
        },
        {
            "name": "Medicare Subtotal",
            "ar": "$19,884,378.93",
            "contractual": "$10,000,000",
            "required_reserve": "53.91%",
            "per_GL": "53.91%",
            "adjustment": "$0",
            "sub-report": [{
                "name": "Medicare IP",
                "ar": "$19,884,378.93",
                "contractual": "$10,000,000",
                "required_reserve": "53.91%",
                "per_GL": "53.91%",
                "adjustment": "$0",
            },
            {
                "name": "Medicare OP",
                "ar": "$19,884,378.93",
                "contractual": "$10,000,000",
                "required_reserve": "53.91%",
                "per_GL": "53.91%",
                "adjustment": "$0",
            },
            {
                "name": "Medicare ED",
                "ar": "$19,884,378.93",
                "contractual": "$10,000,000",
                "required_reserve": "53.91%",
                "per_GL": "53.91%",
                "adjustment": "$0",
            },
            {
                "name": "Medicare SNF",
                "ar": "$19,884,378.93",
                "contractual": "$10,000,000",
                "required_reserve": "53.91%",
                "per_GL": "53.91%",
                "adjustment": "$0",
            }],

        },

        {
            "name": "Medicare Subtotal",
            "ar": "$19,884,378.93",
            "contractual": "$10,000,000",
            "required_reserve": "53.91%",
            "per_GL": "53.91%",
            "adjustment": "$0",
            "sub-report": [],
        }
    ]
    //meta title
    document.title = "Monthly Summary | Rubin Brown";


    const [openItems, setOpenItems] = useState<number[]>([]);

    const toggleOpen = (index: number) => {
        if (openItems.includes(index)) {
            setOpenItems(openItems.filter((item) => item !== index));
        } else {
            setOpenItems([...openItems, index]);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div>
                        {/* <div className="d-flex flex-wrap">
                            <h5 className="font-size-16 me-3">Recent Files</h5>

                            <div className="ms-auto">
                                <Link to="#" className="fw-medium text-reset">View All</Link>
                            </div>
                        </div>
                        <hr className="mt-2" /> */}

                        <div className="d-flex align-items-center justify-content-between commen_top">
                            <div className="d-flex align-items-center">
                                <h4 className="mb-sm-0 font-size-18">Monthly</h4>
                            </div>
                        </div>

                        <div className="monthly_list_details_table custom_report_table">
                            <div className="first_line_details">
                                <div className="special_wid">&nbsp;</div>
                                <div className="special_wid">AR</div>
                                <div className="special_wid">Contractual %</div>
                                <div className="special_wid">Required Reserve</div>
                                <div className="special_wid">Per G/L</div>
                                <div className="special_wid">Adjustment</div>
                            </div>
                            {array.map((item, index) => (
                                <div key={index}>
                                    <div className="mt-4">
                                        <div
                                            className={`second_line_details ${openItems.includes(index) ? 'open' : ''}`}
                                            onClick={() => toggleOpen(index)}
                                        >
                                            <div className={`special_wid ${item['sub-report'].length > 0 ? 'sub cp' : ''}`}>

                                                {item['sub-report'].length > 0 && (
                                                    <img src={openItems.includes(index) ? black_down : side_down} alt="" className="img-fluid black_down" />
                                                )}

                                                {item.name}
                                            </div>
                                            <div className="special_wid">{item.ar}</div>
                                            <div className="special_wid">{item.contractual}</div>
                                            <div className="special_wid">{item.required_reserve}</div>
                                            <div className="special_wid">{item.per_GL}</div>
                                            <div className="special_wid">{item.adjustment}</div>
                                        </div>
                                        {item['sub-report'].length > 0 && openItems.includes(index) && (
                                            <div className="sub">
                                                {item['sub-report'].map((subItem, subIndex) => (
                                                    <div key={subIndex} className="second_line_details">
                                                        <div className="special_wid">{subItem.name}</div>
                                                        <div className="special_wid">{subItem.ar}</div>
                                                        <div className="special_wid">{subItem.contractual}</div>
                                                        <div className="special_wid">{subItem.required_reserve}</div>
                                                        <div className="special_wid">{subItem.per_GL}</div>
                                                        <div className="special_wid">{subItem.adjustment}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Container>


            </div>
        </React.Fragment>
    );
}

export default CustomReport;