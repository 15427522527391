//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_VALIDATE_USER = "/validateUser";
export const POST_USER_AUTHENTICATION = "/userAuthentication";

export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//CLINET
export const GETCLIENTS = "/getClients";
export const CREATECLIENT = "/createClient";
export const SAVECLIENTPROVIDERINFO = "/saveClientProviderInfo";
export const SAVECLIENTDATAFIELDS = "/saveClientDataFields";
export const GET_DATAFIELDS = "/getDataFields";
export const GET_CLIENTPROFILE = "/getClientDetails";
export const GET_AUTO_COMPLETED_DATA_FIELD = "/getAutoCompleteDataField";


//Upload FIle
export const UPLOAD_FILE = "/uploadEdi835File";
export const GET_CLIENT_UPLOADED_DATA = "/getEdi835Data";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

// Calendar
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

// Chat
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "add/message";

// Ecommerce
// Product
export const GET_PRODUCTS = "/apps/product";
export const GET_PRODUCTS_DETAIL = "/apps/productdetail"

export const GET_PRODUCT_COMMENTS = "/comments-product";
export const ON_LIKNE_COMMENT = "/comments-product-action";
export const ON_ADD_REPLY = "/comments-product-add-reply";
export const ON_ADD_COMMENT = "/comments-product-add-comment";

// Orders
export const GET_ORDERS = "/apps/order";
export const ADD_NEW_ORDER = "/apps/order";
export const UPDATE_ORDER = "/apps/order";
export const DELETE_ORDER = "/apps/order";

// Customers
export const GET_CUSTOMERS = "/apps/customer";
export const ADD_NEW_CUSTOMER = "/apps/customer";
export const UPDATE_CUSTOMER = "/apps/customer";
export const DELETE_CUSTOMER = "/apps/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//cart

export const GET_CART = "/cart";
export const DELETE_CART = "/delete-cart";

//CRYPTO
export const GET_WALLET = "/wallet";
//activities
export const GET_WALLET_ACTIVITIES = "wallet-activies";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/projects";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

// Task
export const GET_TASKS = "/apps/task";

//CONTACTS
export const GET_USERS = "/apps/users";
export const ADD_NEW_USERS = "/apps/users";
export const UPDATE_USERS = "/apps/users";
export const DELETE_USERS = "/delete/users/";
export const GET_USER_PROFILE = "/user";

// Contact
export const GET_CONTACTS = "/apps/contact";

// Crypto
export const GET_CRYPTO_ORDRER_LIST = "/crypto-order-list";

// Invoice
export const GET_INVOICES = "/apps/invoice";
export const GET_INVOICE_DETAIL = "/apps/invoice-detail";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//Delete mail
export const DELETE_MAIL = "/mail/delete";

export const GET_EARNING_DATA = "/earning-charts-data";

export const TOP_SELLING_DATA = "/top-selling-data";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

//letest trangection
export const GET_TRANSCECTION = "/transcection";
// export const GET_TRANSCECTION = "/getEdi835Data";

//Jobs
export const GET_JOB_LIST = "/jobs";
export const DELETE_JOB_LIST = "/delete/job";
export const ADD_NEW_JOB_LIST = "/job/add"
export const UPDATE_JOB_LIST = "/job/update"

//job grid
export const GET_JOB_GRID = "/job-grid";
//job condidate list
export const GET_CANDIDATE0_LIST = "/condidate-list";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "/delete/applyjob";

//Chat
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";

//Provider Profile
export const SAVE_CLIENT_PROVIDER_INFO = "/saveClientProviderInfo";
export const UPDATE_CLIENT_PROVIDER_INFO = "/updateClientProviderInfo";

//Edit Address
export const UPDATE_CLIENT_ADDRESS = "/updateClientAddress";

//Edit Contact
export const UPDATE_CLIENT_PRIMARYCONTACT = "/updateClientPrimaryContact";

//Edit Client Name
export const UPDATE_CLIENT_NAME = "/updateClientDetails";

//Get Provider
export const GET_CLIENT_PROVIDER = "/getClientProviders";

//Delete Provider
export const DELETE_CLIENT_PROVIDER = "/deleteClientProvider";

//Get AR
export const GET_AR_SOURCE_DATA = "/getARSourceData";
//Get AR Transaction
export const GET_AR_TRANSACTION_DATA = "/getARTransactions";
//Get ZBA Transaction
export const GET_ZBA_TRANSACTION_DATA = "/getZBATransactions";
// Import AR
export const IMPORT_AR_SOURCE_DATA = "/importARSourceData";

//get data field drop-down
export const CREATE_DATA_FIELDS = "/createDataField";

//get data field by id
export const GET_DATA_FIELDS_BY_ID = "/getDataFieldById";

//get data field drop-down
export const UPDATE_DATA_FIELDS = "/updateDataField";

//upload data
export const UPDATE_CLIENT_PROFILE_IMAGE = "/updateClientProfileImage";

//get patient type data
export const GET_PATIENT_TYPE = "/getPatientTypes";

//create patient type data
export const CREATE_PATIENT_TYPE = "/createPatientType";

//update patient type data
export const UPDATE_PATIENT_TYPE = "/updatePatientType";

//delete patient type data
export const DELETE_PATIENT_TYPE = "/deletePatientType";

//Get ATB
export const GET_ATB_SOURCE_DATA = "/getATBSourceData ";

// Import ATB
export const IMPORT_ATB_SOURCE_DATA = "/importATBSourceData";

//
//get ZBA
export const GET_ZBA_THRESHOLDS = "/getZBAThresholds";

//create ZBA
export const CREATE_ZBA_THRESHOLDS = "/createZBAThreshold";

//update ZBA
export const UPDATE_ZBA_THRESHOLDS = "/updateZBAThreshold";

//delete ZBA
export const DELETE_ZBA_THRESHOLDS = "/deleteZBAThreshold";

//Get AR
export const GET_MONTHLY_AR_REPORT_DATA = "/getMonthlyARReportData";

//Get AR
export const GET_MONTHLY_AR_FC_REPORT_DATA = "/getMonthlyARFcReportData";

//Get AR
export const GET_MONTHLY_AR_PT_REPORT_DATA = "/getMonthlyARPtReportData";

//Get ZBA
export const GET_MONTHLY_ZBA_REPORT_DATA = "/getMonthlyZBAReportData";

//Get ZBA
export const GET_MONTHLY_ZBA_FC_REPORT_DATA = "/getMonthlyZBAFcReportData";

//Get ZBA
export const GET_MONTHLY_ZBA_PT_REPORT_DATA = "/getMonthlyZBAPtReportData";



