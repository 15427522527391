import React, { Fragment } from "react";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
} from "react-table";
import { Table, Row, Col, Button } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "./GlobalSearchFilter";
import DynamicPagination from "./DynamicPagination";

interface filter {
    preGlobalFilteredRows: any;
    globalFilter?: any;
    setGlobalFilter: any;
    isJobListGlobalFilter: any;
}

interface TableContainerProps {
    isLoading: any;
    columns?: any;
    data: any;
    isGlobalFilter?: any;
    isAddOptions?: any;
    isAddUserList?: any;
    isAddInvoiceList?: any;
    handleOrderClicks?: any;
    handleUserClick?: any;
    handleCustomerClick?: any;
    isAddCustList?: any;
    customPageSize: any;
    className?: any;
    handleInvoiceClick?: any;
    customPageSizeOptions?: any;
    isJobListGlobalFilter?: any;
    isGlobalSearch?: any;
    isProductsFilter?: any;
    isCustomerFilter?: any;
    isOrderFilter?: any;
    isContactsFilter?: any;
    isCompaniesFilter?: any;
    isLeadsFilter?: any;
    isCryptoOrdersFilter?: any;
    isInvoiceListFilter?: any;
    isTicketsListFilter?: any;
    isNFTRankingFilter?: any;
    isTaskListFilter?: any;
    tableClass?: any;
    theadClass?: any;
    trClass?: any;
    thClass?: any;
    divClass?: any;
    SearchPlaceholder?: any;
    isPagination?: any;
    customPageSizeOption?: any
    setCustomPageSize?: any;
    perPageData?: any;
    totalItems?: any;
    currentPage?: any;
    setCurrentPage?: any;
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,

}: filter) {
    const count: any = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value: any) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <React.Fragment>
            <Col md={4}>
                <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={`${count} records...`}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    );
}

const TableContainer: any = ({
    isLoading,
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    customPageSize,
    tableClass,
    theadClass,
    customPageSizeOptions,
    customPageSizeOption,
    setCustomPageSize,
    perPageData,
    totalItems,
    currentPage,
    setCurrentPage,
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageSize },
    }: any = useTable(
        {
            columns,
            data,
            perPageData,
            totalItems,
            currentPage,
            setCurrentPage,
            defaultColumn: { Filter: null },
            initialState: {
                pageIndex: 0,
                pageSize: customPageSize,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const pageNumbers: any = [];
    for (let i = 1; i <= Math.ceil(data?.length / customPageSize); i++) {
        pageNumbers.push(i);
    }
    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? <span>&#9650;</span> : <span>&#9660;</span>) : "";
    };

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        setCustomPageSize(Number(event.target.value))
    };

    return (
        <Fragment>
            <div className="table-responsive table-container">
                <Table hover {...getTableProps()} className={tableClass}>
                    <thead className={theadClass}>
                        {headerGroups.map((headerGroup: any) => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th key={column.id} className={`${column.isSortable ? "sorting_asc" : ""}`}>
                                        <div {...column.getSortByToggleProps()}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                        <Filter column={column} />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {isLoading ? (
                            // Render skeleton loading rows while data is loading
                            // You can customize the skeleton row based on your design
                            Array.from({ length: 5 }).map((_, index) => (
                                <tr key={`skeleton-row-${index}`}>
                                    {headerGroups[0].headers.map((column) => (
                                        <td key={column.id}>
                                            {/* Add your skeleton loading component here */}
                                            <div className="skeleton-loading" style={{ width: '100%', height: '20px' }} />
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : data.length === 0 ? (
                            // Render a "No Data Found" message if the data array is empty
                            <tr>
                                <td colSpan={headerGroups[0].headers.length} className="text-center fw-bold">
                                    No Data Found
                                </td>
                            </tr>
                        ) : (
                            // Render actual rows when data is available
                            page.map((row: any, rowIndex: number) => {
                                prepareRow(row);
                                return (
                                    <tr key={row.id} {...row.getRowProps()}>
                                        {row.cells.map((cell: any, cellIndex: number) => (
                                            <td key={cell.column.id} {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>

                    {/* <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell: any) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody> */}
                </Table>
            </div>
            <Row className="mt-2">
                {isGlobalFilter && (
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isJobListGlobalFilter={isJobListGlobalFilter}
                    />
                )}
                {isAddOptions && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleOrderClicks}
                            >
                                <i className="mdi mdi-plus me-1" />
                                Add New Order
                            </Button>
                        </div>
                    </Col>
                )}
                {isAddUserList && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="primary"
                                className="btn mb-2 me-2"
                                onClick={handleUserClick}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create New User
                            </Button>
                        </div>
                    </Col>
                )}
                {isAddCustList && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="success"
                                className="btn-rounded mb-2 me-2"
                                onClick={handleCustomerClick}
                            >
                                <i className="mdi mdi-plus me-1" />
                                New Customers
                            </Button>
                        </div>
                    </Col>
                )}

            </Row>
        </Fragment>
    );
};

export default TableContainer;
