import React, { useState } from "react";
import {
    Container
} from "reactstrap";
import './index.scss';


function Log(props: any) {

    const array = [
        {
            name: "Bee 12",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 12",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: false
        },
        {
            name: "Bee 12",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 12",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: false
        },
        {
            name: "Bee 12",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 1242",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 45",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 23",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 23",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 23",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: false
        },
        {
            name: "Bee 2993",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 77",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 66",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        },
        {
            name: "Bee 55",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: false
        },
        {
            name: "Bee 34",
            upload_method: "Batch",
            insurance_type: "Type",
            record_type: "type",
            time_uploaded: "1/2/23 10:10PM",
            by: "Name",
            transaction: "23 Transactions",
            isTrue: true
        }
    ];
    //pagination code start
    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const paginatedArray = array.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    //pagination code end

    // const handlePageClick = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };
    //meta title
    document.title = "Monthly Summary | Rubin Brown";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div>
                        {/* <div className="d-flex flex-wrap">
                            <h5 className="font-size-16 me-3">Recent Files</h5>

                            <div className="ms-auto">
                                <Link to="#" className="fw-medium text-reset">View All</Link>
                            </div>
                        </div>
                        <hr className="mt-2" /> */}

                        <div className="d-flex align-items-center justify-content-between commen_top">
                            <div className="">
                                <h4 className="mb-sm-0 font-size-18">835 Log </h4>
                            </div>


                        </div>
                        <div className="d-flex align-items-center justify-content-between commen_top mt-4 mb-4">
                            <div className="d-flex align-items-center">
                                <div className="right_select me-3">
                                    <select name="" id="" className="form-select">
                                        <option value="">Date From</option>
                                        <option value="">Date From</option>
                                        <option value="">Date From</option>
                                    </select>
                                </div>
                                <div className="right_select me-3">
                                    <select name="" id="" className="form-select">
                                        <option value="">Date To</option>
                                        <option value="">Date To</option>
                                        <option value="">Date To</option>
                                    </select>
                                </div>

                                <button className="btn btn-primary waves-effect waves-light btn btn-primary">Update</button>
                            </div>
                            <button className="btn btn-outline-primary">Export</button>

                        </div>

                        <div className="data_table_box log_table">
                            <div className="mt-4">
                                <div className="table-responsive">
                                    <table className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover">
                                        <thead className="table-light">
                                            <tr role="row">
                                                <th className=""><div title="Toggle SortBy" >Name</div></th>
                                                <th className=""><div title="Toggle SortBy">Upload Method</div></th>
                                                <th className=""><div title="Toggle SortBy" >Insurance Type</div></th>
                                                <th className=""><div title="Toggle SortBy" >835 Type</div></th>
                                                <th className=""><div title="Toggle SortBy" >Time Uploaded</div></th>
                                                <th className=""><div title="Toggle SortBy" >By</div></th>
                                                <th className=""><div title="Toggle SortBy" >&nbsp;</div></th>
                                                <th className=""><div title="Toggle SortBy" >&nbsp;</div></th>
                                            </tr>
                                        </thead>
                                        <tbody role="row">
                                            {paginatedArray.map((item, index) => (
                                                <tr key={index}>
                                                    <td role="cell">{item.name}</td>
                                                    <td role="cell">{item.upload_method}</td>
                                                    <td role="cell">{item.insurance_type}</td>
                                                    <td role="cell">{item.record_type}</td>
                                                    <td role="cell">{item.time_uploaded}</td>
                                                    <td role="cell">{item.by}</td>
                                                    <td role="cell" className="clr_blue">{item.transaction}</td>
                                                    <td role="cell">
                                                        <a  className={item.isTrue ? "eye_open" : "eye_close"}></a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className="mt-5 mb-5">
                                <ul className="pagination pagination-rounded justify-content-center mb-2">
                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                        <a
                                            
                                            className="page-item pagination-prev page-link"
                                            aria-label="Previous"
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                        >
                                            <span aria-hidden="true">‹</span>
                                            <span className="visually-hidden">Previous</span>
                                        </a>
                                    </li>
                                    {Array.from({ length: Math.ceil(array.length / itemsPerPage) }).map((_, index) => (
                                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                            <a
                                                
                                                id={String(index + 1)}
                                                className="page page-link"
                                                onClick={() => setCurrentPage(index + 1)}
                                            >
                                                {index + 1}
                                            </a>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === Math.ceil(array.length / itemsPerPage) ? 'disabled' : ''}`}>
                                        <a
                                            
                                            className="page-item pagination-next page-link"
                                            aria-label="Next"
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                        >
                                            <span aria-hidden="true">›</span>
                                            <span className="visually-hidden">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>


            </div>
        </React.Fragment>
    );
}

export default Log;