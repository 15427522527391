import React from "react";
import { Row, PaginationItem, PaginationLink } from 'reactstrap';

interface PaginationProps {
    perPageData: number;
    totalItems: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
}

const DynamicPagination: React.FC<PaginationProps> = ({ perPageData, totalItems, currentPage, setCurrentPage }) => {
    const totalPages = Math.ceil(totalItems / perPageData);
    const maxPageNumbersToShow = 10; // Change this value to limit the number of page numbers displayed

    const handleClick = (page: number) => {
        setCurrentPage(page);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
    };

    const getPageNumbers = () => {
        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
        let endPage = startPage + maxPageNumbersToShow - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
        }

        const pageNumbers: number[] = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const pageNumbers = getPageNumbers();
    // Calculate the range of items being displayed
    const startItem = (currentPage - 1) * perPageData + 1;
    const endItem = Math.min(currentPage * perPageData, totalItems);
    
    return (
        <React.Fragment>
            <Row className="justify-content-between align-items-center mb-3" id="pagination-element" style={{ display: "flex" }}>
                <div className="col-auto">
                    <span>{`Showing ${startItem} to ${endItem} of ${totalItems} records`}</span>
                </div>
                <div className="col-auto">
                    <ul className="pagination pagination-rounded justify-content-center mb-2">
                        {/* First Page Button */}
                        <PaginationItem className={currentPage === 1 ? "disabled" : ""}>
                            <PaginationLink onClick={handleFirstPage}>
                                {"<<"}
                            </PaginationLink>
                        </PaginationItem>

                        {/* Previous Page Button */}
                        <PaginationItem className={currentPage <= 1 ? "disabled" : ""}>
                            <PaginationLink previous onClick={handlePrevPage} />
                        </PaginationItem>

                        {/* Page Numbers */}
                        {pageNumbers.map((item, key) => (
                            <PaginationItem className={currentPage === item ? "active" : ""} key={key}>
                                <PaginationLink onClick={() => handleClick(item)}>
                                    {item}
                                </PaginationLink>
                            </PaginationItem>
                        ))}

                        {/* Next Page Button */}
                        <PaginationItem className={currentPage >= totalPages ? "disabled" : ""}>
                            <PaginationLink next onClick={handleNextPage} />
                        </PaginationItem>

                        {/* Last Page Button */}
                        <PaginationItem className={currentPage === totalPages ? "disabled" : ""}>
                            <PaginationLink onClick={handleLastPage}>
                                {">>"}
                            </PaginationLink>
                        </PaginationItem>
                    </ul>
                </div>
            </Row>
        </React.Fragment>
    );
};

export default DynamicPagination;
