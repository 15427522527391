import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

// Gets the logged in user data from local session
export const getSelectedClientData = () => {
  const clientData = localStorage.getItem("selected-client")
  if (clientData) return JSON.parse(clientData);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data)
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// upload File
// export const uploadFile = (data: any) => api.upload(url.UPLOAD_FILE, data);

export const uploadFile = (data: { file: File }, clientId) => {
  const { file } = data;
  const formData = new FormData();
  formData.append('edi835File', file);
  formData.append('clientId', clientId);

  return api.upload(url.UPLOAD_FILE, formData);
};

// Validate User Method
export const getEdi835Data = (clientId) => api.create(url.GET_CLIENT_UPLOADED_DATA, { clientId });

// Get all clients
export const getClients = () => api.get(url.GETCLIENTS, {});

// api.get Data fields for client
export const getDataFields = (data: any) => api.create(url.GET_DATAFIELDS, data);

// Add Client
export const postAddClient = (data: any) => api.upload(url.CREATECLIENT, data);

// Add Client / provider info
export const postAddProviderInfo = (data: any) => api.create(url.SAVECLIENTPROVIDERINFO, data);

// Add Client / Data Fields
export const postAddDataFields = (data: any) => api.create(url.SAVECLIENTDATAFIELDS, data);

//Profile
export const postGetClientProfileData = (clientId) => api.create(url.GET_CLIENTPROFILE, { clientId });

// Validate User Method
export const postValidateUser = (data: any) => api.create(url.POST_VALIDATE_USER, data);

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_USER_AUTHENTICATION, data);

// Login Method
export const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);

// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data)
    .catch((err: any) => {
      var message: any;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

// Calendar
// api.get Events
export const getEvents = () => api.get(url.GET_EVENTS, null);

// api.get Events
export const getCategories = () => api.get(url.GET_CATEGORIES, null);

// add Events
export const addNewEvent = (event: any) => api.create(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = (event: any) => api.put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = (event: any) => api.delete(url.DELETE_EVENT, { headers: { event } });

// api.get Messages
export const getMessages = (roomId: any) => api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// add Message
export const addMessage = (message: any) => api.create(url.ADD_MESSAGE, message);

export const getStarredMails = () => api.get(url.GET_STARRED_MAILS, null);

export const getImportantMails = () => api.get(url.GET_IMPORTANT_MAILS, null);

// get starredmail
export const getDraftMails = () => api.get(url.GET_DRAFT_MAILS, null);

// get inboxmail
export const getInboxMails = () => api.get(url.GET_INBOX_MAILS, null);

// get sent mail
export const getSentMails = () => api.get(url.GET_SENT_MAILS, null);

// get trash mail
export const getTrashMails = () => api.get(url.GET_TRASH_MAILS, null);

// delete Mail
export const deleteMail = (mail: any) => api.delete(url.DELETE_MAIL, { headers: { mail } });

// Ecommerce
// api.get Products
export const getProducts = () => api.get(url.GET_PRODUCTS, null);

// api.get ProductDetails
export const getProductDetail = (id: number) =>
  api.get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

//Product Comments 
export const getProductComents = () => api.get(url.GET_PRODUCT_COMMENTS, null);

export const onLikeComment = (commentId: number, productId: number) => {
  return api.create(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};

export const onLikeReply = (commentId: any, productId: any, replyId: any) => {
  return api.create(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

export const onAddReply = (commentId: any, productId: any, replyText: any) => {
  return api.create(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

export const onAddComment = (productId: any, commentText: any) => {
  return api.create(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

//cart

export const getCart = () => api.get(url.GET_CART, null);
export const deleteCart = (cart: any) => api.delete(url.DELETE_CART, { headers: { cart } });

// get shops
export const getShops = () => api.get(url.GET_SHOPS, null);

// api.get Orders
export const getOrders = () => api.get(url.GET_ORDERS, null);

// add Order
export const addNewOrder = (order: any) => api.create(url.ADD_NEW_ORDER, order);

// update Order
export const updateOrder = (order: any) => api.put(url.UPDATE_ORDER, order);

// delete Order
export const deleteOrder = (order: any) => api.delete(url.DELETE_ORDER, { headers: { order } });

// api.get Customers
export const getCustomers = () => api.get(url.GET_CUSTOMERS, null);

// add Customers
export const addNewCustomer = (customer: any) => api.create(url.ADD_NEW_CUSTOMER, customer);

// update Customers
export const updateCustomer = (customer: any) => api.put(url.UPDATE_CUSTOMER, customer);

// delete Customers
export const deleteCustomer = (customer: any) => api.delete(url.DELETE_CUSTOMER, { headers: { customer } });

// get wallet
export const getWallet = () => api.get(url.GET_WALLET, null);
//get wallwr / activities
export const getWalletActivities = () => api.get(url.GET_WALLET_ACTIVITIES, null);

// get tasks
export const getTasks = () => api.get(url.GET_TASKS, null);

//// get contacts
export const getUsers = () => api.get(url.GET_USERS, null);

// add User
export const addNewUser = (user: any) => api.create(url.ADD_NEW_USERS, user);

// update User
export const updateUser = (user: any) => api.put(url.UPDATE_USERS, user);


//Delete Contact User
export const deleteUsers = (users: any) => api.delete(url.DELETE_USERS, { headers: { users } });

export const getUserProfile = () => api.get(url.GET_USER_PROFILE, null);

export const getEarningChartsData = (month: any) => api.get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

export const getTopSellingData = (month: any) => api.get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

// CRM
// api.get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS, null);

// Order List
export const getCryptoOrderList = () => api.get(url.GET_CRYPTO_ORDRER_LIST, null);

// Invoice
//api.get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES, null);

export const getInvoicesDetail = (id: number) => api.get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get project
export const getProjects = () => api.get(url.GET_PROJECTS, null);

/** PROJECT */
// add user
export const addNewProject = (project: any) => api.create(url.ADD_NEW_PROJECT, project);

// update user
export const updateProject = (project: any) => api.put(url.UPDATE_PROJECT, project);

// delete user
export const deleteProject = (project: any) => api.delete(url.DELETE_PROJECT, { headers: { project } });

// get project details
export const getProjectsDetail = (id: any) =>
  api.get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// Dashboard Analytics

// get dashboard charts data
export const getWeeklyData = (data: any) => api.get(url.GET_WEEKLY_DATA, null);
export const getYearlyData = (data: any) => api.get(url.GET_YEARLY_DATA, null);
export const getMonthlyData = (data: any) => api.get(url.GET_MONTHLY_DATA, null);

//letest trangection
export const getTranscation = () => api.get(url.GET_TRANSCECTION, null);

// get jobs
export const getJobList = () => api.get(url.GET_JOB_LIST, null);
// delete jobs
export const deleteJobList = (jobs: any) => api.delete(url.DELETE_JOB_LIST, { headers: { jobs } });
// add jobs
export const addNewJobList = (job: any) => api.create(url.ADD_NEW_JOB_LIST, job);

// update jobs
export const updateJobList = (job: any) => api.put(url.UPDATE_JOB_LIST, job);

// get Apply Jobs
export const getApplyJob = () => api.get(url.GET_APPLY_JOB, null);
// Delete Apply Jobs
export const deleteApplyJob = (jobs: any) => api.delete(url.DELETE_APPLY_JOB, { headers: { jobs } });

// get chats
export const getChats = () => api.get(url.GET_CHATS, null);

// // get groups
export const getGroups = () => api.get(url.GET_GROUPS, null);

//grid job
export const getJobGrid = () => api.get(url.GET_JOB_GRID, null);

//job Candidate List
export const getJobCandidateList = () => api.get(url.GET_CANDIDATE0_LIST, null);

// Add Provider
export const saveClientProviderInfo = (data: any) => api.create(url.SAVE_CLIENT_PROVIDER_INFO, data);

// Edit Provider
export const updateClientProviderInfo = (data: any) => api.create(url.UPDATE_CLIENT_PROVIDER_INFO, data);

// Edit Address
export const updateClientAddress = (data: any) => api.create(url.UPDATE_CLIENT_ADDRESS, data);

// Edit contact
export const updateClientPrimaryContact = (data: any) => api.create(url.UPDATE_CLIENT_PRIMARYCONTACT, data);

//Update Client List
export const updateClientDetails = (data: any) => api.create(url.UPDATE_CLIENT_NAME, data);

//Get Provider List
export const getClientProviders = (data: any) => api.create(url.GET_CLIENT_PROVIDER, data);

//delete provider
export const deleteClientProvider = (data: any) => api.create(url.DELETE_CLIENT_PROVIDER, data);

//Get AR List
export const getARSourceData = (data: any) => api.create(url.GET_AR_SOURCE_DATA, data);

//Get AR Transaction List
export const getARTransactionData = (data: any) => api.create(url.GET_AR_TRANSACTION_DATA, data);

//Get ZBA Transaction List
export const getZBATransactionData = (data: any) => api.create(url.GET_ZBA_TRANSACTION_DATA, data);

//Import AR
export const uploadARFile = (data: { file: File }, clientId, date) => {
  const { file } = data;
  const formData = new FormData();
  formData.append('ArFile', file);
  formData.append('clientId', clientId);
  formData.append('monthEndDate', date);

  return api.upload(url.IMPORT_AR_SOURCE_DATA, formData);
};

//Get data field drop-down
export const createDataField = (data: any) => api.create(url.CREATE_DATA_FIELDS, data);

// Get Auto Data fields
export const getAutoCompleteDataField = (data: any) => api.create(url.GET_AUTO_COMPLETED_DATA_FIELD, data);

//get data field by id
export const getDataFieldById = (data: any) => api.create(url.GET_DATA_FIELDS_BY_ID, data);

//Get data field drop-down
export const updateDataField = (data: any) => api.create(url.UPDATE_DATA_FIELDS, data);

//upload profile logo
export const updateClientProfileImage = (data: { file: File }, clientId) => {
  const { file } = data;
  const formData = new FormData();
  formData.append('profilePicture', file);
  formData.append('clientId', clientId);

  return api.upload(url.UPDATE_CLIENT_PROFILE_IMAGE, formData);
};

//get patient type
export const getPatientTypes = (data: any) => api.create(url.GET_PATIENT_TYPE, data);

//create patient type
export const createPatientType = (data: any) => api.create(url.CREATE_PATIENT_TYPE, data);

//update patient type
export const updatePatientType = (data: any) => api.create(url.UPDATE_PATIENT_TYPE, data);

//delete patient type
export const deletePatientType = (data: any) => api.create(url.DELETE_PATIENT_TYPE, data);

//Get AR List
export const getATBSourceData = (data: any) => api.create(url.GET_ATB_SOURCE_DATA, data);
//upload profile logo

//Import AR
export const uploadATBFile = (data: { file: File }, clientId) => {
  const { file } = data;
  const formData = new FormData();
  formData.append('ATBFile', file);
  formData.append('clientId', clientId);

  return api.upload(url.IMPORT_ATB_SOURCE_DATA, formData);
};

//get zba
export const getZBAThresholds = (data: any) => api.create(url.GET_ZBA_THRESHOLDS, data);

//create zba
export const createZBAThreshold = (data: any) => api.create(url.CREATE_ZBA_THRESHOLDS, data);

//update zba
export const updateZBAThreshold = (data: any) => api.create(url.UPDATE_ZBA_THRESHOLDS, data);

//delete zba
export const deleteZBAThreshold = (data: any) => api.create(url.DELETE_ZBA_THRESHOLDS, data);

//Get Monthly AR List
export const getMonthlyARReportDataURL = (data: any) => api.create(url.GET_MONTHLY_AR_REPORT_DATA, data);

//Get Monthly AR List
export const getMonthlyARFcReportDataURL = (data: any) => api.create(url.GET_MONTHLY_AR_FC_REPORT_DATA, data);

//Get Monthly AR List
export const getMonthlyARPtReportDataURL = (data: any) => api.create(url.GET_MONTHLY_AR_PT_REPORT_DATA, data);

//Get Monthly AR List
export const getMonthlyZBAReportDataURL = (data: any) => api.create(url.GET_MONTHLY_ZBA_REPORT_DATA, data);

//Get Monthly AR List
export const getMonthlyZBAFcReportDataURL = (data: any) => api.create(url.GET_MONTHLY_ZBA_FC_REPORT_DATA, data);

//Get Monthly AR List
export const getMonthlyZBAPtReportDataURL = (data: any) => api.create(url.GET_MONTHLY_ZBA_PT_REPORT_DATA, data);