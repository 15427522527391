import React, { useEffect, useMemo, useState } from "react";
import {
    Container
} from "reactstrap";
import './index.scss';
import black_down from "assets/images/black_down.svg";
import side_down from "assets/images/side_down.svg";
import { useLoader } from "provider/LoadingContext";
import { isExpireToken } from "slices/thunk";
import { getARTransactionData, getZBATransactionData } from "helpers/backend_helper";
import TableContainer from "../../../Components/Common/TableContainer";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DynamicPagination from "Components/Common/DynamicPagination";
import { toast } from "react-hot-toast";

interface TabContentProps {
    activeTab: string;
    tabId: string;
    children: React.ReactNode;
}

const TabContent: React.FC<TabContentProps> = ({ activeTab, tabId, children }) => {
    return (
        <div role="tabpanel" hidden={activeTab !== tabId}>
            {children}
        </div>
    );
};

function Transactions(props: any) {
    const navigate = useNavigate();
    const { setIsLoading } = useLoader();
    const [activeTab, setActiveTab] = useState<string>('tab2');
    const clients = JSON.parse(localStorage.getItem("selected-client") || '');
    const [per, setPer] = useState<number>(2);
    const [ArData, setArData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [arCustomPageSize, setArCustomPageSize] = useState<number>(50);

    const [zbaData, setZbaData] = useState<any[]>([]);
    const [currentZbaPage, setCurrentZbaPage] = useState<number>(1);
    const [totalZbaItems, setTotalZbaItems] = useState<number>(0);
    const [zbaCustomPageSize, setZbaCustomPageSize] = useState<number>(50);

    const [arOrderByField, setRrOrderByField] = useState('');
    const [arOrderDirection, setRrOrderDirection] = useState('');

    const [zbaOrderByField, setZbaOrderByField] = useState('');
    const [zbaOrderDirection, setZbaOrderDirection] = useState('');

    const ZbaTransactionColumns = useMemo(
        () => [
            {
                Header: "Account",
                accessor: "account_number",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }: { row: { original: { account_number: string } } }) => row.original.account_number,
            },
            {
                Header: "Admit Date",
                accessor: "admit_date",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }: { row: { original: { admit_date: string | null } } }) => {
                    const admitDate = row.original.admit_date;
                    return admitDate ? moment(new Date(admitDate)).format('MM/DD/YYYY') : '';  // Show blank if null or undefined
                },
            },
            {
                Header: "Discharge Date",
                accessor: "discharge_date",
                filterable: false,
                disableFilters: true,

                Cell: ({ row }: { row: { original: { discharge_date: string | null } } }) => {
                    const discharge = row.original.discharge_date;
                    return discharge ? moment(new Date(discharge)).format('MM/DD/YYYY') : '';  // Show blank if null or undefined
                },
            },
            {
                Header: "Month End Date",
                accessor: "month_end_date",
                filterable: false,
                disableFilters: true,

                Cell: ({ row }: { row: { original: { month_end_date: string | null } } }) => {
                    const monthEnd = row.original.month_end_date;
                    return monthEnd ? moment(new Date(monthEnd)).format('MM/DD/YYYY') : '';  // Show blank if null or undefined
                },
            },
            {
                Header: "Financial Class",  // Use PascalCase for property names, which is standard in react-table
                accessor: "insurance_type_name",  // Correct accessor name for the data field
                disableFilters: true,
                // Disables filtering for this column
                Cell: ({ row }) => (
                    <div className="text-center">
                        {row.original.insurance_type_name}
                    </div>
                ),
            },
            {
                Header: "Patient Type",  // Use PascalCase for property names, which is standard in react-table
                accessor: "patient_type_description",  // Correct accessor name for the data field
                disableFilters: true,
                // Disables filtering for this column
                Cell: ({ row }) => (
                    <div className="text-center">
                        {row.original.patient_type_description}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div className="text-end">Total Billed Charges</div>
                ),
                accessor: "total_billed_charges",
                filterable: false,
                disableFilters: true,

                Filter: false,
                Cell: ({ row }: { row: { original: { total_billed_charges: number } } }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.total_billed_charges);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Insurance Payment</div>
                ),
                accessor: "insurance_payment",
                filterable: false,
                disableFilters: true,

                Filter: false,
                Cell: ({ row }: { row: { original: { insurance_payment: number } } }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.insurance_payment);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Insurance Contractual</div>
                ),
                accessor: "insurance_contractual",
                filterable: false,
                disableFilters: true,

                Filter: false,
                Cell: ({ row }: { row: { original: { insurance_contractual: number } } }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.insurance_contractual);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Patient Payment</div>
                ),
                accessor: "patient_payment",
                filterable: false,
                disableFilters: true,

                Filter: false,
                Cell: ({ row }: { row: { original: { patient_payment: number } } }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.patient_payment);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Total Payment</div>
                ),
                accessor: "total_payment",
                filterable: false,
                disableFilters: true,

                Filter: false,
                Cell: ({ row }: { row: { original: { total_payment: number } } }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.total_payment);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Payment %</div>
                ),
                accessor: "payment_percentage",
                filterable: false,
                disableFilters: true,

                Filter: false,
                Cell: ({ row }: { row: { original: { payment_percentage: string } } }) => {
                    const value = parseFloat(row.original.payment_percentage);
                    const percentage = isNaN(value) ? "0.00" : value.toFixed(2);
                    return (
                        <div className="text-end">
                            {`${percentage}%`}
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-end">Contractual %</div>
                ),
                accessor: "contractual_percentage",
                filterable: false,
                disableFilters: true,

                Filter: false,
                Cell: ({ row }: { row: { original: { contractual_percentage: string } } }) => {
                    const value = parseFloat(row.original.contractual_percentage);
                    const percentage = isNaN(value) ? "0.00" : value.toFixed(2);
                    return (
                        <div className="text-end">
                            {`${percentage}%`}
                        </div>
                    );
                },
            },
        ],
        []
    );

    const ArTransactionColumns = useMemo(
        () => [
            {
                Header: "Account",
                accessor: "atb_account_number", // Add accessor to directly access the field
                disableFilters: true,
                initialState: {
                    sortBy: [
                        {
                            id: "atb_account_number", // The accessor of the column you want to sort by initially
                            desc: false, // Set to true for descending, false for ascending
                        },
                    ],
                },
                Cell: ({ value }) => <div>{value}</div>, // Use value instead of row.original
            },
            {
                Header: () => (
                    <div className="text-end">Balance</div>
                ),
                accessor: "atb_balance_amt", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: "Month End Date",
                accessor: "ar_month_end_date", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    return value ? moment(new Date(value)).format('MM/DD/YYYY') : '';  // Show blank if null or undefined
                },
            },
            {
                Header: "Admit Date",
                accessor: "atb_admit_date", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    return value ? moment(new Date(value)).format('MM/DD/YYYY') : '';  // Show blank if null or undefined
                },
            },
            {
                Header: "Discharge Date",
                accessor: "atb_discharge_date", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => moment(new Date(value)).format('MM/DD/YYYY'),

            },
            {
                Header: "Primary Financial Class",
                accessor: "atb_primary_insurance_type_name", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => <div className="text-center">{value}</div>,
            },
            {
                Header: "Current Financial Class",
                accessor: "atb_current_insurance_type_name", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => <div className="text-center">{value}</div>,
            },
            {
                Header: "Patient Type",
                accessor: "ar_patient_type_description", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => <div className="text-center">{value}</div>,
            },
            {
                Header: () => (
                    <div className="text-end">Total Charges</div>
                ),
                accessor: "ar_acct_total_charges", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Total Charge Adjustment</div>
                ),
                accessor: "ar_acct_total_charge_adjustments", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Total Billed Charges</div>
                ),
                accessor: "ar_total_billed_charges", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Insurance Payment</div>
                ),
                accessor: "ar_total_ins_payment", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Patient Payment</div>
                ),
                accessor: "ar_total_pat_payment", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Total Payment</div>
                ),
                accessor: "ar_total_payment", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Payment %</div>
                ),
                accessor: "ar_payment_percentage", // Correct accessor
                disableFilters: true,

                Cell: ({ value }: { value: string }) => {
                    // Parse the string value to a float
                    const numericValue = parseFloat(value);

                    // Check if the result is NaN and handle it
                    const percentage = isNaN(numericValue) ? "0.00" : numericValue.toFixed(2);

                    return (
                        <div className="text-end">
                            {`${percentage}%`}
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-end">Contractual %</div>
                ),
                accessor: "ar_contractual_percentage", // Correct accessor
                disableFilters: true,

                Cell: ({ value }: { value: string }) => {
                    // Parse the string value to a float
                    const numericValue = parseFloat(value);

                    // Check if the result is NaN and handle it
                    const percentage = isNaN(numericValue) ? "0.00" : numericValue.toFixed(2);

                    return (
                        <div className="text-end">
                            {`${percentage}%`}
                        </div>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-end">Recorded Contractual</div>
                ),
                accessor: "ar_total_ins_contractual", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Expected Contractual</div>
                ),
                accessor: "ar_expected_contractual", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Calculated Net</div>
                ),
                accessor: "ar_calculated_net", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
            {
                Header: () => (
                    <div className="text-end">Recorded Net</div>
                ),
                accessor: "ar_recorded_net", // Correct accessor
                disableFilters: true,

                Cell: ({ value }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(value);
                    return <div className="text-end">{formattedValue}</div>;
                },
            },
        ],
        []
    );

    const getARData = async (page: number, pageSize: number) => {
        try {
            setIsLoading(true);
            const body = {
                "clientId": clients.user_id,
                "pagination": true,
                "page": page - 1,
                "size": pageSize,
                "orderByField": arOrderByField ? arOrderByField : '',
                "orderDirection": arOrderDirection ? arOrderDirection : ''
            };
            let response = await getARTransactionData(body);
            if (response && response.success && response.data.list) {
                setArData(response.data.list);
                setTotalItems(response.data.totalItems);
                setIsLoading(false);
            } else {
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const getZbaData = async (page: number, pageSize: number) => {
        try {
            setIsLoading(true);
            const body = {
                "clientId": clients.user_id,
                "pagination": true,
                "page": page - 1,
                "size": pageSize,
                "orderByField": zbaOrderByField ? zbaOrderByField : '',
                "orderDirection": zbaOrderDirection ? zbaOrderDirection : ''
            };
            let response = await getZBATransactionData(body);
            // console.log("getZBATransactionData >>... ", response);
            if (response && response.success && response.data.list) {
                setZbaData(response.data.list);
                setTotalZbaItems(response.data.totalItems);
                setIsLoading(false);
            } else {
                console.error("Failed to fetch data");
                toast.error(response.message);
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };


    const handleZbaSortingParamsaas = async (sortingParams: { orderByField: string; orderDirection: string }) => {
        if (activeTab === 'tab2') {
            const { orderByField, orderDirection } = sortingParams;
            setZbaOrderByField(orderByField);
            setZbaOrderDirection(orderDirection);
            const payload = {
                clientId: clients.user_id,
                pagination: true,
                page: 0,
                size: 50,
                orderByField,
                orderDirection,
            };

            try {
                setIsLoading(true);
                const response = await getZBATransactionData(payload);
                if (response && response.success && response.data.list) {
                    setZbaData(response.data.list);
                    setTotalZbaItems(response.data.totalItems);
                    setIsLoading(false);
                } else {
                    setZbaData([]);
                    setTotalZbaItems(0);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.error("Failed to fetch data", error);
            }
        }
    };


    const handleZbaSortingParams = (params: { orderByField: string; orderDirection: string }) => {
         handleZbaSortingParamsaas(params);
    };


    const handleARSortingParamsaas = async (sortingParams: { orderByField: string; orderDirection: string }) => {
        if (activeTab === 'tab1') {
            const { orderByField, orderDirection } = sortingParams;
            setRrOrderByField(orderByField);
            setRrOrderDirection(orderDirection);
            const payload = {
                clientId: clients.user_id,
                pagination: true,
                page: 0,
                size: 50,
                orderByField,
                orderDirection,
            };

            try {
                setIsLoading(true);
                const response = await getARTransactionData(payload);
                if (response && response.success && response.data.list) {
                    setIsLoading(false);
                    setArData(response.data.list);
                    setTotalItems(response.data.totalItems);
                } else {
                    setIsLoading(false);
                    setArData([]);
                    setTotalItems(0)
                }
            } catch (error) {
                setIsLoading(false);
                console.error("Failed to fetch data", error);
            }
        }
    };


    const handleArSortingParams = (params: { orderByField: string; orderDirection: string }) => {
         handleARSortingParamsaas(params);
    }

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
        setZbaOrderByField('');
        setZbaOrderDirection('');
        setRrOrderByField('');
        setRrOrderDirection('');
    };

    useEffect(() => {
        if (activeTab === 'tab1') {
            getARData(currentPage, arCustomPageSize);
        }
        if (activeTab === 'tab2') {
            getZbaData(currentZbaPage, zbaCustomPageSize)
        }
    }, [currentPage, currentZbaPage, arCustomPageSize, zbaCustomPageSize, activeTab]);




    // getARTransactions
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div className="mb-2 tab-btn justify-content-start"><h4 className="fw-bold">Transactions</h4>
                        <div className="tab-header ms-auto mb-1">
                            <button
                                type="button"
                                className={activeTab === 'tab1' ? 'active' : ''}
                                onClick={() => handleTabClick('tab1')}
                            >
                                AR Transactions
                            </button>
                            <button
                                type="button"
                                className={activeTab === 'tab2' ? 'active' : ''}
                                onClick={() => handleTabClick('tab2')}
                            >
                                ZBA Transactions
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="tab-content">
                            <TabContent tabId="tab1" activeTab={activeTab}>
                                <div className="data_table_box log_table mt-2">
                                    <div className="">
                                        <TableContainer
                                            columns={ArTransactionColumns}
                                            data={ArData}
                                            handleSortingParams={handleArSortingParams}
                                            isGlobalFilter={false}
                                            isAddOptions={false}
                                            customPageSize={arCustomPageSize}
                                            setCustomPageSize={setArCustomPageSize}
                                            tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover"
                                            theadClass="table-light"
                                            customPageSizeOption={true}
                                            customPageSizeOptions={true}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 mb-2">
                                    <DynamicPagination
                                        perPageData={arCustomPageSize}
                                        totalItems={totalItems}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div>
                            </TabContent>
                            <TabContent tabId="tab2" activeTab={activeTab}>
                                <div className="col-md-12">
                                    <div className="data_table_box log_table mt-2">
                                        <div className="">
                                            <TableContainer
                                                columns={ZbaTransactionColumns}
                                                data={zbaData}
                                                handleSortingParams={handleZbaSortingParams}
                                                isGlobalFilter={false}
                                                isAddOptions={false}
                                                customPageSize={zbaCustomPageSize}
                                                setCustomPageSize={setZbaCustomPageSize}
                                                tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover"
                                                theadClass="table-light"
                                                customPageSizeOption={true}
                                                customPageSizeOptions={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-2 mb-2">
                                        <DynamicPagination
                                            perPageData={zbaCustomPageSize}
                                            totalItems={totalZbaItems}
                                            currentPage={currentZbaPage}
                                            setCurrentPage={setCurrentZbaPage}
                                        />
                                    </div>
                                </div>
                            </TabContent>

                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Transactions;