import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import withRouter from "../../../Components/Common/withRouter";

import { Button, Card, Input, Col, CardBody, CardTitle, Modal, ModalHeader, Row, ModalBody, InputGroup, Spinner, Container } from "reactstrap";
import EcommerceOrdersModal from "../../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import TableContainer from "../../../Components/Common/TableContainer";
import { uploadFile, getEdi835Data, getLoggedInUser, getSelectedClientData, getARSourceData, uploadARFile } from 'helpers/backend_helper'
import Pagination from "../../../Components/Common/Pagination";
import moment from "moment";
import { toast } from "react-hot-toast";
import DynamicPagination from "Components/Common/DynamicPagination";
import { useNavigate } from "react-router-dom";
import { isExpireToken } from "slices/thunk";
import { useLoader } from "provider/LoadingContext";

const AR: React.FC = () => {
    const navigate = useNavigate();
    const { setIsLoading } = useLoader();
    const [subscribemodal, setSubscribemodal] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [modal1, setModal1] = useState<boolean>(false);
    const toggleViewModal = useCallback(() => setModal1(!modal1), [modal1]);
    const [ArData, setArData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [customPageSize, setCustomPageSize] = useState<number>(50);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const fileInputRef = useRef(null);
    const [per, setPer] = useState<number>(2);
    const [currentDate, setCurrentDate] = useState('');
    const clients = JSON.parse(localStorage.getItem("selected-client") || '')

    useEffect(() => {
        getARData(currentPage, customPageSize);
    }, [currentPage, customPageSize]);

    useEffect(() => {
        setSelectedFile(null)
        setIsFileUploading(false)
        setCurrentDate('')
    }, []);

    const getARData = async (page: number, pageSize: number) => {
        try {
            setIsLoading(true);
            const body = {
                "clientId": clients.user_id,
                "pagination": true,
                "page": page - 1,
                "size": pageSize,
                "orderByField": '',
                "orderDirection": ''
            };
            let response = await getARSourceData(body);
            if (response && response.success && response.data.list) {
                setArData(response.data.list);
                setTotalItems(response.data.totalItems);
                setIsLoading(false);
            } else {
                console.error("Failed to fetch data");
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const handleInputChange = (value) => {
        setCurrentDate(value)
    };

    const columns = useMemo(
        () => [
            {
                Header: "Account Number",
                accessor: "Account_Number",
                filterable: false,
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => row.original.Account_Number
            },
            {
                Header: "Discharge Date",
                filterable: false,
                accessor: "Discharge_Date",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => moment(new Date(row.original.Discharge_Date)).format('MM/DD/YYYY'),
            },
            {
                Header: "Financial Class",
                filterable: false,
                accessor: "Financial_Class",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => row.original.Financial_Class,
            },
            {
                Header: "Payer Group",
                filterable: false,
                accessor: "Payer_Group",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => row.original.Payer_Group
            },
            {
                Header: "Patient Type",
                filterable: false,
                accessor: "Patient_Type_Abbreviation",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.Patient_Type_Abbreviation
            },
            {
                Header: "Service Code",
                filterable: false,
                accessor: "Service_Code",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.Service_Code
            },
            {
                Header: "Service Code Description",
                filterable: false,
                accessor: "Service_Code_Description",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) =>
                    row.original.Service_Code_Description
            },
            {
                Header: "Acct Total Charges",
                filterable: false,
                accessor: "Acct_Total_Charges",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.Acct_Total_Charges);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            // {
            //     Header: "Acct Total Charges %",
            //     filterable: false,
            //     disableFilters: true,
            //     Filter: false,
            //     Cell: ({ row }) => {
            //         const formatter = new Intl.NumberFormat('en-US', {
            //             style: 'currency',
            //             currency: 'USD',
            //         });
            //         const formattedValue = formatter.format(row.original.Acct_Total_Charges_Per);
            //         return <span style={{ textAlign: 'end', display: 'block' }}>
            //             {formattedValue}
            //         </span>;
            //     },
            // },
            {
                Header: "Total Ins Payment",
                filterable: false,
                accessor: "Total_Ins_Payment",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.Total_Ins_Payment);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            // {
            //     Header: "Total Ins Payment %",
            //     filterable: false,
            //     disableFilters: true,
            //     Filter: false,
            //     Cell: ({ row }) => {
            //         const value = parseFloat(row.original.Total_Ins_Payment_Per);
            //         const percentage = isNaN(value) ? 0 : value.toFixed(per);
            //         return <span style={{ textAlign: 'end', display: 'block' }}>
            //             {`${percentage}%`}
            //         </span>;
            //     },
            // },
            {
                Header: "Total Ins Contractual",
                filterable: false,
                accessor: "Total_Ins_Contractual",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.Total_Ins_Contractual);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            // {
            //     Header: "Total Ins Contractual %",
            //     filterable: false,
            //     disableFilters: true,
            //     Filter: false,
            //     Cell: ({ row }) => {
            //         const value = parseFloat(row.original.Total_Ins_Contractual_Per);
            //         const percentage = isNaN(value) ? 0 : value.toFixed(per);
            //         return <span style={{ textAlign: 'end', display: 'block' }}>
            //             {`${percentage}%`}
            //         </span>;
            //     },
            // },
            {
                Header: "Total Pat Payment",
                filterable: false,
                accessor: "Total_Pat_Payment",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.Total_Pat_Payment);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            // {
            //     Header: "Total Pat Payment %",
            //     filterable: false,
            //     disableFilters: true,
            //     Filter: false,
            //     Cell: ({ row }) => {
            //         const value = parseFloat(row.original.Total_Pat_Payment_Per);
            //         const percentage = isNaN(value) ? 0 : value.toFixed(per);
            //         return <span style={{ textAlign: 'end', display: 'block' }}>
            //             {`${percentage}%`}
            //         </span>;
            //     },
            // },
            {
                Header: "Acct Total Charge Adjustments",
                filterable: false,
                accessor: "Acct_Total_Charge_Adjustments",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.Acct_Total_Charge_Adjustments);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            // {
            //     Header: "Acct Total Charge Adjustments %",
            //     filterable: false,
            //     disableFilters: true,
            //     Filter: false,
            //     Cell: ({ row }) => {
            //         const value = parseFloat(row.original.Acct_Total_Charge_Adjustments_Per);
            //         const percentage = isNaN(value) ? 0 : value.toFixed(per);
            //         return <span style={{ textAlign: 'end', display: 'block' }}>
            //             {`${percentage}%`}
            //         </span>;
            //     },
            // },
            {
                Header: "Acct Balanc",
                filterable: false,
                accessor: "Acct_Balance",
                disableFilters: true,
                Filter: false,
                Cell: ({ row }) => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    const formattedValue = formatter.format(row.original.Acct_Balance);
                    return <span style={{ textAlign: 'end', display: 'block' }}>
                        {formattedValue}
                    </span>;
                },
            },
            // {
            //     Header: "Acct Balanc %",
            //     filterable: false,
            //     disableFilters: true,
            //     Filter: false,
            //     Cell: ({ row }) => {
            //         const value = parseFloat(row.original.Acct_Balance_Per);
            //         const percentage = isNaN(value) ? 0 : value.toFixed(per);
            //         return <span style={{ textAlign: 'end', display: 'block' }}>
            //             {`${percentage}%`}
            //         </span>;
            //     },
            // },
        ],
        []
    );

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isFileUploading) {
            const file = e.target.files?.[0] || null;
            if (file) {
                const fileNameArray = file.name.split('.');
                const fileExtension = fileNameArray[fileNameArray.length - 1].toLowerCase();

                if (['xlsx'].includes(fileExtension)) {
                    setSelectedFile(file);
                } else {
                    setSelectedFile(null);
                    toast.error("File format must be .xlsx");
                }
            }
        }
    };

    const handleUploadFile = async () => {
        if (!isFileUploading) {
            try {
                if (selectedFile && currentDate != '') {
                    setIsFileUploading(true);
                    let response = await uploadARFile({ file: selectedFile }, clients.user_id, currentDate);

                    if (response && response.success) {
                        setIsFileUploading(false);
                        setSelectedFile(null);
                        setSubscribemodal(false);
                        toast.success(`Successfully inserted`);
                        // fetchEdi835Data();
                        getARData(currentPage, customPageSize);

                        // let insertedCount = response.data.insertedCount;
                        // let updatedCount = response.data.updatedCount;

                        // if (insertedCount > 0 && updatedCount > 0) {
                        //     toast.success(`Successfully inserted ${insertedCount} and updated ${updatedCount} record(s).`);
                        // } else if (insertedCount > 0) {
                        //     toast.success(`Successfully inserted ${insertedCount} record(s).`);
                        // } else if (updatedCount > 0) {
                        //     toast.success(`Successfully updated ${updatedCount} record(s).`);
                        // } else {
                        //     toast("No records were inserted or updated.");
                        // }
                    } else {
                        toast.error(response.message);
                        setIsFileUploading(false);
                        setSelectedFile(null);
                        setSubscribemodal(false);
                        if (response.code === '1') {
                            isExpireToken(navigate);
                        }
                    }
                } else {
                    if (selectedFile === null) {
                        toast.error("Please upload any file");
                    } else {
                        toast.error("Please select date");
                    }
                }
            } catch (error) {
                toast.error("An error occurred while uploading data. Please try again.");
            }
        } else {
            toast.error("Please Select File");
        }
    };

    const handleUpload = () => {
        setSubscribemodal(true);
    };

    const uploadARModal = () => {
        setSubscribemodal(!subscribemodal);
        setSelectedFile(null)
        setIsFileUploading(false)
        setCurrentDate('')
    }

    const handleARSortingParamsaas = async (sortingParams: { orderByField: string; orderDirection: string }) => {
        const { orderByField, orderDirection } = sortingParams;
        const payload = {
            clientId: clients.user_id,
            pagination: true,
            page: 0,
            size: 50,
            orderByField,
            orderDirection,
        };

        try {
            setIsLoading(true);
            const response = await getARSourceData(payload);
            if (response && response.success && response.data.list) {
                setArData(response.data.list);
                setTotalItems(response.data.totalItems);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setArData([]);
                setTotalItems(0)
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Failed to fetch data", error);
        }
    };


    const handleArSortingParams = (params: { orderByField: string; orderDirection: string }) => {
        handleARSortingParamsaas(params);
    }

    return (
        <React.Fragment>
            <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
            <div className="page-content">
                <Container fluid={true}>
                    <div className="mb-2 tab-btn"><h4 className="fw-bold">AR</h4>
                        <button className="btn btn-primary" id="collapseWidthExample" type="button" onClick={handleUpload}>
                            Upload AR File
                        </button>
                    </div>
                    <div className="data_table_box log_table">
                        <div className="">
                            <TableContainer
                                columns={columns}
                                data={ArData}
                                handleSortingParams={handleArSortingParams}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover"
                                theadClass="table-light"
                                customPageSizeOption={true}
                                customPageSizeOptions={true}
                            />
                        </div>
                    </div>
                    <div className="mt-2 mb-2">
                        {/* <Pagination
                            perPageData={customPageSize}
                            data={ArData}
                            setCurrentpages={setCurrentpages}
                            currentpages={currentpages}
                        /> */}
                        <DynamicPagination
                            perPageData={customPageSize}
                            totalItems={totalItems}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </Container>
            </div>
            {/* subscribe ModalHeader */}
            <Modal isOpen={subscribemodal} autoFocus={true} centered toggle={uploadARModal}>
                <div>
                    <ModalHeader className="border-bottom-0" toggle={uploadARModal} />
                </div>
                <ModalBody>
                    <div className="text-center mb-4">
                        <Row className="justify-content-center">
                            <Col xl={10}>
                                <h4 className="text-primary mb-4">Upload AR File</h4>
                                <input
                                    name="startDate"
                                    placeholder="Effective From"
                                    type="date"
                                    className="form-control mb-4"
                                    id="startDate"
                                    onChange={(e) => handleInputChange(e.target.value)}
                                />
                                <InputGroup className="rounded bg-light">
                                    <Input ref={fileInputRef} disabled={isFileUploading} type="file" className="bg-transparent border-0" accept=".xlsx"
                                        placeholder="Upload File" onChange={handleFileChange} />
                                    <Button disabled={isFileUploading} color="primary" type="button" id="button-addon2" onClick={handleUploadFile}>{isFileUploading ? <Spinner size="sm">Uploading...</Spinner> : "Upload"}</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal >
        </React.Fragment>
    );
};

export default withRouter(AR);