import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import withRouter from "../../Components/Common/withRouter";

import { Button, Card, Input, Col, CardBody, CardTitle, Modal, ModalHeader, Row, ModalBody, InputGroup, Spinner } from "reactstrap";
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";
import TableContainer from "../../Components/Common/TableContainer";
import { uploadFile, getEdi835Data, getLoggedInUser, getSelectedClientData } from 'helpers/backend_helper'
import Pagination from "../../Components/Common/Pagination";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { isExpireToken } from "slices/thunk";

const LatestTranaction: React.FC = () => {
  const navigate = useNavigate();
  const [subscribemodal, setSubscribemodal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [modal1, setModal1] = useState<boolean>(false);
  const toggleViewModal = useCallback(() => setModal1(!modal1), [modal1]);
  const [client835Data, setClient835Data] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [customPageSize, setCustomPageSize] = useState(10);
  const fileInputRef = useRef(null);
  const [clientId, setClientId]: any = useState(null);

  useEffect(() => {
    fetchEdi835Data();
  }, []);

  const fetchEdi835Data = async () => {
    try {
      setIsTableLoading(true);
      let currentClientId: any = null;
      let userData = getLoggedInUser();
      if (userData && userData.user_type === 1) {
        var clientData: any = getSelectedClientData();
        currentClientId = clientData?.user_id;
      } else {
        currentClientId = userData?.id;
      }
      setClientId(currentClientId);
      let response = await getEdi835Data(currentClientId);
      if (response && response.success && (response.data && response.data.length > 0)) {
        setClient835Data(response?.data);
        setIsTableLoading(false)
      } else {
        setIsTableLoading(false);
        if (response.code === '1') {
          isExpireToken(navigate);
        }
      }
    } catch (error) {
      toast.error("An error occurred while fetching data. Please try again.");
      setIsTableLoading(false);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Check No",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.check_no,
      },
      {
        Header: "Patient Id",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => {
          return row.original.patient_control_no
        },
      },
      {
        Header: "Patient Control No",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.patient_control_no,
      },
      {
        Header: "Patient Name",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => {
          return row.original.patient_name
        },
      },
      {
        Header: "Patient Amount",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => {
          return row.original.patient_amt
        },
      },
      {
        Header: "Payee",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => {
          return row.original.payee
        },
      },
      {
        Header: "Payer",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => {
          return row.original.payer
        },
      },
      {
        Header: "Amount",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => {
          return row.original.chk_amount
        },
      },
      {
        Header: "Payee NPI",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.payee_npi,
      },
      {
        Header: "Payer Control No",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.payer_ctrl_no,
      },
      {
        Header: "SVC Line No",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.svc_line_no,
      },
      {
        Header: "SVC Control No",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.svc_control_no,
      },
      {
        Header: "SVC Start Date",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => moment(new Date(row.original.svc_start)).format('DD-MM-YYYY'),
      },
      {
        Header: "SVC End Date",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => moment(new Date(row.original.svc_end)).format('DD-MM-YYYY'),
      },
      {
        Header: "HCPCS",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.hcpcs,
      },
      {
        Header: "In Claimed",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_claimed,
      },
      {
        Header: "In Allowed",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_allowed,
      },
      {
        Header: "In Paid",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_paid,
      },
      {
        Header: "In Deductible",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_deductible,
      },
      {
        Header: "In CO INS",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_co_ins,
      },
      {
        Header: "In CO Pay",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_co_pay,
      },
      {
        Header: "In Denied",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_denied,
      },
      {
        Header: "In More Adjustments",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.ln_more_adjustments,
      },
      {
        Header: "Revenue Code",
        filterable: false,
        disableFilters: true,
        Filter: false,
        Cell: ({ row }) => row.original.revenue_code,
      },
      {
        Header: "Created At",
        filterable: false,
        disableFilters: true,
        Filter: false,
        // Cell: ({ row }) => moment(row.original.created_at).format("YYYY-MM-DD HH:mm:ss"),
        Cell: ({ row }) => moment(new Date(row.original.created_at)).format('DD-MM-YYYY'),
      },
    ],
    []
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isFileUploading) {
      const file = e.target.files?.[0] || null;
      if (file) {
        const fileNameArray = file.name.split('.');
        const fileExtension = fileNameArray[fileNameArray.length - 1].toLowerCase();

        if (['edi', 'txt'].includes(fileExtension)) {
          setSelectedFile(file);
        } else {
          setSelectedFile(null);
          toast.error("File format must be .edi or .txt");
        }
      }
    }
  };

  const handleUploadFile = async () => {
    if (!isFileUploading) {
      try {
        if (selectedFile) {
          setIsFileUploading(true);
          let response = await uploadFile({ file: selectedFile }, clientId);

          if (response && response.success) {
            setIsFileUploading(false);
            setSelectedFile(null);
            setSubscribemodal(false);
            fetchEdi835Data();

            let insertedCount = response.data.insertedCount;
            let updatedCount = response.data.updatedCount;

            if (insertedCount > 0 && updatedCount > 0) {
              toast.success(`Successfully inserted ${insertedCount} and updated ${updatedCount} record(s).`);
            } else if (insertedCount > 0) {
              toast.success(`Successfully inserted ${insertedCount} record(s).`);
            } else if (updatedCount > 0) {
              toast.success(`Successfully updated ${updatedCount} record(s).`);
            } else {
              toast("No records were inserted or updated.");
            }
          } else {
            toast.error("File upload failed");
            if (response.code === '1') {
              isExpireToken(navigate);
          }
          }
        } else {
          toast.error("Please upload any file");
        }
      } catch (error) {
        toast.error("An error occurred while uploading data. Please try again.");
      }

    }
  };

  const handleUpload = () => {
    setSubscribemodal(true);
  };

  const [currentpages, setCurrentpages] = useState<any>(client835Data);
  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <Card>
        <CardBody>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <CardTitle tag="h4" className="mb-4 tab-btn"><div>835 Transactions</div>
            <button className="btn btn-primary" id="collapseWidthExample" type="button" onClick={handleUpload}>
              Upload 835 File
            </button>
          </CardTitle>
          <div className="data_table_box log_table">
            <div className="mt-4">
              <TableContainer
                // isLoading={isTableLoading}
                columns={columns}
                data={currentpages}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={customPageSize}
                setCustomPageSize={setCustomPageSize}
                tableClass="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover"
                theadClass="table-light"
                customPageSizeOption={true}
                customPageSizeOptions={true}
              />
            </div>
          </div>
          <div className="mt-2 mb-2">
            <Pagination
              perPageData={customPageSize}
              data={client835Data}
              setCurrentpages={setCurrentpages}
              currentpages={currentpages}
            />
          </div>
        </CardBody>
      </Card>

      {/* subscribe ModalHeader */}
      <Modal isOpen={subscribemodal} autoFocus={true} centered toggle={() => { setSubscribemodal(!subscribemodal) }}>
        <div>
          <ModalHeader className="border-bottom-0" toggle={() => { setSubscribemodal(!subscribemodal) }} />
        </div>
        <ModalBody>
          <div className="text-center mb-4">
            <Row className="justify-content-center">
              <Col xl={10}>
                <h4 className="text-primary mb-4">Upload 835 File</h4>
                <InputGroup className="rounded bg-light">
                  <Input ref={fileInputRef} disabled={isFileUploading} type="file" className="bg-transparent border-0" accept=".edi, .txt"
                    placeholder="Upload File" onChange={handleFileChange} />
                  <Button disabled={isFileUploading} color="primary" type="button" id="button-addon2" onClick={handleUploadFile}>{isFileUploading ? <Spinner size="sm">Uploading...</Spinner> : "Upload"}</Button>
                </InputGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal >
    </React.Fragment>
  );
};

export default withRouter(LatestTranaction);