import React from 'react';
import withRouter from "../Components/Common/withRouter";
import GlobalSpinner from 'Components/Common/GlobalSpinner';
import { LoaderProvider } from 'provider/LoadingContext';

const NonAuthLayout = (props: any) => {
  return (
    <React.Fragment>
      <LoaderProvider>
        <GlobalSpinner />
        {props.children}
      </LoaderProvider>
    </React.Fragment>
  );
}

export default withRouter(NonAuthLayout)
